import { useInjection } from "inversify-react"
import { useEffect, useState } from "react"
import { UsersStore } from "../../stores/users/UsersStore"
import { UserStore } from "../../stores/user/UserStore"
import { url } from "../auth/helper"

const UserPost = ({_id}) =>{
    const userStore = useInjection(UserStore)
    const usersStore = useInjection(UsersStore)
    const [id, setId] = useState('')
    const [loaded, setLoaded] = useState(true)
    const [post, setPost] = useState(null)
    // useEffect(()=>{
    //     if(usersStore.user) {
    //         console.log(userStore.isAuth)
    //         userStore.getUserPosts(_id)
    //         let arr = location?.href?.split('/')
    //         setId(arr[arr.length-1])
             
    //     }
    // },[_id])
    // useEffect(()=>{
        
    //    if(!post) {
    //     setLoaded(!loaded)
    //     userStore.posts.filter(el=>el._id===id).map((el)=>{
    //         setPost(el)
    //     })
    // } 
    // })
   
    return (
        <div className='lk-message'>
            <img src={post?.image ? url+'/'+post?.image:''} style={{width:'100%', objectFit:'cover',maxHeight:'230px'}}></img>
            <div style={{padding:'12px'}}>
                <div className='lk-title' style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
                    <div style={{fontFamily: 'Releway-bold',fontSize: '32px'}}>{post?.title}</div>
               
                </div>
                <div className='lk-text'>{post?.text}</div>  
                <div className='lk-nft' style={{marginTop:'20px'}}>{post?.nfts[0]}</div>
            </div>         
        </div>
    )
}
export default UserPost