import {observer} from "mobx-react";
import './profileComponents.sass'
import { useState } from "react";
import { SubmitButton, TextAreaInput, TextInput } from "../../shared/forms/forms";
import {useInjection} from "inversify-react";
import {RegisterData, UserStore} from "../../stores/user/UserStore";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";


const PersonalData = observer(() => {
    const userStore = useInjection(UserStore);
    const [formData, setFormData] = useState<RegisterData>();
    const {t} = useTranslation()
    const change = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }
    const submit = (e) => {
        e.preventDefault()
        userStore.updateProfile(formData).then(res=>{
            toast.success(t('toasts::data'))
        })
    }

    return (
       <form className='lk-personalData'onSubmit={submit}>
            
            <TextInput title={`${t('form::userID')}`}required name={'user_id'} defaultValue={userStore.user?.user_id} onChange={change} border />
            <TextInput title={`${t('form::fullname')}`} required name={'fullname'} defaultValue={userStore.user?.fullname} onChange={change} border />
            <TextInput title={`${t('form::email')}`}required name={'email'} defaultValue={userStore.user?.email} onChange={change} type={'email'} border />
            <TextInput title={`${t('form::phone')}`} name={'phone'} defaultValue={userStore.user?.phone} onChange={change}  border />
            {userStore.user?.user_type=='artist'&&
            <>
            <TextAreaInput title={`${t('form::aboutU')}`} name={'about'} defaultValue={userStore.user?.about} onChange={change} border />
            <TextAreaInput title={`${t('form::education')}`} name={'education'} defaultValue={userStore.user?.education} onChange={change} border />
            <TextAreaInput title={`${t('form::media')}`} name={'social'}  onChange={change} defaultValue={userStore.user?.social} border />
            <TextAreaInput title={`${t('form::worksInCollections')}`} name={'null'}  onChange={change} defaultValue={userStore.user?.null}  border />
            </>
            }
            <div style={{display: "flex", justifyContent: 'flex-end'}}>
                <NavLink 
                    style={{textDecoration: 'none', color: '#8A4ADA',}} 
                    to='/p/auth/newPassword'>
                        {t('button::changePassword')}
                </NavLink> 
            </div>
            
            <SubmitButton type='submit'>{t('button::change')}</SubmitButton>
       </form>
    )
})


export default  PersonalData