import {observer} from "mobx-react";
import './profileComponents.sass'
import styled from "styled-components";
import SearchBar from "./searchBar";
import { ButtonLight } from "../../shared/buttons/buttons";
import { ModalsEnum, ModalStore } from "../../stores/ModalStore";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { useEffect, useState } from "react";
import { Table } from "../../shared/tables/tables";
import { Desc, Name } from "./selling";
import { divide } from "lodash";
import { url } from "../auth/helper";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";
import { NavLink } from "react-router-dom";

export const NothingHere = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;

align-items: flex-start;
justify-content:center;
border:1px solid rgba(211, 211, 211, 1);
border-radius: 8px;

h1 {
    font-size:32px
}


`
export const Text = styled.div`
padding:28px;
display: flex;
flex-direction: column;
align-items: flex-start;
margin-top:50px;
p {
    text-align: left;
    max-width:365px
}
`
const CollectionsNFT = observer(({user, match}) => {
 const modalStore = useInjection(ModalStore)
 const userStore = useInjection(UserStore)
 const history = useHistory();
 const {t} = useTranslation()
 const [isMe, setIsMe] = useState(false)
 useEffect(()=>{
    if(user) {
       userStore.getCollections(user?._id) 
       
    }
    if(user?._id===userStore.user?._id) {
        setIsMe(true)
        // console.log("hi me")
    }
    return ()=>{
        userStore.clearCollections() 
    }
 },[user])
    return (
       
    <SmoothAppearance trigger={userStore.collections}>

        <div className='lk-myNft'> 
        
        {userStore.collections&&
         (userStore.collections.length!==0?
        <div> 
            <div  style={{height:'70px', width:'100%', display:'flex', justifyContent:"space-between",alignItems:'flex-start'}}>
                <h1 style={{marginBottom:'30px'}}>{t('sidebar::collections')}</h1>
                {isMe && 
                (user?.user_type  === 'artist' ?
                    <ButtonLight style={{width:'170px',minWidth:'180px',display:userStore.user?._id===user?._id?'block':'none'}} onClick={()=>modalStore.showModal(ModalsEnum.Collections)}>{t('collections::create')}</ButtonLight>
                    :
                    <ButtonLight width='180px' onClick={()=>history.push(`${match.path}/become_artist`)}>
                        <NavLink to={`${match.path}/become_artist`}>{t('collections::create')}</NavLink>
                    </ButtonLight>
                )}
            </div>
            <Table>
            <tbody>
        {userStore.collections.map((el,i)=>{
            return ( 
                         <tr key={i}style={{cursor:'pointer'}} >
{/*                             
                            <td style={{width:'1px', padding: '0 10px 0 0'}}>
                                <img src={url+el?.author.photo} />
                            </td>
                            <td>
                                <Name>{el?.author.fullname}</Name>
                            </td> */}
                            <td onClick={()=>history.push(`./collections/${el?._id}`)} style={{minWidth:'120px', width:'120px'}}>
                                <Name  style={{textAlign:'left'}}>{el?.name}</Name>
                            </td>
                            <td onClick={()=>history.push(`./collections/${el?._id}`)}>
                                <Desc style={{textAlign:'left',paddingLeft:'30px'}}>{el?.description}</Desc>
                            </td>
                            {isMe&&<td>
                                <img src={require('../../images/modal-cross.png')}style={{width:'15px', height:'15px',cursor:'pointer'}} onClick={()=>modalStore.showModal(ModalsEnum.CollectionsDelete,{id: el?._id, title: el.name})}></img>
                            </td>}
                        </tr>
            )
            }).reverse()}
            </tbody>
                       
            </Table>
           
            </div>
        :
        <NothingHere style={{display:userStore.user?._id===user?._id?'flex':'none'}}>
                <Text>
                    <h1>{t('collections::nothing')}</h1>
                    <p>{t('collections::uCanBuyNft')}</p>
                    {isMe&&
                    (userStore.user?.user_type  === 'artist' ?
                        <ButtonLight style={{width:'180px'}}onClick={()=>modalStore.showModal(ModalsEnum.Collections)}>{t('collections::create')}</ButtonLight>
                        :
                        <ButtonLight width='180px' onClick={()=>history.push(`${match.path}/become_artist`)}>
                            <NavLink to={`${match.path}/become_artist`}>{t('collections::create')}</NavLink>
                        </ButtonLight>
                    )}
                    <ButtonLight style={{width:'180px'}}onClick={()=>history.push("../../../../p/catalog")}>{t('button::toCatalog')}</ButtonLight>
                </Text>
                
                <img src={require('../../images/looking-out.svg')} style={{padding:'28px'}}/>
            

            
        </NothingHere>)}
      
   </div>
   </SmoothAppearance>
    )
    
})


export default  CollectionsNFT