import {useHistory, useParams} from "react-router";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import PrivatePages from "../../../components/auth/auth";
import {FormOptions, ModalForm, TextInput} from "../../../shared/forms/forms";
import {useInjection} from "inversify-react";
import {HistoryStore} from "../../../stores/RootStore";
import {UserStore} from "../../../stores/user/UserStore";
import { useTranslation } from "react-i18next";


const Validation = observer(() => {
    const params = useParams();
    const history = useHistory()
    const userStore = useInjection(UserStore)
    const {t} = useTranslation()
    const [validate, setValidate] = useState(false)
    useEffect(() => {
        // console.log(params)
       let res = userStore.Validation(params)
        if(!res) {
            setValidate(true)
        }
    }, [])



    function RedirectFunction() {
        history.push('/p/auth/login')
    }

    return (
        <PrivatePages>
            {!validate ? (
                <ModalForm onSubmit={RedirectFunction} title={`${t('form::accountVerified')}`} button={`${t('button::toLoginPage')}`}>
                </ModalForm>
            ):<ModalForm onSubmit={RedirectFunction} title={`$${t('form::codeIncorrect')}`} button={`${t('button::toLoginPage')}`}>
            </ModalForm>}

        </PrivatePages>

    )
})

export default Validation