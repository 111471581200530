import { useTranslation } from "react-i18next";
import CatalogItem from "../../../pages/Catalog/catalogItem";
import { Description } from "..";
import { observer } from "mobx-react";

export const SimilarNFT = observer(({NFT}) => {

const { t } = useTranslation();
  return (
    <div className="nft-same">
      <Description>
        <h2>{t("nft::similar")}</h2>
      </Description>
      <div className="nft-grid">
        {NFT?.map((el, i) => {
          if (i < 5) {
            return <CatalogItem key={"same" + i} el={el} />;
          }
        })}
      </div>
    </div>
  );
})
