import {observer} from "mobx-react";
import './profileComponents.sass'
import CatalogItem from "../../pages/Catalog/catalogItem";
import { Table } from "../../shared/tables/tables";
import styled from "styled-components";
import SearchBar from "./searchBar";
import { ButtonLight } from "../../shared/buttons/buttons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";

const WishList = observer(() => {
    const [ammount, setAmmount] = useState(4)
    const {t} = useTranslation()
    const userStore = useInjection(UserStore)


    return (
        <SmoothAppearance trigger={userStore.user}>
        <div className='lk-myNft'>
           {/* <SearchBar owned user_id={userStore.user?._id} /> */}
            <div className='mynft-container'>
                {userStore.user?.wishlist?.map((el,i)=>{
                    console.log('hiwww',el)
                        return(
                            <CatalogItem key={'my-wish-nft'+i} el={el} isAuc={true} />
                        )
                })}
            </div>
        </div>
        </SmoothAppearance>
    )
})


export default  WishList