import {useHistory, useParams} from "react-router";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import PrivatePages from "../../../components/auth/auth";
import { ModalForm, TextInput} from "../../../shared/forms/forms";
import {useInjection} from "inversify-react";
import {UserStore} from "../../../stores/user/UserStore";
import {LoginData} from "../../../interfaces/auth";
import {toast} from "react-toastify";
import {Redirect} from "react-router-dom";
import { useTranslation } from "react-i18next";


const ReStore = observer(() => {
    const params = useParams();
    const userStore = useInjection(UserStore)
    const history = useHistory();
    const {t} = useTranslation()
    const [validate, setValidate] = useState(false)
    const [id, setid] = useState()
    const [formData, setFormData] = useState<LoginData>({
        password: ''
    })

    const [password, setPassword] = useState();

    const secondPassword = (e) => {
        setPassword(e.target.value)
    }

    const onChange = (e: any) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        userStore.ValidateReStore(params).then((res) => {
            if(res) {
                // console.log('a', params)

                setValidate(true)
            }
        })

    }, [])


    function RedirectFunction() {
       return history.push('/p/catalog')
    }




    const onSubmit = (e: any) => {
        e.preventDefault();
        if(formData.password !== password) return toast.error(t('toasts::notMatch'));
        //server
        userStore.sendNewPassword(formData, params).then(res => {
            if(res){
                toast.success(t('toast::succsess'));
                setTimeout(() => history.push('/p/catalog'), 200)

            }
        });

    }

    return (
        <PrivatePages>
            {validate ? (
                <ModalForm onSubmit={onSubmit} title={`${t('form::enterNewPass')}`} button={`${t('button::change')}`}>
                    <TextInput title={`${t('form::newPassword')}`} name={'password'} onChange={onChange} hidden={true} />
                    <TextInput title={`${t('form::repeatPassword')}`} onChange={secondPassword} hidden={true} />
                </ModalForm>
            ): (
                <div>
                    <ModalForm onSubmit={RedirectFunction} title={`${t('form::incorrectLink')}`} button={`${t('button::toMain')}`}>
                    </ModalForm>
                </div>
            )}



        </PrivatePages>

    )
})

export default ReStore