import { observer } from "mobx-react";
import "../../components/profile/profileComponents.sass";
import styled from "styled-components";
import { ButtonLight } from "../../shared/buttons/buttons";
import { ModalsEnum, ModalStore } from "../../stores/ModalStore";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { NFTstore } from "../../stores/NFTstore.";
import { useEffect, useState } from "react";
import { Table } from "../../shared/tables/tables";
import { Desc, Name } from "../../components/profile/selling";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";
import { NavLink } from "react-router-dom";
import { url } from "../../components/auth/helper";

export const NothingHere = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: flex-start;
  justify-content: center;
  border: 1px solid rgba(211, 211, 211, 1);
  border-radius: 8px;

  h1 {
    font-size: 32px;
  }
`;
export const Text = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
  p {
    text-align: left;
    max-width: 365px;
  }
`;
const Galleries3D = observer(({ user, match }) => {
  const nftStore = useInjection(NFTstore);
  const userStore = useInjection(UserStore);
  const [eng, setEng] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const transl = (type) => {
    if (type === "loft") {
      return "Лофт";
    } else if (type === "dark-hall") {
      return "Темный зал";
    } else if (type === "minimalism") {
      return "Минимализм";
    } else if (type === "classic") {
      return "Классика";
    } else if (type === "neon") {
      return "Неон";
    }
  };
  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setEng(true);
    } else {
      setEng(false);
    }
  }, [localStorage.getItem("lang")]);
  useEffect(() => {
    // if(userStore.user) {
    nftStore.showAll3Ds();
    // }
  }, []);

  return (
    <SmoothAppearance trigger={nftStore}>
      <div className="lk-myNft">
        {nftStore?.galleries && nftStore?.galleries?.length !== 0 ? (
          <div>
            <div
              style={{
                height: "70px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginBottom: "30px",
              }}
            >
              <h1 style={{ margin: "20px" }}>{t("sidebar::3d")}</h1>
            </div>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <div style={{ textAlign: "left", paddingLeft: "30px" }}>
                      {t("3D::name")}
                    </div>
                  </th>
                  <th>{t("3D::titleg")}</th>
                  <th>
                    <div style={{ textAlign: "left", paddingLeft: "30px" }}>
                      {t("3D::type")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {nftStore?.galleries &&
                  nftStore?.galleries
                    ?.map((el, i) => {
                      return (
                        <tr
                          key={i}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push(`./3dgalleries/${el?.fileName}`)
                          }
                        >
                          <td
                            style={{
                              width: "1px",
                              padding: "0px 10px 0 20px ",
                            }}
                          >
                            <img src={url + "/" + el?.user?.photo} />
                          </td>
                          <td>
                            <Name
                              style={{ textAlign: "left", paddingLeft: "30px" }}
                            >
                              {el?.user?.fullname}
                            </Name>
                          </td>
                          <td style={{ minWidth: "120px", width: "120px" }}>
                            <Name>{el.title}</Name>
                          </td>
                          <td>
                            <Desc
                              style={{ textAlign: "left", paddingLeft: "30px" }}
                            >
                              {eng
                                ? el.galleryType.replace("-", " ").toUpperCase()
                                : transl(el.galleryType)}
                            </Desc>
                          </td>
                          {/* {isMe&&<td>
                                <img src={require('../../images/modal-cross.png')}style={{width:'15px', height:'15px',cursor:'pointer'}} onClick={()=>modalStore.showModal(ModalsEnum.CollectionsDelete,{id: el?._id, title: el.name})}></img>
                            </td>} */}
                        </tr>
                      );
                    })
                    .reverse()}
              </tbody>
            </Table>
          </div>
        ) : (
          <NothingHere
            style={{
              display: userStore.user?._id === user?._id ? "flex" : "none",
            }}
          >
            <Text>
              <h1>{t("collections::noGalleries")}</h1>
              <p>{t("collections::noGalleriesD")}</p>

              <ButtonLight
                style={{ width: "180px" }}
                onClick={() => history.push("../../../../p/catalog")}
              >
                {t("button::toCatalog")}
              </ButtonLight>
            </Text>

            <img
              src={require("../../images/looking-out.svg")}
              style={{ padding: "28px" }}
            />
          </NothingHere>
        )}
      </div>
    </SmoothAppearance>
  );
});

export default Galleries3D;
