import "./catalog.sass";
import { url } from "../../components/auth/helper";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useEffect, useState, lazy, Suspense } from "react";
import { useInjection } from "inversify-react";
import { UsersStore } from "../../stores/users/UsersStore";
import Web3 from "web3";
import ReactPlayer from "react-player";
// import Preview from './Preview';
import { useTranslation } from "react-i18next";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faHeart, faHourglassStart, faStar } from '@fortawesome/free-solid-svg-icons';
import { NFTstore } from "../../stores/NFTstore.";
import { UserStore } from "../../stores/user/UserStore";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { showTimerTo } from "./functions";

const Preview = lazy(() => import("./Preview"));

const Container = styled.div`
  transition: all 0.3s ease-in-out;
  cursor: default;

  &:hover {
    transform: scale(1.01);
  }
  & #pointer {
    cursor: pointer;
  }
  & .author {
    cursor: pointer;
    &img {
      display: none;
      width: 24px !important;
      margin: 0;
    }
  }
`;
export interface ILike {
  liked: boolean;
  wished: boolean;
}
const WishLikes = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  & div {
    border-radius: 3px;
    background-color: #ad8bda;
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
  }
  & .like {
    padding: 0px 5px;
    margin-left: 5px;
    & svg {
      margin-left: 5px;
      & path {
        stroke: white;
        stroke-width: 35px;
        fill: ${(p: ILike) => (p.liked ? "white" : "transparent")};
      }
    }
  }
  & .views {
    padding: 0px 5px;
    margin-left: 5px;
    & svg {
      margin-left: 5px;
      & path {
        /* stroke: white;
    stroke-width: 35px; */
        fill: white;
      }
    }
  }
  & .wish {
    padding: 4px 5px;
    & svg {
      & path {
        stroke: white;
        stroke-width: 35px;
        fill: ${(p: ILike) => (p.wished ? "white" : "transparent")};
      }
    }
  }
`;

const Price = styled.div`
  font-family: Releway-Bold;

  font-weight: 600;
  font-size: 22px;
  color: #273847;
`;
// const Info = styled.div`
// display:flex;
// flex-direction:row;
// margin-left: auto;
// & p {
//     margin-right: 5px;
// }
// & span {
//     font-family: Releway-semiBold;
//     font-size: 16px;

// }

// `

const CatalogItem = observer(({ el, mostExpensive, showDelayTokens }: any) => {
  const history = useHistory();
  const usersStore = useInjection(UsersStore);
  const userStore = useInjection(UserStore);
  const nftStore = useInjection(NFTstore);
  const { t } = useTranslation();
  let web3 = new Web3(process.env.RPC_URL_97);

  const [isAuc, setIsAuc] = useState(false);
  const [daysLeft, setDaysLeft] = useState<string>("");
  const [daysToStart, setDaysToStart] = useState<string>("");
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [wished, setWished] = useState(false);
  const [eng, setEng] = useState(false);

  useEffect(() => {
    if (el?.isSelling && el?.auction?.open) {
      setIsAuc(true);
    }

    let int = setInterval(() => {
      let toEnd = showTimerTo(el?.auction?.dateEnd, t);
      setDaysLeft(toEnd);
      let toStart = showTimerTo(el?.auction?.startDate, t);
      setDaysToStart(toStart);
    }, 1000);
    if (el?.auction?.open) {
      int;
    }
    return () => {
      clearInterval(int);
    };
  }, [el]);

  useEffect(() => {
    showWishLikes();
  }, [userStore.user, el.likes]);
  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setEng(true);
    } else {
      setEng(false);
    }
  }, [localStorage.getItem("lang")]);
  const showWishLikes = () => {
    if (userStore.user) {
      const l = el.likes?.some((id) => id === userStore.user?._id);
      const w = userStore.user?.wishlist?.some((wish) => wish._id === el._id);
      setLiked(l);
      setWished(w);
    }
    const v = el.likes ? el.likes.length : 0;
    setLikes(v);
  };

  const transl = (category) => {
    if (category === "Изображение") {
      return "Image";
    } else if (category === "Векторное изображение") {
      return "Vector Image";
    } else if (category === "Панорамное изображение") {
      return "Panoramic Image";
    } else if (category === "Видео") {
      return "Video";
    } else if (category === "Панорамное видео") {
      return "Video";
    } else if (category === "3Д Видео") {
      return "3D Video";
    } else if (category === "3Д Модель") {
      return "3D Model";
    } else if (category === "Интерактивный контент") {
      return "Interactive content";
    }
  };
  const authorClick = (e) => {
    e.stopPropagation();
    usersStore.getOneUser(el.owner.user_id);
    history.push({
      pathname: "/p/profile/artist/" + el.owner.user_id,
      state: { user_id: el.owner.user_id },
    });
  };

  const likeClick = () => {
    if (userStore.isAuth) {
      nftStore.likeNft(el._id);
      const newV = liked === true ? likes - 1 : likes + 1;
      setLiked(!liked);
      setLikes(newV);
    } else toast.error("Необходимо авторизироваться");
  };

  const wishClick = () => {
    if (userStore.isAuth) {
      userStore.addTokenToWishlist(el._id);
      setWished(!wished);
    } else toast.error("Необходимо авторизироваться");
  };

  return (
    <Container className="ct-contentItem">
      <div style={{ position: "relative" }}>
        <Suspense fallback={null}>
          <Preview
            type={el?.category}
            history={history}
            el={el}
            most={mostExpensive}
          ></Preview>
        </Suspense>
        {el.owner?._id !== userStore.user?._id && (
          <WishLikes liked={liked} wished={wished}>
            <div className="wish" onClick={wishClick}>
              {/* <FontAwesomeIcon icon={faStar}  /> */}
              <img
                src={
                  wished
                    ? require("../../images/icons/star-active.svg")
                    : require("../../images/icons/star.svg")
                }
                style={{ width: "17px", height: "17px" }}
              />
            </div>
            <div className="like" onClick={likeClick}>
              {likes}
              {/* <FontAwesomeIcon icon={faHeart}  /> */}
              <img
                src={
                  liked
                    ? require("../../images/icons/heart-active.svg")
                    : require("../../images/icons/heart.svg")
                }
                style={{
                  width: "17px",
                  height: "17px",

                  marginLeft: "3px",
                }}
              />
            </div>
            {/* <div className='views' >
                                {el.views}
                                <img src={require('../../images/icons/eye.svg')}style={{width:'21px', height:'17px',filter:'invert(1)'}} className='icon'/>
                            </div> */}
          </WishLikes>
        )}
      </div>
      <div className="ct-background">
        <div className="ct-contentData">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              transform: "translateY(-15px)",
            }}
          >
            {/* <img className='author' src={url+'/'+el?.owner?.photo}  onClick={authorClick}/> */}
            <div className="author" onClick={authorClick}>
              {el?.owner?.fullname}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {el.isSelling ? (
              <>
                <span style={{ transform: "translateY(-15px)" }}>
                  {isAuc ? `${t("preview::bet")}` : `${t("preview::price")}`}:
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <Price
                    style={{
                      maxWidth: "122px",
                      color: "#273847",
                      overflowX: "hidden",
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                      textAlign: "end",
                    }}
                  >
                    {el?.price
                      ? Number(
                          web3.utils.fromWei(el?.price?.toString(), "ether")
                        ).toFixed(3)
                      : "??"}
                    <br />
                    BNB
                  </Price>
                </span>
              </>
            ) : (
              <Price
                style={{
                  transform: "translateY(-30px)",
                  marginTop: "30px",
                  fontSize: "14px",
                  textAlign: "right",
                }}
              >
                {t("preview::sold")}
              </Price>
            )}
          </div>
        </div>
        <div
          id="pointer"
          className="ct-contentTitle"
          onClick={() => history.push(`/p/nft/${el._id}`)}
        >
          {el?.title}
        </div>
        <div className="ct-contentTags">
          <p>{eng ? transl(el?.category) : el?.category}</p>

          {isAuc && (
            <div className="ct-contentAucTime">
              {showDelayTokens ? (
                <>
                  <img
                    src={require("../../images/icons/hourglass-start.svg")}
                    style={{
                      width: "17px",
                      height: "17px",
                      marginRight: "3px",
                    }}
                  />
                  {/* <FontAwesomeIcon className='ct-contentAucTimeIco' icon={faHourglassStart} /> */}
                  <p>{daysToStart}</p>
                </>
              ) : (
                <>
                  <img
                    className="ct-contentAucTimeIco"
                    src={require("../../images/clock-regular 2.png")}
                  />
                  <p>{daysLeft}</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
});

export default CatalogItem;

{
  /* <Info>
<p>{isAuc?'Ставка':'Цена'} </p>
&nbsp;
<span style={{display:'inline-block' ,maxWidth:'40px', overflow:'hidden', textOverflow:'ellipsis'}}>{!isAuc ? el.price : el.auction?.startPrice}</span>
<span> BNB</span>
</Info> */
}
