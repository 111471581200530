
import { useTranslation } from 'react-i18next'
import { SmoothAppearance } from '../../shared/layout/smoothAppearance'
import './faq.css'
// import useKrpano from 'react-krpano-hooks'


const FAQ = () => {
    const {t} = useTranslation()
    // const {containerRef} = useKrpano({
    //     globalFunctions: {
    //       logNewScene: (scene) => {
    //         console.log('New scene: ', scene)
    //       },
    //     },
    //     scriptPath: "http://localhost:3000/ext/krpano/krpano.js" ,
    //     embeddingParams: {
    //       xml: "http://localhost:3000/ext/krpano/tourkek.xml" ,
    //     }
      
    //   })
    let faqItem = [
        {
            q:`${t('faq::title1')}`,
            a: `${t('faq::text1')}`
        },
        {
            q:`${t('faq::title2')}`,
            a: `${t('faq::text2')}`
        },
        {
            q:`${t('faq::title3')}`,
            a: `${t('faq::text3')}`
        },
        {
            q:`${t('faq::title4')}`,
            a: `${t('faq::text4')}`
        },
        {
            q:`${t('faq::title5')}`,
            a: `${t('faq::text5')} `
        },
    ]
    return (
        <SmoothAppearance trigger>
        <div className='faq-main'>
        {/* <div ref={containerRef} /> */}
           {
               faqItem.map((item,i)=>{
                   return  (
                   <div key={i} className='faq-item'>
                        <h2 className='faq-q'>{item.q}</h2>
                        <p className='faq-a'>{item.a}</p>
                    </div>)    
               } 
            )
            }
        </div>
        </SmoothAppearance>
    )
}


export default FAQ