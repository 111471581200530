import {useInjection} from 'inversify-react'
import {observer} from 'mobx-react'
import { useState } from 'react'
import { NavLink, useRouteMatch} from 'react-router-dom'
import { DropdownButton, DropdownItem, DropdownMenu } from '../../buttons/dropdownMenu'
import { Chevron, NavButton } from '../../../shared/buttons/buttons'
import { UIStore } from '../../../stores/uiStore/uiStore'
import { isProd } from '../../../constants';
import { NFTstore } from '../../../stores/NFTstore.'
import console from 'console'
import { useTranslation } from 'react-i18next'




const NavbarButton = observer(({link,i}) => {
    let match = useRouteMatch();
    const nftStore = useInjection(NFTstore)
    const [hover, setHover] = useState(false)
    const {i18n} = useTranslation()
    const changeLang = (lang) => {
        let l = lang==='Русский'?'ru':'en'
        i18n.changeLanguage(l)
        localStorage.setItem('lang', l);
    }
    if((i<=4)&&isProd) {
        return <NavButton></NavButton>
    }
    
    return (
            <NavButton
            onMouseEnter={()=>setHover(true)}
            onMouseLeave={()=>setHover(false)}
            style={{borderColor: hover && link.dropdown?'#894ADA':'', display:i===5&&'block'}}
            
            >
                <NavLink
                    style={{textDecoration:'none', color:'#273847',fontFamily:'Releway-medium',}}
                    activeStyle={{ fontFamily:link.path?'Releway-bold':'Releway-medium'}}
                    to={link.path?`${match.path}${link.path}`:window.location.pathname+window.location.hash}

                >
                        {link.title}
                </NavLink>
                {link.dropdown&&<Chevron src={require('../../../images/chevron.png')} />}
                {link.dropdown&& hover &&
                <DropdownMenu style={{top:'26px'}} onClick={()=>setHover(false)}>
                    {link.dropdown.map((el,i)=>{
                        return(
                            <NavLink  
                                    style={{textDecoration:'none'}}
                                    to={link.path?`${match.path}${el.path}`:window.location.pathname+window.location.hash} 
                                    /*only for catalog*/ onClick={()=>el.tag ? nftStore.setNavbarCatalogFilter(['tags',el.tag]) : link.path===null?changeLang(el.title):''}
                                >
                                <DropdownButton>
                                            {el.title}
                                   
                                </DropdownButton>
                            </NavLink>
                        )
                    })}
                </DropdownMenu>
                }
            </NavButton>
                
    )})

export default NavbarButton

