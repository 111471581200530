import React from 'react';
import Modal from "../components/Modal";
import {ModalsEnum, ModalStore} from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { SubmitButton,TextInput  } from '../shared/forms/forms';
import { UserStore } from '../stores/user/UserStore';
import { toast } from 'react-toastify';
import { NFTstore } from "../stores/NFTstore.";
import { web3 } from '../constants';
import { WithTranslation, withTranslation } from 'react-i18next';
interface IConnectModalProps extends WithTranslation {
    data: { stop: boolean };
    idx: number;
}

interface IConnectModalState {
}

@observer
class TestModal extends React.Component<IConnectModalProps, IConnectModalState> {
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(UserStore)
    declare protected readonly userStore: UserStore;
    @resolve( NFTstore)
    declare protected readonly nftStore:  NFTstore;
    state = { bet: '' }
    onChange = (e) =>{
        this.setState({bet:e.target.value})
    }
    onSubmit = () =>{

        if(!this.userStore.isAuth) return toast.error(this.props.t('toasts::needAuth'))
        else if(this.userStore.isAuth&&
            web3.utils.fromWei(this.nftStore?.oneNFT?.price.toString(), "ether" )<
            this.state.bet&&web3.utils.fromWei(this.userStore?.user?.balance?.toString(), "ether" )>this.state.bet
            ) {
            // setLoader(true)
            this.nftStore.betNFT(this.nftStore.oneNFT._id, this.state.bet)
               .then(res =>{
                // console.log(res)
               })
               .catch(err=>{
                   let msg = err.response.data.err
                   err?.message && toast.error(msg)
               })
               this.modalStore.hideModal(this.props.idx)
        }
        else if(this.nftStore?.oneNFT?.price>this.state.bet&&web3.utils.fromWei(this.userStore?.user?.balance?.toString(), "ether" )>this.state.bet) {
            // setLoader(true)
            toast.error(this.props.t('toasts::smallBet'))
        }
        else if(web3.utils.fromWei(this.userStore?.user?.balance?.toString(), "ether" )<=(this.state.bet+this.userStore.bnbUsd*0.02)) {
            // setLoader(true)
            toast.error(this.props.t('toasts::nofunds'))
        }
    }
    render() {
        return (
            <Modal modalKey={ModalsEnum.Test} idx={this.props.idx}>
                <TextInput  title={this.props.t('modals::enterSum')} type='number' onChange={this.onChange} onEnter={this.onSubmit} border currentBid={this.nftStore?.oneNFT?.price}></TextInput>
                <SubmitButton disabled={this.props.data.stop} onClick={this.onSubmit}>{this.props.t('modals::placeBet')}</SubmitButton>
                
            </Modal>
        )
    }
}
export default withTranslation()(TestModal);

