import { Container } from "inversify";
import { UIStore } from "./uiStore/uiStore";
import { UserStore } from "./user/UserStore";
import { UsersStore } from "./users/UsersStore";
import { wrapHistory } from "oaf-react-router";
import {createBrowserHistory, History} from "history";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import {ModalStore} from "./ModalStore";
import {NFTstore} from "./NFTstore.";
// @ts-ignore
export class HistoryStore implements History {}

export class RootStore {
    public routerStore: RouterStore;
    public historyStore: HistoryStore;
    public userStore: UserStore;
    public usersStore: UsersStore;
    public UIStore: UIStore;
    public nftStore: NFTstore;
    public modalStore: ModalStore;
    public container: Container;

    public constructor() {
        const browserHistory = createBrowserHistory();
        wrapHistory(browserHistory, {
            smoothScroll: true,
            primaryFocusTarget: 'body',
        });
        this.routerStore = new RouterStore();
        this.historyStore = syncHistoryWithStore(browserHistory, this.routerStore);
        this.userStore = new UserStore(this);
        this.usersStore = new UsersStore(this);
        this.UIStore = new UIStore(this);
        this.nftStore = new NFTstore(this)
        this.modalStore = new ModalStore(this);
        this.container = new Container();
        this.container.bind(RouterStore).toConstantValue(this.routerStore);
        this.container.bind(HistoryStore).toConstantValue(this.historyStore);
        this.container.bind(UserStore).toConstantValue(this.userStore);
        this.container.bind(UsersStore).toConstantValue(this.usersStore);
        this.container.bind(UIStore).toConstantValue(this.UIStore);
        this.container.bind(NFTstore).toConstantValue(this.nftStore);
        this.container.bind(ModalStore).toConstantValue(this.modalStore);
    }
}    

