export const showTimerTo = (date, t) => {

   const dayString =  t && t('common::daysShort')

    let dateNow = Date.now();
    let dateEnd = Date.parse(date);
    let timeLeft = dateEnd - dateNow;
    let days = Math.trunc(timeLeft / (24 * 1000 * 60 * 60) + 1);
    let hours = Math.trunc(timeLeft / (1000 * 60 * 60));
    let minutes = Math.trunc((timeLeft - hours * 3600000) / (1000 * 60));
    let seconds = Math.trunc(
      (timeLeft - hours * 3600000 - minutes * 60000) / 1000
    );
    let prhours = hours >= 10 ? hours : "0" + hours;
    let prminutes = minutes >= 10 ? minutes : "0" + minutes;
    let prseconds = seconds >= 10 ? seconds : "0" + seconds;

    if (timeLeft <= 0){
      return '00 : 00 : 00'
    }
    if (timeLeft > 86400000) {
      return days.toString()+' '+dayString
    }
    if (timeLeft < 86400000 && timeLeft > 0) {
      return prhours + " : " + prminutes + " : " + prseconds;
    }
  };