import styled from 'styled-components'

export const Table = styled.table`
    width: 100%;
    border-spacing: 0px;

    & tr{
        height: 78px;
        width: 486px;
        padding: 0px 20px;
        background-color: white;
        border-bottom: 1px solid #CBCBCB;
    }
    & td {
        background: none;
        border: none;
        text-align: center;
        font-family: Releway-medium;
    }
    & th {
        background: none;
        border: none;
        text-align: center;
        font-family: Releway-medium;
    }
    & img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
    }
    @media (max-width: 1000px){
        width: 100%;
    }
        
`
