import './catalog.sass'
import CatalogItem from './catalogItem'
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";

import Loader from "react-loader-spinner";
import {NFTstore} from "../../stores/NFTstore.";
import { ButtonLight } from '../../shared/buttons/buttons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SmoothAppearance } from '../../shared/layout/smoothAppearance';


const CatalogBlock = observer(({showDelayTokens}:any) => {
    const nftStore = useInjection(NFTstore)
    const {t} = useTranslation()
    return (
        <SmoothAppearance trigger={nftStore.NFT}>
            <div className='ct-contentGrid' 
            // style={{opacity:opacity? 0:1, transition:"all 0.7s ease"}}
            >
                {nftStore?.NFT && nftStore.NFT.map((el,i)=>{
                        return(
                            <CatalogItem key={'cat-item'+el._id} el={el} isAuc={i%2===0?true:false} showDelayTokens={showDelayTokens}/>
                        )
                })}
            </div>
            {nftStore.NFT?.length>=10&&<div style={{display:nftStore.NFTbutton?'flex':'none', justifyContent:'center', margin: '20px 0 0'}}>
                <ButtonLight  width='200px' onClick={()=>nftStore.loadNewPage()}>{t('button::showMore')}</ButtonLight>
            </div>}
        </SmoothAppearance>
    )
})

export default CatalogBlock