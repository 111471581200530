import { observer } from "mobx-react";
import "../profile.sass";
import { useEffect, useState } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import MyNFT from "../../../components/profile/myNFT";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  RouteComponentProps,
} from "react-router-dom";
import styled from "styled-components";
import AboutArtist from "./about";
import CreatedNFT from "../../../components/profile/created";
import CollectionsNFT from "../../../components/profile/collections";
import { UsersStore } from "../../../stores/users/UsersStore";
import OneCollection from "../../../components/profile/oneCollection";
import Blog from "../../../components/profile/blog";
import UserPost from "../../../components/profile/userPost";
import GalleryPage from "../../../components/profile/3d";
export const Path = process.env.REACT_APP_IP;
interface IProps extends RouteComponentProps {
  user_id: string;
}
const Container = styled.div`
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
    & .desktop {
      display: none;
    }
    & .mobile {
      display: block;
    }
  }
  @media (min-width: 1001px) {
    & .desktop {
      display: block;
    }
    & .mobile {
      display: none;
    }
  }
`;

interface stateType {
  user_id: string;
}

const ArtistProfile = observer(({ match }) => {
  const userStore = useInjection(UserStore);
  const usersStore = useInjection(UsersStore);
  const location = useLocation();
  const [auth, setAuth] = useState(true);
  const { state } = useLocation<stateType>();
  const userId = state?.user_id;

  if (!auth) {
    return <Redirect to={"/p/auth"} />;
  }
  return (
    // <PrivatePages>
    <Container>
      <AboutArtist user={usersStore.user} />
      <Switch>
        <Route exact path={`${match.path}/`}>
          <MyNFT user={usersStore.user} />
        </Route>
        <Route exact path={`${match.path}/created`}>
          <CreatedNFT user={usersStore.user} />
        </Route>
        <Route exact path={`${match.path}/collections`}>
          <CollectionsNFT user={usersStore.user} match={match} />
        </Route>
        <Route exact path={`${match.path}/collections/:id`}>
          <OneCollection user_id={userId} />
        </Route>
        <Route exact path={`${match.path}/blog`}>
          <Blog
            _id={usersStore.user?._id}
            user_id={usersStore?.user?.user_id}
          />
        </Route>
        <Route exact path={`${match.path}/blog/:id`}>
          <UserPost _id={usersStore.user?._id}></UserPost>
        </Route>
        <Route exact path={`${match.path}/3d`}>
          <GalleryPage user={usersStore.user} match={match} myPage={false} />
        </Route>
      </Switch>
    </Container>
    // </PrivatePages>
  );
});

export default ArtistProfile;

{
  /* <Mobile className='mobile'>
                        <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom: '2px solid #F2E9EA', marginBottom:'20px'}}>
                            <div className='lk-title' style={{borderColor:'transparent', margin:0, padding:0}}>Личный кабинет</div>
                            <div style={{position:'relative', }}
                                onMouseEnter={()=>setMobSettings(true)}
                                onMouseLeave={()=>setMobSettings(false)}
                            >
                                    <img src={require('../../../images/settings.png')} style={{width: '30px', height:'30px'}} />
                                    {mobSettings&&
                                    <DropdownMenu style={{ right:'0px',top:'30px'}}>
                                        <CopyToClipboard text={`${userStore.user?.public_key}`}>
                                            <a onClick={linkToClipboard}>Скопировать кошелек</a>
                                        </CopyToClipboard>
                                        <CopyToClipboard text={`${window.location.origin}/?ref=${userStore.user?.referal_code}`}>
                                            <a onClick={linkToClipboard}>Скопировать реферальную ссылку</a>
                                        </CopyToClipboard>
                                    </DropdownMenu>}
                            </div>
                        </div>
                        
                        <div className='mob-btn-container' onMouseEnter={()=>setMobDropdown(true)} onMouseLeave={()=>setMobDropdown(false)}>
                            <button style={{fontFamily: 'Releway-bold'}}>
                                Меню
                                <Chevron/>
                            </button>
                            {mobDropdown && 
                            <DropdownMenu style={{top: '45px'}}>
                                <NavLink activeStyle={navActive} to={`${match.path}/profile`}>Личные данные</NavLink>
                                <NavLink activeStyle={navActive} to={`${match.path}/wallet`}>Мой кошелек</NavLink>
                                <NavLink activeStyle={navActive} to={`${match.path}/referals`}>Рефералы</NavLink>
                                {userStore.user?.user_type==='artist'&&
                                <NavLink activeStyle={navActive} to={`${match.path}/analytics`}>Аналитика</NavLink>}
                                <NavLink activeStyle={navActive} to={`${match.path}/nft`}>Мои NFT</NavLink>
                            </DropdownMenu>}
                        </div>
             
                    </Mobile>*/
}
