import { observer } from "mobx-react";
import "./profileComponents.sass";
import styled from "styled-components";
import SearchBar from "./searchBar";
import CatalogItem from "../../pages/Catalog/catalogItem";
import { ButtonLight } from "../../shared/buttons/buttons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";

const NothingHere = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & h1 {
    text-align: center;
  }
`;

const CreatedNFT = observer(({ user, isSold }) => {
  const [ammount, setAmmount] = useState(4);
  const { t } = useTranslation();
  const userStore = useInjection(UserStore);

  useEffect(() => {
    user && userStore.getMintedNFT(user?._id);
  }, [user]);

  userStore.mintedNFT &&
    console.log(JSON.parse(JSON.stringify(userStore.mintedNFT)));

  return (
    <SmoothAppearance trigger={userStore.mintedNFT}>
      <div className="lk-myNft">
        <SearchBar created user_id={user?._id} />

        {user?.works ? (
          <div className="mynft-container">
            {!isSold
              ? userStore.mintedNFT?.ownedNftArray?.map((el, i) => {
                  console.log(
                    "%ccreated.tsx line:45 el.author.",
                    "color: #007acc;",
                    el.author._id,
                    el.owner._id
                  );
                  if (i < ammount) {
                    return (
                      <CatalogItem key={"my-nft" + i} el={el} isAuc={true} />
                    );
                  }
                })
              : userStore.mintedNFT?.ownedNftArray
                  ?.filter((el) => el.author._id !== el.owner._id)
                  .map((el, i) => {
                    console.log(
                      "%ccreated.tsx line:45 el.author.",
                      "color: #007acc;",
                      el.author._id,
                      el.owner._id
                    );
                    if (i < ammount) {
                      return (
                        <CatalogItem key={"my-nft" + i} el={el} isAuc={true} />
                      );
                    }
                  })}
          </div>
        ) : (
          <NothingHere>
            <h1>{t("pCreated::empty")}</h1>
            <img src={require("../../images/looking-out.png")} />
          </NothingHere>
        )}
        {ammount < user?.minted_tokens?.length && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0 0",
            }}
          >
            <ButtonLight width="200px" onClick={() => setAmmount(ammount + 4)}>
              {t("button::showMore")}
            </ButtonLight>
          </div>
        )}
      </div>
    </SmoothAppearance>
  );
});

export default CreatedNFT;
