import { useInjection } from "inversify-react"
import { observer } from "mobx-react-lite"
import { Link, NavLink } from "react-router-dom"
import { UserStore } from "../../stores/user/UserStore"
import { url } from "../auth/helper"
import './profileComponents.sass'
import { useHistory } from 'react-router'
import getDate, { getDateNumbers } from "../../shared/date/getDate"
export interface IMsg {
    title?:string
    text?: string
    img?: string
    nft?: any
    me?: boolean
    _id: string,
    time?: string
}

const Message =observer(({title,text, img, nft, me,_id, time}:IMsg)=>{
    const history = useHistory ()
    const d1 = new Date(time).getTime()
    // console.log(nft)
    const userStore = useInjection(UserStore)
    const deletePost = () =>{
        userStore.deletePost(_id)
    }
    const currentPost = () =>{
        userStore.setCurrentPost(title,text,_id)
    }
    const postPage = () =>{
        history.push(`./blog/${_id}`)
    }
    return (
        <div className='lk-message' onClick={()=>!me&&postPage()}>
            <img src={img ? url+'/'+img:''} style={{width:'100%', objectFit:'cover',maxHeight:'230px'}}></img>
            <div style={{padding:'12px'}}>
                <div className='lk-title' style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
                    <div style={{fontFamily: 'Releway-bold',fontSize: '32px'}}>{title}</div>
                    <div style={{display:'flex'}}>
                        <Link to={`./blog/${_id}`}><img   style={{display:!me?'none':'block',cursor:'pointer',width:'18px'}} src={require('../../images/edit1.jpg')}  onClick={currentPost}></img></Link>
                        <img src={require('../../images/modal-cross.png')}  style={{display:!me?'none':'block',cursor:'pointer',marginLeft:'15px'}}onClick={deletePost}></img>
                    </div>
                </div>
                <div className='lk-text'>{text}</div>  
                <div className='lk-nft' style={{marginTop:'20px'}}>{nft}</div>
                <div className='lk-text'>{time && getDateNumbers(d1, true) }</div>
            </div>         
        </div>
    )
})
export default Message