import { useInjection } from 'inversify-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitButton } from '../../shared/forms/forms'
import { ButtonLight } from '../../shared/buttons/buttons'
import { UserStore } from '../../stores/user/UserStore'
import './profileComponents.sass'
import { useHistory } from 'react-router'
const Post =({user_id})=>{
    const userStore = useInjection(UserStore)
    const history = useHistory()
    const {t} = useTranslation()
    const [focusTa, setFocusTa] = useState(false)
    const [focusI, setFocusI] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [post, setPost] = useState(null)
    const [id, setId] = useState('')
    useEffect(()=>{
        if(userStore.user) {
            console.log(userStore.isAuth)
            userStore.getposts()
            let arr = location?.pathname?.split('/')
            setId(arr[arr.length-1])
        }
    },[user_id])
    useEffect(()=>{
        
       if(!post) {
        setLoaded(!loaded)
        userStore.posts.filter(el=>el._id===id).map((el)=>{
            setPost(el)
        })
    } 
    })
    const onChange =(e)=>{
        setPost({...post, [e.target.name]: e.target.value})
    }
    const onSave =()=>{
        userStore.editPost(post.title, post.text, post._id)
        history.push('../blog')
    }
    const onCancel =()=>{
        history.push('../blog')
    }
    return (
        
        <div className='lk-post'>
            <input className='lk-input'name='title' defaultValue={post?.title} style={{boxShadow:!focusI?'0px 1px 2px 1px rgba(0,0,0,0.61)':'0px 1px 5px 2px rgba(0,0,0,0.61)' }}onChange={onChange}onFocus={()=> setFocusI(true)} onBlur={()=>setFocusI(false)}/>
            <textarea className='lk-textarea'name='text'  defaultValue={post?.text} style={{boxShadow:!focusTa?'0px 1px 2px 1px rgba(0,0,0,0.61)':'0px 1px 5px 2px rgba(0,0,0,0.61)' }}onChange={onChange}onFocus={()=> setFocusTa(true)} onBlur={()=>setFocusTa(false)}/>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <ButtonLight  onClick={onCancel} style={{backgroundColor:'grey',color:'white'}} width='120px'>{t('collections::cancel')}</ButtonLight>
                <ButtonLight width='120px' onClick={onSave}>{t('collections::save')}</ButtonLight>
            </div>
        </div>
    )
}
export default Post 