import ReactPlayer from "react-player"
import { url } from "../../components/auth/helper"
import { useEffect, useState } from "react";


const Preview =({type,history,el, most})=>{
    const [opacity, setOpacity] =useState(true)
    useEffect(()=>{
        if(el===null){
            setOpacity(true)
        }
    },[])
    
    if(type==='Видео' && !el?.preview?.includes('gif')) {
        return (
            <div className={opacity?'noVideo':'changedVideo'} >
                 <ReactPlayer
                    onClick={()=>history.push(`/p/nft/${el._id}`)}
                    url={url+'/'+el?.preview}
                    loop={true}
                    playing={true}
                    width='100%' 
                    muted={true}
                    height= {most?'428px':'252px'}
                    onReady= {()=>{
                        setOpacity(false)
                    }} 
            />
            </div>
           
            
        )
    } else {
        return (
            <img
            id='pointer' src={url+'/'+el?.preview}style={{
                height:most?'428px':'252px',

            }} onLoad= {()=>{
               
                setOpacity(false)
            }} className={opacity?'noImage':'changedImage'}onClick={()=>history.push(`/p/nft/${el?._id}`)}
            
             />
        )
    }
}
export default Preview