import React from 'react';
import { ModalsEnum, ModalStore } from "../stores/ModalStore";
import { observer } from "mobx-react";
import { useInjection } from "inversify-react";
import TestModal from "./TestModal";
import SellTokenModal from "./SellToken";
import AucTokenModal from "./AucToken";
import WithdrawModal from './withdraw';
import CollectionModal from './CollectionModal';
import CollectionsDelete from './CollectionDelete';
import GalleryDelete from './GalleryDelete';
import WithdrawToken from './WithdrawToken'
import RatingModal from './rating'

import MyNFTsModal from './MyNFTsModal';
const MODAL_REGISTRY = {
    [ModalsEnum.Test]: TestModal,
    [ModalsEnum.SellToken]: SellTokenModal,
    [ModalsEnum.AucToken]: AucTokenModal,
    [ModalsEnum.Withdraw]: WithdrawModal,
    [ModalsEnum.Collections]: CollectionModal,
    [ModalsEnum.CollectionsDelete]: CollectionsDelete,
    [ModalsEnum.GalleryDelete]: GalleryDelete,
    [ModalsEnum.WithdrawToken]: WithdrawToken,
    [ModalsEnum.Rating]: RatingModal,
    [ModalsEnum.MyNFTs]: MyNFTsModal,
}

export const ModalsContainer = observer(() => {
    const modalStore = useInjection(ModalStore);

    return (
        <>
            {modalStore.activeModals.map((m, i) => {
                const Component = MODAL_REGISTRY[m.key];
                return <Component key={i} data={m.data} idx={i} />;
            })}
        </>
    )
});
