import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ButtonLight } from "../../shared/buttons/buttons";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";
import { UserStore } from "../../stores/user/UserStore";
import { NFTstore } from "../../stores/NFTstore.";
import { NothingHere, Text } from "./collections";
import { useHistory } from "react-router";
import { ModalsEnum, ModalStore } from "../../stores/ModalStore";
import { Table } from "../../shared/tables/tables";
import { Desc, Name } from "./selling";
const GalleryPage = observer(({user, match, myPage}) => {
    const nftStore = useInjection(NFTstore)
    const modalStore = useInjection(ModalStore)
    const userStore = useInjection(UserStore)
    const history = useHistory();
    const {t} = useTranslation()
    const [eng, setEng] = useState(false)
    const [isMe, setIsMe] = useState(false)
    const transl = (type) =>{
        if(type==='loft') {
            return 'Лофт'
        } else if (type==='dark-hall') {
            return 'Темный зал'
        } else if (type==='minimalism') {
            return 'Минимализм'
        } else if (type==='classic') {
            return 'Классика'
        } else if (type==='neon') {
            return 'Неон'
        }
     }
     useEffect(()=>{
        if(localStorage.getItem('lang')==='en') {
            setEng(true)
        } else {setEng(false)}
    },[localStorage.getItem('lang')])
    useEffect(()=>{
       if(user) {
            nftStore.showUser3Ds(user._id)
       }
       if(user?._id===userStore.user?._id) {
           setIsMe(true)
           // console.log("hi me")
       }
       console.log(user)
       return ()=>{
           userStore.clearCollections() 
       }
       
    },[user])
       return (
          
       <SmoothAppearance trigger={nftStore.oneGallery}>
        <div className='lk-myNft'> 
        {nftStore?.userGalleries?.length!==0?
        <div> 
            {myPage && <div  style={{height:'70px', width:'100%', display:'flex', justifyContent:"space-between",alignItems:'flex-start'}}>
                <h1 style={{marginBottom:'30px'}}>{t('sidebar::3d')}</h1>
                {isMe && 
                (user?.user_type  === 'artist' ?
                    <NavLink to={`${match.path}/3d/create`} style={{textDecoration:'none'}}>
                        <ButtonLight style={{width:'170px',minWidth:'180px',display:userStore.user?._id===user?._id?'block':'none'}}>
                            {t('3D::create')}
                        </ButtonLight>
                    </NavLink>
                    :
                    <NavLink to={`${match.path}/become_artist`} style={{textDecoration:'none'}}>
                        <ButtonLight width='180px' onClick={()=>history.push(`${match.path}/become_artist`)}>
                            {t('collections::create')}
                        </ButtonLight>
                    </NavLink>
                )}
            </div>}
            <Table>
            <thead>
                <tr>
                    <th><div  style={{textAlign:'left'}}>{t('3D::titleg')}</div></th>
                    <th ><div style={{textAlign:'left',paddingLeft:'30px'}}>{t('3D::type')}</div></th>
                 </tr>
                </thead>
            <tbody>
        {nftStore?.userGalleries?.map((el,i)=>{
            return ( 
                         <tr key={i}style={{cursor:'pointer'}} >
{/*                             
                            <td style={{width:'1px', padding: '0 10px 0 0'}}>
                                <img src={url+el?.author.photo} />
                            </td>
                            <td>
                                <Name>{el?.author.fullname}</Name>
                            </td> */}
                            <td onClick={()=>history.push(`../../../../p/3dgalleries/${el?.fileName}`)} style={{minWidth:'120px', width:'120px'}}>
                                <Name  style={{textAlign:'left'}}>{el?.title}</Name>
                            </td>
                            <td onClick={()=>history.push(`../../../../p/3dgalleries/${el?.fileName}`)}>
                                <Desc style={{textAlign:'left',paddingLeft:'30px'}}>{eng?el.galleryType.replace('-',' ').toUpperCase():transl(el.galleryType)}</Desc>
                            </td>
                            {myPage && <td>
                                <img src={require('../../images/modal-cross.png')}style={{width:'15px', height:'15px',cursor:'pointer'}} onClick={()=>modalStore.showModal(ModalsEnum.GalleryDelete,{id: el?.fileName, title: el.title, userId: el.user})}></img>
                            </td>}
                        </tr>
            )
            }).reverse()}
            </tbody>
                       
            </Table>
           
            </div>
        :  
           <NothingHere style={{display:userStore.user?._id===user?._id?'flex':'none'}}>
                   <Text >
                       <h1>{t('3D::nothing')}</h1>
                       <p>{t('3D::uCanBuyNft')}</p>
                       {isMe&&
                       (userStore.user?.user_type  === 'artist' ?
                           <ButtonLight style={{width:'180px'}}onClick={()=>history.push("./3d/create")}>{t('3D::create')}</ButtonLight>
                           :
                           <ButtonLight width='180px' onClick={()=>history.push(`${match.path}/become_artist`)}>
                               <NavLink to={`${match.path}/become_artist`}>{t('3D::create')}</NavLink>
                           </ButtonLight>
                       )}
                       <ButtonLight style={{width:'180px'}}onClick={()=>history.push("../../../../p/3dgalleries")}>{t('button::toCatalog')}</ButtonLight>
                   </Text>
                   
                   <img src={require('../../images/cube.png')} style={{padding:'28px',maxWidth:'95vw'}}/>
           </NothingHere>}
        </div>
     
      </SmoothAppearance>
    )
       
   })
   
   
   export default GalleryPage