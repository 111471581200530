import {observer} from "mobx-react";
import './profileComponents.sass'
import { Table } from "../../shared/tables/tables";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Chart from "../../shared/charts/chart";
import { useEffect } from "react";
import { useState } from "react";
import { web3 } from "../../constants";
import getDate from "../../shared/date/getDate";

const Graph = styled.img`

@media(max-width:1000px){
    width: 100%;
}
`

const Analytics = observer(({allSalles}) => {
    const {t} = useTranslation()
    const array = [1,2,3,4,5]
    const [dates, setDates]=useState([])
    const [prices, setPrices]=useState([])
    const [actors, setActors]=useState([])
    useEffect(()=>{
        console.log(allSalles)
        allSalles?.map((el)=>{
            setDates(dates => [...dates, el.date ]);
            setPrices(price => [...price, el?.price ? parseFloat(web3.utils.fromWei(el?.price?.toString(), "ether" )):0]);
            setActors(actors =>[...actors, {from: el.from.user_id, to: el.to.user_id}])
        },)
    },[allSalles])
    // useEffect(()=>{
    //     console.log(prices, dates)
    // },[prices])
    return (
       <div className='lk-personalData'>

           <div className='lk-analyticTitle' style={{marginTop:'20px'}}>
               <img src={require('../../images/graph-icon.png')}/>
               {t('pAnalytics::graph')}
           </div>
           {allSalles?.length>0?<Chart dates={dates} actors={actors} prices={prices}></Chart>:
           <Table className='lk-table'>
                <tbody>
                    <tr>
                        <td style={{textAlign: 'center'}}>{t('pAnalytics::noBuys')}</td>
                    </tr>
                </tbody>
            </Table>
           }
           {/* <div className='lk-analyticTitle'>
               <img src={require('../../images/list-icon.png')}/>
               {t('pAnalytics::news')}
           </div>
           
           <Table className='lk-table'>
                <tbody>

                        <tr>
                            <td style={{textAlign: 'center'}}>{t('pAnalytics::newsEmpty')}</td>
                        </tr>


                </tbody>
            </Table>
            <div className='lk-analyticTitle'>
               <img src={require('../../images/list-icon.png')}/>
               {t('pAnalytics::releases')}
            </div>
           <Table className='lk-table'>
                <tbody>
                    <tr>
                        <td style={{textAlign: 'center'}}>{t('pAnalytics::releasesEmpty')}</td>
                    </tr>
                </tbody>
            </Table> */}
       </div>
    )
})


export default  Analytics