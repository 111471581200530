import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ButtonLight } from "../../shared/buttons/buttons";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";
import { UserStore } from "../../stores/user/UserStore";
import { NothingHere, Text } from "./collections";
import { useHistory } from "react-router";
import { ModalsEnum, ModalStore } from "../../stores/ModalStore";
import ReactPlayer from "react-player";
import { NFTstore } from "../../stores/NFTstore.";
import { SubmitButton } from "../../shared/forms/forms";

const GalleryCreate = observer(({_id}) => {
    const modalStore = useInjection(ModalStore)
    const userStore = useInjection(UserStore)
    const nftStore = useInjection(NFTstore)
    const history = useHistory();
    const {t} = useTranslation()
    const [title, setTitle] = useState('')
    const [roomType, setRoomType] = useState<string>('')
    const [nfts, setNfts] = useState([])
    const onChange = (e) =>{
        setTitle(e.target.value)
    }
    const deleteNftBind = (id,ind) =>{
        setNfts(nfts.filter((item,i) => i!==ind));
    }
    useEffect(()=>{
        userStore.getOwnedNFT(_id)
        
    },[_id])
    useEffect(()=>{
        if(userStore.currentPostNft) {
            setNfts (nfts => [...nfts, userStore.currentPostNft])
        }
    },[userStore.currentPostNft])
    useEffect(()=>{
      console.log(nfts)
    },[nfts])
    const submit = () =>{
      nftStore.create3D(title, roomType, nfts, t('3D::success'))
      history.push('../')
    }
       return (
          
       <SmoothAppearance trigger={userStore}>
        <div className='lk-myNft'>   

        <h3 className='lk-bigTitle'>{t('3D::field1')}</h3>
        <input required name='title' defaultValue={title} type="text" onChange={onChange} className="form-control" id="token-name" aria-describedby="token-name-desc" />
   
        <h3 className='lk-bigTitle'>{t('3D::field2')}</h3>
        <div style={{display:'flex',flexWrap:'wrap'}}>
            <div className="col-md-2 lk-gap">
              <input required type="radio"  className="btn-check" name="contenttype" id="ctype-image" autoComplete="off" />
              <label className="btn-outline-primary" htmlFor="ctype-image" onClick={()=>setRoomType('neon')}>
                <img src={require('../../images/preview/неон.png')} className='lk-rooms'></img>
                <p  className="lk-titles">{t('3D::neon')}</p>
              </label>
            </div>
            <div className="col-md-2 lk-gap">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-panimage" autoComplete="off" />
              <label className="btn-outline-primary" htmlFor="ctype-panimage" onClick={()=>setRoomType('loft')}>
                <img src={require('../../images/preview/лофт.png')} className='lk-rooms'></img>
                <p  className="lk-titles">{t('3D::loft')}</p>
              </label>
            </div>
            <div className="col-md-2 lk-gap">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-vector" autoComplete="off" />
              <label className="btn-outline-primary" htmlFor="ctype-vector" onClick={()=>setRoomType('dark-hall')}>
                 <img src={require('../../images/preview/темный зал.png')}className='lk-rooms'></img>
                 <p  className="lk-titles">{t('3D::darkHall')}</p>
              </label>
            </div>
            {/* <div className="col-md-2 lk-gap">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-video" autoComplete="off" />
              <label className="btn-outline-primary" htmlFor="ctype-video" onClick={()=>setRoomType('minimalism')}>
                <img src={require('../../images/preview/минимализм.png')} className='lk-rooms'></img> 
                <p  className="lk-titles">Минимализм</p>
              </label>
            </div> */}
            <div className="col-md-2 lk-gap">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-dark" autoComplete="off" />
              <label className="btn-outline-primary" htmlFor="ctype-dark" onClick={()=>setRoomType('classic')}>
                <img src={require('../../images/preview/классика.png')} className='lk-rooms'></img> 
                <p  className="lk-titles">{t('3D::classic')}</p>
              </label>
            </div>
          </div>
          
          <h3 className='lk-bigTitle'>{t('3D::field3')}</h3>
          <div style={{display:'flex',flexWrap:'wrap'}}>
          {nfts?.map((el,i)=>{
              return (
                  <div key={el.id+i} className="col-md-2 lk-gap" style={{overflow:'hidden',textOverflow: 'ellipsis'}}>
                        <div style={{width:'95%',display:'flex',justifyContent:'flex-end',transform:'translateY(24px)',zIndex:4}}>
                          <img src={require('../../images/cross2.png')}  style={{cursor:'pointer',width:'20px'}} onClick={()=>deleteNftBind(el.id,i)}></img>
                        </div>
                      {(el.type.includes("Видео")&& !el.link?.includes('gif'))
                      ? <ReactPlayer
                          onClick={()=>deleteNftBind(el.id,i)}
                          url={el?.link}
                          loop={true}
                          playing={true}
                          width='100%' 
                          height= '113px'
                          muted={true}
                          style={{zIndex:1}}
                          
                  />:
                      <img src={el.link}className='lk-rooms'/>
                      }
                      <p  className="lk-titles" style={{color: '#8A4ADA',maxWidth:'100%'}}>{el.name}</p>
                  </div>
              )
          })}
          </div>
          {nfts.length<4&&<ButtonLight onClick={()=>modalStore.showModal(ModalsEnum.MyNFTs)} style={{width:'150px'}}>{t('3D::addNft')}</ButtonLight>}<br/>
          <div style={{width:'100%', display:'flex', justifyContent:'flex-start'}}><ButtonLight style={{background: nfts.length!==4||roomType===''||title===''?'grey':'rgba(138, 74, 218, 1)', width:'150px',marginTop:'50px'}} disabled={nfts.length!==4||roomType===''||title===''} violet onClick={()=>submit()}>{t('3D::save')}</ButtonLight></div>
        </div>
     
      </SmoothAppearance>
    )
       
   })
   
   
   export default GalleryCreate