// import { faEye, faSearch } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import Web3 from "web3";

export const BG = styled.div`
  min-height: 100vh;
  background: url(${require("../../images/backgroundGradient.png")});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    /* justify-content: left; */
  }
`;

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 26px;
  padding: 64px 80px;
  position: relative;
  width: 598px;
  display: flex;
  flex-direction: column;

  & .modalCross {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 32px;
    right: 26px;
  }
  @media (max-width: 1000px) {
    /* width:100%; */
    margin: 0 25px;
    padding: 30px 15px;
  }
`;
export const NFTFormContainer = styled(Container)`
  width: 90vw;
  margin: 40px 0;
  background-color: white;
`;
export const Title = styled.div`
  font-family: Releway-bold;
  font-size: 32px;
  text-align: center;
  @media (max-width: 1000px) {
    font-size: 20px;
  }
`;

export const InputHolder = styled.div`
  position: relative;
  margin-top: 18px;

  & div {
    text-align: left;
    font-family: Releway-regular;
    font-size: 14px;
    margin-bottom: 10px;
  }
  & input {
    height: 44px;
    width: 100%;
    border: 0.5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 12px;
  }
  & textarea {
    height: 100px;
    width: 100%;
    border: 0.5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    resize: none;
  }
  & svg {
    position: absolute;
    right: 16px;
    bottom: 12px;
  }
`;
const Options = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Releway-regular;
  font-size: 12px;

  & .leftColumn {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  & .rightColumn {
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: right;
  }
  & .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 18px;
  }
  & .checkbox input {
    cursor: pointer;
    margin-right: 10px;
  }
  & .checkbox button {
    background: none;
    border: none;
    font-family: Releway-regular;
    font-size: 12px;
    color: #894ada;
  }
  & .checkbox a {
    margin: 0;
    margin-left: 3px;
  }
  & a {
    text-decoration: none;
    color: #894ada;
    margin-top: 18px;
  }
`;
export const SubmitButton = styled.button`
  height: 50px;
  background: ${(props) => (props.disabled ? "grey" : "#8A4ADA")};
  /* display:${(props) => (props.disabled ? "none" : "block")}; */
  border-radius: 8px;
  border: none;
  color: white;
  width: 100%;
  margin-top: 25px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  & img {
    height: 1.1rem;
    margin-right: 10px;
  }
  & svg {
    height: 1.1rem;
    margin-right: 10px;
  }
`;

const SearchInputStyles = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  & input {
    height: 100%;
    width: 100%;
    background: #ffffff;
    border: 0.5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 35px;
  }
  & svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    color: grey;
  }
  & input:focus {
    outline: none;
    /* border-width: 3.5px; */
  }
`;

interface ITextInput {
  title?: string;
  onChange: ChangeEventHandler;
  hidden?: boolean;
  name?: string;
  border?: boolean;
  defaultValue?: string;
  type?: string;
  onEnter?: any;
  currentBid?: number;
  required?: boolean;
  id?: string;
  inputRef?: MutableRefObject<HTMLInputElement>;
}
export const ModalInput = ({
  title,
  name,
  onChange,
  hidden,
  border,
  defaultValue,
  type,
  onEnter,
  currentBid,
  required,
}: ITextInput) => {
  let web3 = new Web3(process.env.RPC_URL_97);
  const [hide, setHide] = useState(false);
  const [typeI, setTypeI] = useState("text");
  useEffect(() => {
    if (hidden) {
      setTypeI("password");
    } else if (type && !hidden) {
      setTypeI(type);
    }
  }, []);

  return (
    <InputHolder>
      <div>{title}</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          defaultValue={
            currentBid
              ? web3.utils.fromWei(currentBid.toString(), "ether")
              : defaultValue
          }
          name={name}
          style={{ borderColor: border ? "#F2E9EA" : "white" }}
          type={typeI}
          step={type === "number" ? "any" : "false"}
          onChange={onChange}
          onKeyDown={(e) => (e.key === "Enter" && onEnter ? onEnter() : "")}
          min={
            currentBid ? web3.utils.fromWei(currentBid.toString(), "ether") : 0
          }
          required={required}
        />
        {
          hidden && (
            <img
              src={require("../../images/icons/eye.svg")}
              onClick={() => {
                setTypeI(!hide ? "text" : "password");
                setHide(!hide);
              }}
              style={{ width: "17px", height: "17px", marginLeft: "6px" }}
            />
          )

          // <FontAwesomeIcon icon={faEye} style={{color: hide? 'black': 'grey'}}
          //     onClick={()=>{
          //         setTypeI(!hide?'text':'password')
          //         setHide(!hide)
          //     }}

          //     />
        }
      </div>
    </InputHolder>
  );
};
export const TextInput = ({
  title,
  name,
  onChange,
  hidden,
  border,
  defaultValue,
  type,
  onEnter,
  currentBid,
  required,
}: ITextInput) => {
  let web3 = new Web3(process.env.RPC_URL_97);
  const [hide, setHide] = useState(false);
  const [typeI, setTypeI] = useState("text");
  useEffect(() => {
    if (hidden) {
      setTypeI("password");
    } else if (type && !hidden) {
      setTypeI(type);
    }
  }, []);

  return (
    <InputHolder>
      <div>{title}</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          defaultValue={
            currentBid
              ? web3.utils.fromWei(currentBid.toString(), "ether")
              : defaultValue
          }
          name={name}
          style={{ borderColor: border ? "#F2E9EA" : "white" }}
          type={typeI}
          step={type === "number" ? 0.0001 : "false"}
          onChange={onChange}
          onKeyDown={(e) => (e.key === "Enter" && onEnter ? onEnter() : "")}
          min={
            currentBid ? web3.utils.fromWei(currentBid.toString(), "ether") : 0
          }
          required={required}
        />
        {
          hidden && (
            <img
              src={require("../../images/icons/eye.svg")}
              onClick={() => {
                setTypeI(!hide ? "text" : "password");
                setHide(!hide);
              }}
              style={{ width: "17px", height: "17px", marginLeft: "6px" }}
            />
          )
          // <FontAwesomeIcon icon={faEye} style={{color: hide? 'black': 'grey'}}
          //     onClick={()=>{
          //         setTypeI(!hide?'text':'password')
          //         setHide(!hide)
          //     }}
          //     />
        }
      </div>
    </InputHolder>
  );
};
export const SearchInput = ({
  title,
  name,
  onChange,
  border,
  defaultValue,
  id,
  inputRef,
}: ITextInput) => {
  const { t } = useTranslation();
  return (
    <SearchInputStyles>
      <img
        src={require("../../images/icons/search.png")}
        style={{
          width: "17px",
          height: "17px",
          marginLeft: "9px",
          marginTop: "9px",
          position: "absolute",
          zIndex: 1000,
        }}
      />
      <input
        defaultValue={defaultValue}
        name={name}
        style={{ borderColor: border ? "#F2E9EA" : "white" }}
        type={"text"}
        placeholder={t("button::search")}
        onChange={onChange}
        id={id}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.code == "Enter" || e.code == "NumpadEnter") {
            onChange(e);
          }
        }}
      />
    </SearchInputStyles>
  );
};

export const TextAreaInput = ({
  title,
  name,
  onChange,
  border,
  defaultValue,
}: ITextInput) => {
  return (
    <InputHolder>
      <div>{title}</div>
      <textarea
        maxLength={999}
        defaultValue={defaultValue}
        name={name}
        style={{ borderColor: border ? "#F2E9EA" : "white" }}
        onChange={onChange}
      />
    </InputHolder>
  );
};

interface IForm {
  title: string;
  onSubmit: FormEventHandler;
  button: string;
  complite?: boolean;
}
type P = React.PropsWithChildren<IForm>;

function LinkTest(props: { to: string }) {
  return null;
}

export const ModalForm = ({
  title,
  onSubmit,
  button,
  complite,
  children,
}: P) => {
  return (
    <BG>
      <Container>
        <Link to={"/"}>
          <img
            className="modalCross"
            src={require("../../images/modal-cross.png")}
          />
        </Link>
        <Title>{title}</Title>
        <form onSubmit={onSubmit}>
          {children}
          <SubmitButton disabled={complite} type="submit">
            {button}
          </SubmitButton>
        </form>
      </Container>
    </BG>
  );
};

interface IFormOptions {
  rememberPassword?: boolean;
  forgotPassford?: boolean;
  linkWallet?: boolean;
  acceptTerms?: boolean;
  somthWrong?: boolean;
  reSend?: any;
  complite?: boolean;
}

export const FormOptions = ({
  reSend,
  rememberPassword,
  forgotPassford,
  linkWallet,
  acceptTerms,
  somthWrong,
  complite,
}: IFormOptions) => {
  const { t } = useTranslation();
  return (
    <Options>
      <div className="leftColumn">
        {/* {rememberPassword&&
                <div className='checkbox'>
                    <input type="checkbox" />
                    Запомнить пароль
                </div>
            } */}
        {acceptTerms && (
          <div className="checkbox">
            <input type="checkbox" required />
            {t("forms::agree")}
            <NavLink to="/p/privacy" target="_blank">
              {t("forms::conditions")}
            </NavLink>
          </div>
        )}
        {somthWrong && complite ? (
          <div className="checkbox">
            {t("forms::didntCome")}
            <button onClick={reSend}>{t("forms::sendOneMore")}</button>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="rightColumn">
        {forgotPassford && (
          <NavLink to="/p/auth/restorePassword">
            {t("forms::forgotPassword")}
          </NavLink>
        )}
        {/* {linkWallet&&
            <NavLink to='/auth/linkWallet'>Привязать кошелек</NavLink>
            } */}
      </div>
    </Options>
  );
};
