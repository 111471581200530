import { observer } from "mobx-react";
import { useState } from "react"
import {LoginData} from '../../../interfaces/auth'
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import { FormOptions, ModalForm, TextInput } from '../../../shared/forms/forms';
import PrivatePages from "../../../components/auth/auth";
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";



const NewPasswordForm = observer(() => {
    const [formData, setFormData] = useState<LoginData>({
        password: ''
    })

    const [password, setPassword] = useState();
    const store = useInjection(UserStore);
    const {t} = useTranslation()


    const secondPassword = (e) => {
        setPassword(e.target.value)
    }

    const onChange = (e: any) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }



    const onSubmit = (e: any) => {
        e.preventDefault();
        if(formData.password !== password) return toast.error(t('toasts::notMatch'))
        //server
        store.ChangePassword(formData);
        toast.success(t('toast::succsess'));
        setTimeout(() => history.back(), 200)


    }
   
    return(
        <PrivatePages>
            <ModalForm onSubmit={onSubmit} title={`${t('form::newPassword')}`} button={`${t('button::change')}`}>
                <TextInput title={`${t('form::newPassword')}`} name={'password'} onChange={onChange} hidden={true} />
                <TextInput title={`${t('form::repeatPassword')}`} onChange={secondPassword} hidden={true} />
                <FormOptions rememberPassword />
            </ModalForm>    
                    
        </PrivatePages>

    )
})


export default NewPasswordForm