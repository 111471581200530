import React from 'react';
import Modal from "../components/Modal";
import {ModalsEnum, ModalStore} from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { SubmitButton,TextInput  } from '../shared/forms/forms';
import { UserStore } from '../stores/user/UserStore';
import { toast } from 'react-toastify';
import { NFTstore } from "../stores/NFTstore.";
import { web3 } from '../constants';
import { WithTranslation, withTranslation } from 'react-i18next';
interface IConnectModalProps extends WithTranslation {
    data: { stop: boolean };
    idx: number;
}

interface IConnectModalState {
}

@observer
class RatingModal extends React.Component<IConnectModalProps, IConnectModalState> {
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(UserStore)
    declare protected readonly userStore: UserStore;
    @resolve( NFTstore)
    declare protected readonly nftStore:  NFTstore;
    state = { bet: '' }
    onChange = (e) =>{
        this.setState({bet:e.target.value})
    }
    onSubmit = () =>{
        localStorage.setItem('userAge', 'adult')
        this.modalStore.hideModal(this.props.idx)
        window.location.reload()
    }
    onDecline = () =>{
        this.modalStore.hideModal(this.props.idx)
        window.history.back()
    }
    
    render() {
        return (
            <Modal modalKey={ModalsEnum.Rating} idx={this.props.idx}>
                <h3>{this.props.t('modals::ageRating')}</h3>
                <SubmitButton  onClick={this.onSubmit}>{this.props.t('modals::yes')}</SubmitButton>
                <SubmitButton  onClick={this.onDecline}>{this.props.t('modals::no')}</SubmitButton>
            </Modal>
        )
    }
}
export default withTranslation()(RatingModal);

