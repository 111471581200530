import {observer} from "mobx-react";
import '../profile.sass'
import {useEffect, useState} from "react";
import { ButtonLight } from "../../../shared/buttons/buttons";
import { useInjection } from "inversify-react";
import styled from 'styled-components'
import { UsersStore } from "../../../stores/users/UsersStore";
import { useTranslation } from "react-i18next";
import { UserStore } from "../../../stores/user/UserStore";

export const Path = process.env.REACT_APP_IP;

const Container = styled.div`
margin: 15px 0 41px 0;
    & h1 {
        font-family: Releway-semiBold;
        font-size: 24px;
    }
    & p {
        font-family: Releway-regular;
        font-size: 16px;
    }
  



@media (max-width: 1000px) {
    flex-direction: column;
    & .desktop{
        display: none;
    }
    & .mobile{
        display: block;
    }
}
@media (min-width: 1001px) {
    & .desktop{
        display: block;
    }
    & .mobile{
        display: none;
    }
}
`
const Flex = styled.div`
display: flex;
    & div {
        height: 62px;
        width: 180px;
        background: #FBFBFB;
        border: 1px solid #F2E9EA;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        border-radius: 8px;
    }
    & div h3 {
        font-family: Releway-semiBold;
        font-size: 16px;
    }
    & div span {
        font-family: Releway-regular;
        font-size: 12px;
        color: #6D6D6D;
    }
`



const AboutArtist = observer(({user}) => {
    const store = useInjection(UsersStore)
    const userStore = useInjection(UserStore)
    const {t} = useTranslation()
    const [trading, setTrading] = useState(0)
    const [subButton, setSubButton] =  useState<boolean>(true)
    const [me, setMe] =  useState<boolean>(false)
    useEffect(()=>{
        const trd = user?.stats? (user.stats.bought+user.stats.sold ) : undefined
        trd && setTrading(trd)
        
    },[user])
    useEffect(()=>{
        if(userStore?.user?.subscriptions?.includes(user?._id)) {
            setSubButton(false)
        }
        if(userStore?.user?._id === user?._id&&user?._id) {
            setMe(true)
            console.log('%cabout.tsx ', 'color: #007acc;', 'da');
        } else {
            setMe(false)
        }
    },[userStore.user])
    const subscribe = () =>{
        userStore.subscribeToUser(user._id)
        setSubButton(!subButton)
    }
    return (
        <Container>
            <div style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
                <h1>{user?.fullname}</h1>
                <ButtonLight width='180px' style={{display:me?'none':'block'}} violet={subButton} onClick={()=>subscribe()}>{!subButton?'Вы подписаны':'Подписаться'}</ButtonLight>
            </div>
            <p>
                {user?.about}
            </p>
            <Flex>
                <div>
                    <h3>{user?.works?.length} NFT</h3>
                    <span>{t('profile::inGallery')}</span>
                </div>
                <div>
                    <h3>{trading} BNB</h3>
                    <span>{t('profile::tradingVolume')}</span>
                </div>
                <div>
                    <h3>{user?.minted_tokens?.length} NFT</h3>
                    <span>{t('profile::createdBy')}</span>
                </div>
                {/* <div>
                    <h3>{user?.views}</h3>
                    <span>{t('profile::views')}</span>
                </div> */}
            </Flex>
            
        </Container>
    )
})


export default  AboutArtist



