import PrivatePages from "../../../components/auth/auth";
import {observer} from "mobx-react";
import '../profile.sass'
import {useEffect, useState} from "react";
import { ButtonLight } from "../../../shared/buttons/buttons";
import { useInjection } from "inversify-react";
import { useLocation } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

// import { faBlog, faBoxes, faHammer, faTh } from '@fortawesome/free-solid-svg-icons'
import { MenuNav } from "../../../shared/navigation/navlink";
import { Divider, Menu, MobileProfile } from "./styled";
import { UsersStore } from "../../../stores/users/UsersStore";
import { DropdownItem, DropdownLink, DropdownMenu } from "../../../shared/buttons/dropdownMenu";
import { useTranslation } from "react-i18next";

export const Path = process.env.REACT_APP_IP;

interface stateType {
    user_id: string 
 }



const SidebarArtist = observer(({match}) => {
    const userStore = useInjection(UsersStore)
    const location = useLocation();
    const [openMobileMenu, setOpenMobileMenu] = useState(false)
    const {t} = useTranslation()
    const { state } = useLocation<stateType>();
    const userId = state?.user_id
     
    useEffect(() => {
        if(userId) {
            userStore.getOneUser(userId)
            console.log('zzzzzzzzzzzzz side artist')
        }
        

    }, [userId])
    const linkToClipboard =()=>{
        toast.success(t('toasts::copied'));
    }
    // console.log('1',location)
    // console.log('2',match)
    
    return (
        <>
                <div className='profileBlock'>
                    <img className='lk-logo' src={Path+'/'+userStore.user?.photo} />
                    <div className='lk-name'>{userStore.user?.fullname}</div>
                    <div className='lk-id'>{userStore.user?.user_id}</div>
   
                    <div className='lk-referal'>
                        {userStore.user?.public_key.slice(0,4)}...{userStore.user?.public_key.slice(userStore.user?.public_key.length-5,userStore.user?.public_key.length-1)}
                        <CopyToClipboard text={`${userStore.user?.public_key}`}>
                        <img style={{cursor:'pointer'}} src={require('../../../images/copy-icon.png')} onClick={linkToClipboard} />
                        </CopyToClipboard>
                    </div>
                    {/* <ButtonLight width='180px'>Подписаться</ButtonLight> */}
                    <Divider style={{margin:'18px 0'}} />
                    <Menu>
                        <h3>NFT</h3>
                        <MenuNav exact={true} 
                        to={{
                            pathname:`/p/profile/artist/${userStore.user?.user_id}/all`,
                            state:{user_id:userStore.user?.user_id}
                            }} 
                        text={`${t('sidebar::allNFT')}`}
                        // icon={faTh} 
                        icon={require(`../../../images/icons/table.svg`)}
                        />
                        <MenuNav exact={true} 
                        to={{
                            pathname:`/p/profile/artist/${userStore.user?.user_id}/created`,
                            state:{user_id:userStore.user?.user_id}
                            }} 
                        text={`${t('sidebar::created')}`} 
                        icon={require(`../../../images/icons/hammer.svg`)}
                        // icon={faHammer}
                         />
                         {/* <MenuNav exact={true} 
                        to={{
                            pathname:`/p/profile/artist/${userStore.user?.user_id}/created`,
                            state:{user_id:userStore.user?.user_id}
                            }} 
                        text={`${t('sidebar::sale')}`} 
                        icon={require(`../../../images/icons/hammer.svg`)}
                        // icon={faHammer}
                         /> */}
                        <MenuNav exact={true} 
                        to={{
                            pathname:`/p/profile/artist/${userStore.user?.user_id}/collections`,
                            state:{user_id:userStore.user?.user_id}
                            }} 
                            
                        text={`${t('sidebar::collections')}`} 
                        icon={require(`../../../images/icons/box-archive.svg`)}
                        // icon={faBoxes}
                         />
                        <h3>{t('sidebar::social')}</h3>
                        <MenuNav exact={true}  to={{
                            pathname:`/p/profile/artist/${userStore.user?.user_id}/blog`,
                            state:{user_id:userStore.user?.user_id}
                            }} 
                        text={`${t('sidebar::blog')}`} icon={require(`../../../images/icons/blog.svg`)} />
                        <MenuNav exact={true}  to={{
                            pathname:`/p/profile/artist/${userStore.user?.user_id}/3d`,
                            state:{user_id:userStore.user?.user_id}
                            }} 
                        text={`${t('sidebar::3d')}`} icon={require(`../../../images/icons/3d.png`)} />
                    </Menu>

         
                </div>
                 <MobileProfile  className='mobileProfile'>
                 <div style={{display:"flex"}}>
                     <img className='lk-logo' src={Path+'/'+userStore.user?.photo} />
                     <div style={{flexGrow:3,}}>
                         <div className='lk-name'>{userStore.user?.fullname}</div>
                         <div className='lk-id'>{userStore.user?.user_id}</div>
                         <div style={{position:'relative'}}>
                            <ButtonLight style={{height:'30px', width:'100px'}} onClick={()=>setOpenMobileMenu(!openMobileMenu)}>
                                {t('button::menu')}
                            </ButtonLight>
                            {openMobileMenu && 
                            <DropdownMenu style={{top:'50px', left:'-70px'}} onClick={()=>setOpenMobileMenu(!openMobileMenu)}>
                                
                                <DropdownItem 
                                
                                icon={require(`../../../images/icons/user.svg`)}
                                >
                                    <DropdownLink exact={true}  
                                    to={{
                                    pathname:`/p/profile/artist/${userStore.user?.user_id}`,
                                    state:{user_id:userStore.user?.user_id}
                                    }} >
                                        {t('sidebar::allNFT')}</DropdownLink>
                                </DropdownItem>
                                <DropdownItem 
                                    icon={require(`../../../images/icons/user.svg`)}
                                >
                                    <DropdownLink exact={true} 
                                    to={{
                                    pathname:`/p/profile/artist/${userStore.user?.user_id}/created`,
                                    state:{user_id:userStore.user?.user_id}
                                    }} >
                                        {t('sidebar::created')}</DropdownLink>
                                </DropdownItem>
                            </DropdownMenu>
                            }
                         </div>
                     </div>
                 </div>

             </MobileProfile>
             </>
    )
    
   
})


export default  SidebarArtist

    {/* {userStore.user?.user_type==='artist'&&
            <Be>
                Стать 
                <button onClick={()=>userStore.changeType('user')}>Пользователем</button>
            </Be>
            } */}              
            {/* {
                    activePage==='dashboard'?
                        <ButtonLight width='180px' style={{width:'max-content'}} onClick={() =>setActivePage('create')}>Создать NFT</ButtonLight>
                        :
                        <ButtonLight width='180px' style={{width:'max-content'}} onClick={() =>setActivePage('dashboard')}>Отмена</ButtonLight>
                    } */}