import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useInjection } from 'inversify-react';
import { UserStore } from '../../stores/user/UserStore';
import {UIStore} from "../../stores/uiStore/uiStore";
import Auth from "../../pages/auth";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";



export enum LoadingStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  FAIL = 'fail',
  NOTHING = 'nothing'
}



const PrivatePages = observer(({children}: any) => {

    const location = useLocation();
    const userStore = useInjection(UserStore);
    const uiStore = useInjection(UIStore);
    const [auth, setAuth] = useState<LoadingStatus>(userStore.isAuth
        ? LoadingStatus.SUCCESS 
        : LoadingStatus.LOADING
    ) 
    const token: string | null = useMemo(() => {
       return localStorage.getItem('token');
    }, [userStore.isAuth])

    useEffect(() => {
        if(token || userStore.isAuth){
            userStore.GetUser(token as string).then(res => {
            if(res) setAuth(LoadingStatus.SUCCESS)
            else  setAuth(LoadingStatus.LOADING)
         })
        } else setAuth(LoadingStatus.FAIL)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, location.pathname])

    // useEffect(() => {
    //     if (auth == LoadingStatus.FAIL) {
    //         uiStore.hideNavbar(false)
    //     } else uiStore.hideNavbar(true)

    //     return () => uiStore.hideNavbar(true)
    // })
    return children
        // if(auth === LoadingStatus.SUCCESS) return children
        // else return <Auth />

    
})

export default PrivatePages





