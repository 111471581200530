import {observer} from "mobx-react";
import './profileComponents.sass'
import { useState } from "react";
import { Table } from "../../shared/tables/tables";
import {useInjection} from "inversify-react";
import {UserStore} from "../../stores/user/UserStore";
import SearchBar from "./searchBar";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { UsersStore } from "../../stores/users/UsersStore";

const CopyString = styled.div`
display:flex;
align-items:center;
`

export const Path = process.env.REACT_APP_IP;



const Referals = observer(() => {
    const history = useHistory();
        const {t} = useTranslation()
    const userStore = useInjection(UserStore)
    const usersStore = useInjection(UsersStore)
    
    const userRedirect = (user_id) => {
    usersStore.getOneUser(user_id)
    history.push({
        pathname:'/p/profile/artist/'+user_id, 
        state:{user_id:user_id}
    })
}

const copyReferal = () => {
    toast.success(t("toasts::linkCopied"))
}
    return (
       <div className='lk-personalData'>
            <div className='lk-title2'>{t('pReferal::refLink')}</div>
            <CopyString>
            <p className='lk-wallet'>{window.location.origin}/?ref={userStore.user?.referal_code}</p>
            <CopyToClipboard text={`${window.location.origin}/?ref=${userStore.user?.referal_code}`}>
                <img style={{cursor:'pointer', marginLeft:'15px'}} src={require('../../images/copy-icon.png')} onClick={copyReferal} />
            </CopyToClipboard>
            </CopyString>
           <div className='lk-title3'  style={{marginTop:0}}>{t('pReferal::uourReferals')}</div>
          <Table className='lk-table'>
                <tbody>
                    {userStore.user?.referals.length>0 ? userStore.user?.referals.map((user,i)=>{
                        console.log(user)
                        return(
                            <tr key={i} className='lk-tr' style={{backgroundColor: i%2? 'white':''}}>
                                <td><img src={Path+'/'+user.photo} /></td>
                                <td style={{textAlign:"left"}}>{user.fullname}</td>
                                <td>{user.registration_date?.slice(0,10)}</td>
                                <td>{
                                user.user_type==='artist'?
                                <td className='lk-id' style={{marginBottom:'0px'}} onClick={()=>userRedirect(user.user_id)}>{user.user_id}
                                </td>:user.user_id}</td>
                            </tr>
                        )
                    }) : (
                        <tr>
                            <td style={{textAlign: 'center'}}>{t('pReferal::uourReferalsEmpty')}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
           <div className='lk-title3'>{t('pReferal::referalBuys')}</div>
            <Table className='lk-table'>
                <tbody>
                    <tr>
                        <td style={{textAlign: 'center'}}>{t('pReferal::referalBuysEmpty')}</td>
                    </tr>
                </tbody>
            </Table>
       </div>
    )
})


export default  Referals