import {observer} from "mobx-react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components'


const Privacy = styled.div`
    font-family: Releway-medium;

    & h1 {
        font-family: Releway-semiBold;
        margin-bottom: 40px;
    }
    & h2 {
        font-family: Releway-semiBold;
    }
`

const PPolicy = observer(({match}) => {
 const {t} = useTranslation()
 
    return (
        // <PrivatePages>
            <Privacy className='nft-container'>
                <h1>{t('policy::mainTitle')}</h1>
                <p>
                    <h2>{t('policy::title1')}</h2>
                    
                    <br />{t('policy::title1.0')}.
                    <br />{t('policy::title1.1')}
                    <br />{t('policy::title1.2')}
                </p>
                <p>
                    <h2>{t('policy::title2')}</h2>
                    <br />{t('policy::title2.1')}
                    <br />{t('policy::title2.2')}
                    <br />{t('policy::title2.3')}
                    <br />{t('policy::title2.4')}
                    <br />{t('policy::title2.5')}
                    <br />{t('policy::title2.6')}
                    <br />{t('policy::title2.7')}
                    <br />{t('policy::title2.8')}
                    <br />{t('policy::title2.9')}
                    <br />{t('policy::title2.10')}
                    <br />{t('policy::title2.11')}
                    <br />{t('policy::title2.12')}
                    <br />{t('policy::title2.13')}
                    <br />{t('policy::title2.14')}
                </p>
                <p>
                    <h2>{t('policy::title3')}</h2>
                
                    <br />{t('policy::title3.1')}
                    <br />{t('policy::title3.1a')}
                    <br />{t('policy::title3.1b')}
                    <br />{t('policy::title3.1c')}
                    <br />{t('policy::title3.2')}
                    <br />{t('policy::title3.2a')}
                    <br />{t('policy::title3.2b')}
                    <br />{t('policy::title3.2c')}
                    <br />{t('policy::title3.2d')}
                    <br />{t('policy::title3.2e')}
                    <br />{t('policy::title3.2f')}
                    <br />{t('policy::title3.2g')}
                    <br />{t('policy::title3.2h')}
                </p>
                <p>
                    <h2>{t('policy::title4')}</h2>
                    <br />{t('policy::title4.1')}
                    <br />{t('policy::title4.1a')}
                    <br />{t('policy::title4.1b')}
                    <br />{t('policy::title4.1c')}
                    <br />{t('policy::title4.1d')}
                    <br />{t('policy::title4.1e')}
                    <br />{t('policy::title4.1f')}
                    <br />{t('policy::title4.2')}
                    <br />{t('policy::title4.2a')}
                    <br />{t('policy::title4.2b')}
                    <br />{t('policy::title4.3')}
                </p>
                <p>
                    <h2>{t('policy::title5')}</h2>
                    <br />{t('policy::title5.1')}
                    <br />{t('policy::title5.2')}
<br />{t('policy::title5.3')}
<br />{t('policy::title5.4')}
<br />{t('policy::title5.5')}
<br />{t('policy::title5.6')}
<br />{t('policy::title5.7')}
<br />{t('policy::title5.8')}
<br />{t('policy::title5.9')}
<br />{t('policy::title5.10')}
<br />{t('policy::title5.10.1')}
<br />{t('policy::title5.10.2')}
<br />{t('policy::title5.10.3')}
<br />{t('policy::title5.10.4')}
                </p>
                <p>
                    <h2>{t('policy::title6')}</h2>
<br />{t('policy::title6.1')}
<br />{t('policy::title6.2')}
<br />{t('policy::title6.3')}
<br />{t('policy::title6.4')}
<br />{t('policy::title6.5')}
<br />{t('policy::title6.6')}
<br />{t('policy::title6.7')}
                </p>
                <p>
                    <h2>{t('policy::title7')}</h2>
<br />{t('policy::title7.1')}
<br />{t('policy::title7.1a')}
<br />{t('policy::title7.1b')}
<br />{t('policy::title7.1c')}
<br />{t('policy::title7.2')}
<br />{t('policy::title7.3')}
                </p>
                <p>
                    <h2>{t('policy::title8')}</h2>
<br />{t('policy::title8.1')}
<br />{t('policy::title8.1a')}
<br />{t('policy::title8.1b')}
<br />{t('policy::title8.1c')}
<br />{t('policy::title8.2')}
<br />{t('policy::title8.3')}
<br />{t('policy::title8.4')}
                </p>
                <p>
                    <h2>{t('policy::title9')}</h2>
<br />{t('policy::title9.1')}
<br />{t('policy::title9.2')}
<br />{t('policy::title9.3')}
<br />{t('policy::title9.4')}
<br />{t('policy::title9.5')}
<br />{t('policy::title9.6')}
<br />{t('policy::title9.7')}
                </p>
                <p>
                    <h2>{t('policy::title10')}</h2>
<br />{t('policy::title10.0')}
<br />{t('policy::title10.1')}
<br />{t('policy::title10.2')}
<br />{t('policy::title10.3')}
<br />{t('policy::title10.4')}
<br />{t('policy::title10.00')}
<br />{t('policy::title10.5')}
<br />{t('policy::title10.6')}
<br />{t('policy::title10.7')}
<br />{t('policy::title10.8')}
<br />{t('policy::title10.9')}
                </p>
                <p>
                    <h2>{t('policy::title11')}</h2>
<br />{t('policy::title11.1')}
<br />{t('policy::title11.2')}
                </p>
                <p>
                    <h2>{t('policy::title12')}</h2>
<br />{t('policy::title12.1')}
<br />{t('policy::title12.2')}
                </p>
                <p>
                    <h2>{t('policy::title13')}</h2>
                    <br />{t('policy::title13.0')}
                </p>
                <p>
                    <h2>{t('policy::title14')}</h2>
<br />{t('policy::title14.1')}
<br />{t('policy::title14.2')}
<br />{t('policy::title14.3')}
                </p>
            </Privacy>

        // </PrivatePages>
    )
})


export default  PPolicy