// import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "../../../shared/forms/forms";
import { UserStore } from "../../../stores/user/UserStore";
import { Buttons, Price } from "../index";

export const SimpleBuy = observer(
  ({
    oneNFT,
    origCopies,
    authorOffer,
    web3,
    BuyNFT,
    stop,
    isCopied,
    copiesLeft,
    allCopies,
  }) => {
    const { t, i18n } = useTranslation();
    const userStore = useInjection(UserStore);
    const [price, setPrice] = useState<number>(0);
    const [border, setBorder] = useState<number>(0);
    const [currentBuyId, setCurrentBuyId] = useState<string>("");
    const [copyOwner, setCopyOwner] = useState<string>("");
    useEffect(() => {
      if (isCopied) {
        setPrice(oneNFT?.price);
      }
      setCurrentBuyId(oneNFT?._id);
    }, [isCopied]);
    useEffect(() => {
      setCurrentBuyId(oneNFT?._id);
      setPrice(oneNFT?.price);
    }, [oneNFT]);
    const newPrice = (el, i) => {
      setPrice(el.price);
      // console.log(el, oneNFT.instances.find(ins=>ins?.owner?.fullname==el?.owner?.fullname&&ins?.price===el?.price))
      setCurrentBuyId(
        oneNFT.instances.find(
          (ins) =>
            ins?.owner?.fullname == el?.owner?.fullname &&
            ins?.price === el?.price
        )?._id
      );
      setBorder(i);
      setCopyOwner(el.owner?.user_id);
    };

    return (
      <>
        <Price>
          <div>
            {" "}
            {t("nft::price")} :
            <br />
            <b>
              {price &&
                Number(
                  web3?.utils?.fromWei(price?.toString(), "ether")
                ).toFixed(3)}
              &thinsp; BNB
            </b>
            <span>
              ≈ {i18n.language == "ru" ? "₽" : "$"}{" "}
              {price &&
                userStore.bnb &&
                i18n.language == "ru" &&
                (
                  userStore.bnb *
                  parseFloat(web3.utils.fromWei(price?.toString(), "ether"))
                ).toFixed(2)}
              {price &&
                userStore.bnbUsd &&
                i18n.language != "ru" &&
                (
                  userStore.bnbUsd *
                  parseFloat(web3.utils.fromWei(price?.toString(), "ether"))
                ).toFixed(2)}
            </span>
          </div>
        </Price>
        {!isCopied && (
          <Price style={{ marginBottom: "30px" }}>{t("nft::singleCopy")}</Price>
        )}
        {isCopied && (
          <Price
            style={{
              marginBottom: "30px",
              display: "flex",
              maxWidth: "469px",
              justifyContent: "space-between",
            }}
          >
            <div>
              {" "}
              {t("nft::copy")}
              :
              <br />
              <b>{copiesLeft + "/" + allCopies}</b>
            </div>

            {origCopies.filter((copy) => copy?.isSelling).length > 1 && (
              <div>
                {" "}
                Все предложения :
                <br />
                {authorOffer?.map((el, i) => {
                  // console.log('hiiii',el)
                  if (el?.isSelling) {
                    console.log("hiiii", el);
                    return (
                      <div
                        key={i}
                        onClick={() => newPrice(el, i)}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          borderTop:
                            i === border
                              ? "1px solid #959EA7"
                              : "1px solid transparent",
                          borderBottom:
                            i === border
                              ? "1px solid #959EA7"
                              : "1px solid transparent",
                          borderLeft: "0px",
                          borderRight: "0px",
                        }}
                      >
                        {el.owner.fullname}:
                        <b key={i}>
                          &thinsp;
                          {el.price &&
                            parseFloat(
                              web3.utils.fromWei(el?.price?.toString(), "ether")
                            )}
                          &thinsp;BNB
                        </b>
                      </div>
                    );
                  }
                })}
                {origCopies?.map((el, i) => {
                  if (
                    el?.isSelling &&
                    el.owner.user_id !== oneNFT.author.user_id
                  ) {
                    return (
                      <div
                        key={i}
                        onClick={() => newPrice(el, i + 1)}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          borderTop:
                            i + 1 === border
                              ? "1px solid #959EA7"
                              : "1px solid transparent",
                          borderBottom:
                            i + 1 === border
                              ? "1px solid #959EA7"
                              : "1px solid transparent",
                          borderLeft: "0px",
                          borderRight: "0px",
                        }}
                      >
                        {el.owner.fullname}:
                        <b key={i}>
                          &thinsp;
                          {el.price &&
                            parseFloat(
                              web3.utils.fromWei(el?.price?.toString(), "ether")
                            )}
                          &thinsp;BNB
                        </b>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </Price>
        )}
        {oneNFT?.isSelling && (
          <Buttons>
            <SubmitButton
              disabled={
                oneNFT?.owner.user_id === userStore.user?.user_id ||
                stop ||
                copyOwner === userStore.user?.user_id
              }
              onClick={() => BuyNFT(currentBuyId)}
              style={{ marginTop: "4px" }}
            >
              <img
                src={require("../../../images/icons/cart-shopping.svg")}
                style={{
                  width: "17px",
                  height: "17px",
                  marginRight: "6px",
                  filter: "invert(1)",
                }}
              />
              {/* <FontAwesomeIcon icon={faShoppingCart} /> */}
              {t("nft::buy")}
            </SubmitButton>
          </Buttons>
        )}
      </>
    );
  }
);
