
import { ButtonLight} from '../../../shared/buttons/buttons';
import './news.sass'
import { observer } from 'mobx-react';
import {  useEffect, useRef } from 'react';
import { useInjection } from 'inversify-react';
import CatalogItem from '../../../pages/Catalog/catalogItem';
import { UserStore } from '../../../stores/user/UserStore';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NFTstore } from '../../../stores/NFTstore.';
import ReactPlayer from 'react-player';
import { SmoothAppearance } from '../../../shared/layout/smoothAppearance';


const LastNft = styled.div`

padding: 60px 60px;
& h1 {
    text-align: center;
    margin-bottom: 80px;
}
& .nft-grid {
        width: 100%;
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 20px;
}
@media  ( max-width: 1000px ){
    & .nft-grid {
        grid-template-columns:  1fr;
        row-gap: 20px;
}  
}
            
`
const VideoBanner = styled.div`

width: 100%;
`
const About = styled.div`
margin-bottom: 100px;
& .bg {
 background-size: cover;
 padding: 1px 0 100px 0;
 margin-top:-10px;
}

& h1 {
    text-align: center;
    margin: 80px 0px;
    
}
& p {
    margin: 0px 100px;
    text-align: center;
}
& .principles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1fr;
    row-gap: 60px;
    & h3 {
        text-align: center;

    }
    & p {
        text-align: center;
color: #7C848B;

    }

}
@media  ( max-width: 1300px ){
    & .principles {
        grid-template-columns: 1fr 1fr;
        
        & .plug {
            display: none;
        }
    }
}
@media  ( max-width: 1000px ){
    & .principles {
        grid-template-columns: 1fr ;
        
        & h3 {
        text-align: left;
        margin: 0 20px 10px;
        }
        & p {
            text-align: left;
            margin-left: 47px;
        }
    }
    & h1 {
    text-align: center;
    margin: 50px 0px;
    }
    & p {
    margin: 0px 20px;
    text-align: center;
    }

}
`

const Banner = styled.div`
position: relative;
& img {
    width: 100%;
}
`

const News = observer(() => {
    const nftStore = useInjection(NFTstore)
    const userStore = useInjection(UserStore)
    
    const {t} = useTranslation()


const responseInstagram = (response) => {
  console.log(response);
};
    useEffect(()=>{
        nftStore.getMostExpensiveNFT ()
        nftStore.getNFTcatalog().then(res => console.log(res, 'res in comp'))
        
            // ReactGA.pageview(window.location.pathname + window.location.search);
            // userStore.instagramTest()
    },[])
    // useEffect(()=>{
    //     console.log(ref.current)
    // })

    return (
        <SmoothAppearance trigger >
            
            <div className='news-container' id='mainc' style={{backgroundImage:`url(${require('../../../images/bgNews.png')})`, backgroundSize:'cover'}}>
                <div className='news-banner'>
                    <div>
                        <div className='news-text'>{t('news::header')}</div>
                        <div className='news-subtext'>{t('news::subHeader')}</div>
                        <div className='news-buttons'>
                            {!userStore.isAuth&&<Link to='/p/auth'><ButtonLight violet width='174px' style={{marginRight:'12px'}} >{t('button::authorization')}</ButtonLight></Link>}
                            <Link to='/p/catalog'><ButtonLight width='174px' violet={userStore.isAuth}>{t('button::catalog')}</ButtonLight></Link>
                           
                        </div>
                    </div>
                        <div className='news-banner-nft'>
                            {/* {nftStore?.mainNFT && <CatalogItem el={nftStore?.mainNFT} mostExpensive={true}></CatalogItem>} */}
                            {nftStore?.NFT && 
                            <CatalogItem el={nftStore?.NFT[0]} mostExpensive={true}></CatalogItem>
                            }
                        </div>
                </div>
                

            </div>
            <LastNft>
                    <h1>{t('news::newNFT')}</h1>
                    <div className='nft-grid'>
                        {nftStore.NFT?.map((el,i)=>{
                            if(i<5){
                                return(
                                    <CatalogItem key={'same'+i} el={el} />
                                )
                            }
                        })}
                        </div>
                </LastNft>
                <VideoBanner>
                        <ReactPlayer
                            url={require('../../../images/final_eng.mp4')}
                            loop={true}
                            // style={{cursor:'pointer', width:'100%'}}
                            playing={true}
                            // forceVideo={true}
                            width='100%' 
                            volume={0}
                            height='auto'
                            muted={true}
                        />
                </VideoBanner>
                <About>
                    <div className='bg' style={{backgroundImage:`url(${require('../../../images/bgNews.png')})`}}>
                        <h1>{t('news::aboutProjectTitle')}</h1>
                        <p>{t('news::aboutProject')}</p>
                    </div>
                    
                    <h1>{t('news::principlesTitle')}</h1>
                    <div className='principles'>
                        <div>
                            <h3>{t('news::princip1title')}</h3>
                            <p>{t('news::princip1text')}</p>
                        </div>
                        <div>
                            <h3>{t('news::princip2title')}</h3>
                            <p>{t('news::princip2text')}</p>
                        </div>
                        <div>
                            <h3>{t('news::princip3title')}</h3>
                            <p>{t('news::princip3text')}</p>
                        </div>
                        <div>
                            <h3>{t('news::princip4title')}</h3>
                            <p>{t('news::princip4text')}</p>
                        </div>
                        <div>
                            <h3>{t('news::princip5title')}</h3>
                            <p>{t('news::princip5text')}</p>
                        </div>
                        <div>
                            <h3>{t('news::princip6title')}</h3>
                            <p>{t('news::princip6text')}</p>
                        </div>
                        <div>
                            <h3>{t('news::princip7title')}</h3>
                            <p>{t('news::princip7text')}</p>
                        </div>
                        <div>
                            <h3>{t('news::princip8title')}</h3>
                            <p>{t('news::princip8text')}</p>
                        </div>
                        <div>
                            <h3>{t('news::princip9title')}</h3>
                            <p>{t('news::princip9text')}</p>
                        </div>
                        <div className='plug'></div>
                        <div>
                            <h3>{t('news::princip10title')}</h3>
                            <p>{t('news::princip10text')}</p>
                        </div>
                    </div>
                
                </About>
                <Banner>
                    <img src={require('../../../images/banner.jpg')} />
                </Banner>
                </SmoothAppearance>
    )
}
)

export default News



const filters= [
    "Картинки","GIF","Видео","Другое","Искусство","Фотография","Игры","Музыка","Спорт"
]
const items = [
    {
        text:'Специальное продложение',
        img:'../../../images/backgroundGradient.png'
    },
    {
        text:'Специальное продложение',
        img:'../../../images/backgroundGradient.png'
    },
    {
        text:'Специальное продложение',
        img:'../../../images/backgroundGradient.png'
    },
    {
        text:'Специальное продложение',
        img:'../../../images/backgroundGradient.png'
    },
    {
        text:'Специальное продложение',
        img:'../../../images/backgroundGradient.png'
    },
    {
        text:'Специальное продложение',
        img:'../../../images/backgroundGradient.png'
    },
    {
        text:'Специальное продложение',
        img:'../../../images/backgroundGradient.png'
    },
    {
        text:'Специальное продложение',
        img:'../../../images/backgroundGradient.png'
    },
]