// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Item = styled.div`

margin: 0 0 15px 0;

   
    & a {
        text-decoration: none;
        color: black;
        background: none;
        border: none;
        font-size: 18px;
        padding: 0 0 5px 0;
        display: flex;
        
    }
    & a div{
        width: 25px;
        }
    & a p {
        margin: 0px 0 0 10px;
        border-bottom: 2px solid transparent;
        white-space: nowrap;
    }
`


export const MenuNav = ({exact, to, text, 
    icon
}) => {
    const [active, setActive] = useState(false)
   
    
    return(
        <Item>
            {/* {console.log(to)}  */}
        <NavLink exact={exact}
          to={to}
           
          isActive={(match) => {
                // console.log(match)
            if (!match) {
            
              return false;
            }
            if (match) {

              return true;
            }
          }}
          style={{ fontFamily:'Montserrat-regular'}}
          activeStyle={{fontFamily:'Montserrat-semiBold'}}
          >
            <div ><img src={icon} style={{width:'17px', height:'17px'}}/>
                {/* <FontAwesomeIcon style={{color: active? 'black':'#778592',}} icon={icon} /> */}
            </div>
            <p >
                {text}
            </p>
        </NavLink>
        </Item>
    )
}