import styled from "styled-components"

export const Menu = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
    & h3 {
        font-family: Montserrat-semiBold;
        font-size: 14px;
        margin: 0 0 15px 0;
        color: #717171;
    }
`

export const MobileProfile = styled.div`

    & img {
        width: 100px;
        height: 100px;
        margin-right: 30px;
    }
`


export const Divider = styled.div`
height: 2px;
width: 100%;
background: #F2E9EA;
`