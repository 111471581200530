import {observer} from "mobx-react";
// import './profileComponents.sass'

import styled from "styled-components";

import { useEffect } from "react";
import { useInjection } from "inversify-react";
import { NFTstore } from "../../stores/NFTstore.";
import { url } from "../auth/helper";
import { useTranslation } from "react-i18next";

const Container = styled.div
`    width:100%;
margin-top:0px;
padding-top:15px;
margin-left:6px;
border-top: 1px solid #CBCBCB;
    h3 {
        margin: 0;
        color: black;
        font-family: Releway-semiBold;
        font-size: 22px;
        
        
        text-align: left; 
    }

`
const InWork = observer(({}) => {
    const {t} = useTranslation()
    
    return (
        <Container>
            <p>{t("nft::inwork")}</p>
        </Container>
       
    )
})


export default  InWork

export const Ipfs = observer(({ipfs}) => {
    
    
    return (
        <Container>
            <a target="_blank" href={ipfs} style={{fontSize:'14px',color:'#894ADA', cursor:'pointer'}}>{ipfs}</a>
        </Container>
       
    )
})
export const Hidden = observer(({isFirst,id}) => {
    const nftStore = useInjection(NFTstore)
    useEffect(()=>{
        if(isFirst) {
            nftStore.getHiddenContent(id)
        }
        return () =>{
            nftStore.removeHidden()
        }
        
    },[])
    
    return (
        <Container>
            {
                nftStore.hidden&&nftStore.hidden!=="false"
                ?
                <a href={nftStore.hidden&&'https://ipfs.digarty.com/ipfs/'+nftStore.hidden}target="_blank" style={{fontSize:'14px',color:'#894ADA', cursor:'pointer',marginTop:'10px'}}>{'https://ipfs.digarty.com/ipfs/'+nftStore.hidden}</a>
                :
                <p>Скрытый контент недоступен</p>
            }
            
        </Container>
       
    )
})