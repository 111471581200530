import React from 'react';
import Modal from "../components/Modal";
import {ModalsEnum, ModalStore} from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { InputHolder, SubmitButton,TextInput, Title  } from '../shared/forms/forms';
import { UserStore } from '../stores/user/UserStore';
import { toast } from 'react-toastify';
import { NFTstore } from "../stores/NFTstore.";
import Web3 from 'web3';
import { WithTranslation, withTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import styled from 'styled-components';
import { url } from '../components/auth/helper';


interface IConnectModalProps extends WithTranslation {
    data: { type?: string };
    idx: number;
}

interface IConnectModalState {
}

@observer
class MyNFTsModal extends React.Component<IConnectModalProps, IConnectModalState> {
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve( UserStore)
    declare protected readonly userStore:  UserStore;
    _addToPost (id,title, link, type) {
        if(this.props.data?.type==='blog') {
            console.log('hi')
            this.userStore.setCurrentPostNft (id, title)
        }
        else {
            this.userStore.setCurrentPostNft (id, title, link, type)
        }
        this.modalStore.hideAllModals()
    }
    
    render() {console.log(this.props.data?.type)
        return (
            <Modal modalKey={ModalsEnum.MyNFTs} idx={this.props.idx} nopadding>
                <div style={{maxHeight:'320px', overflow:'scroll'}}>
                 {this.userStore?.ownedNFT?.map((el)=>{
                    return (
                        <div key={el._id} className='lk-blog-preview-modal' onClick={()=>this._addToPost(el._id,el.title,el.preview, el.category)}>
                            <p className='lk-blog-preview-modal-text' >{el.title}</p>
                        </div> 
                    )
                   
                })}   
                </div>
                
            </Modal>
        )
    }
}
export default withTranslation()(MyNFTsModal);

const DP = styled.div`

    input {

        border-color:#F2E9EA;
     
    }
`

