import { useEffect, useState } from "react";

export const SmoothAppearance = (props =>{
    const [opacity, setOpacity] = useState(true);
    useEffect(()=>{
        // console.log('hi baby')
            setOpacity(true)
        
    },[])
    useEffect(()=>{
        if(!props.trigger){
            setOpacity(true)
        }
        else {
            setOpacity(false)
            // console.log('hii')
        }
    },[props.trigger])

    return (
    <div 
    style={{opacity:opacity?0:1 ,transition:'ease 700ms all'}}>
        {props.children}
    </div>)
        
})
