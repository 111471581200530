

export interface ContentCutter {
    file: any[],
    preview: any[],
    fileSize: number,
    previewSize: number,
}
export enum Extensions {
    JPEG = 'image/jpeg',
    GIF = 'image/gif',
    TIFF = 'image/tiff',
    PNG = 'image/png',
    WEBM = 'video/webm',
    MP4 = 'video/mp4',
    AVI = 'video/x-msvideo',
    MOV = 'video/quicktime',
    MKV = 'video/x-matroska',
    WMV = 'video/x-ms-wmv',
    SVG = 'image/svg+xml',
    AI = 'application/postscript'
}

export enum Extensions3D {
    STL = 'model/stl',
    OBJ = 'application/object',
    FBX = 'application/octet-stream',
    DS = 'application/x-3ds',
    IGES = 'model/iges',
    STEP = 'application/octet-stream',
    VRML = 'model/vrml',
    COLLADA = 'model/vnd.collada+xml'
}

export enum ExtensionsArchives {
    ZIP = 'application/x-zip',
    ZIP2 = 'application/x-zip-compressed',
    RAR = 'application/x-rar-compressed',
    RAR2 = 'application/vnd.rar',
    RAR3 = 'application/x-rar',
    APK = 'application/vnd.android.package',
    DMG = 'application/octet-stream',
    ISO = 'application/octet-stream',
    SZ = 'application/x-7z-compressed'
}

export const ExtensionsModels = {
    file: Extensions,
    preview: Extensions,
    hideContent: Extensions
}

export const ExtensionsImages = {
    file: [Extensions.JPEG, Extensions.PNG, Extensions.TIFF, ExtensionsArchives.ISO, ExtensionsArchives.RAR, ExtensionsArchives.ZIP, ExtensionsArchives.SZ, ExtensionsArchives.ZIP2, ],
    preview: [Extensions.JPEG, Extensions.PNG, Extensions.TIFF],
    fileSize: 4194304,
    previewSize: 4194304,
}

export const ExtensionsGif = {
    file: [Extensions.GIF, ExtensionsArchives.ISO, ExtensionsArchives.RAR, ExtensionsArchives.ZIP, ExtensionsArchives.ZIP2, ],
    preview: [Extensions.GIF],
    fileSize: 4194304,
    previewSize: 4194304,
}

export const ExtensionsPanoImg = {
    file: [Extensions.JPEG, Extensions.PNG, ExtensionsArchives.ISO, ExtensionsArchives.RAR, ExtensionsArchives.ZIP, ExtensionsArchives.SZ, ExtensionsArchives.ZIP2, ],
    preview: [Extensions.GIF, Extensions.JPEG, Extensions.MP4],
    fileSize: 4194304,
    previewSize: 4194304,
}
export const ExtensionsVector = {
    file: [Extensions.AI, Extensions.SVG, ExtensionsArchives.ISO, ExtensionsArchives.RAR, ExtensionsArchives.ZIP, ExtensionsArchives.SZ, ExtensionsArchives.ZIP2, ],
    preview: [Extensions.JPEG, Extensions.PNG],
    fileSize: 4194304,
    previewSize: 4194304,
}
export const ExtensionsVideo = {
    file: [Extensions.MP4, Extensions.WEBM, Extensions.MKV, Extensions.MOV, Extensions.WMV, Extensions.AVI, ExtensionsArchives.ISO, ExtensionsArchives.RAR, ExtensionsArchives.ZIP, ExtensionsArchives.SZ, ExtensionsArchives.ZIP2, ],
    preview: [Extensions.MP4, Extensions.GIF],
    fileSize: 4194304,
    previewSize: 4194304,
}
export const ExtensionsPanoVid = {
    file: [Extensions.MP4, Extensions.WEBM, Extensions.MKV, Extensions.MOV, Extensions.WMV, Extensions.AVI, ExtensionsArchives.ISO, ExtensionsArchives.RAR, ExtensionsArchives.ZIP, ExtensionsArchives.SZ, ExtensionsArchives.ZIP2, ],
    preview: [Extensions.JPEG, Extensions.MP4, Extensions.GIF],
    fileSize: 4194304,
    previewSize: 4194304,
}
export const Extensions3DVid = {
    file: [Extensions.MP4, Extensions.WEBM, Extensions.MKV, Extensions.MOV, Extensions.WMV, Extensions.AVI, ExtensionsArchives.ISO, ExtensionsArchives.RAR, ExtensionsArchives.ZIP, ExtensionsArchives.SZ, ExtensionsArchives.ZIP2, ],
    preview: [ Extensions.MP4, Extensions.GIF],
    fileSize: 4194304,
    previewSize: 4194304,
}
export const Extensions3DModel = {
    file: [Extensions3D.COLLADA, Extensions3D.DS, Extensions3D.FBX, Extensions3D.IGES, Extensions3D.OBJ, Extensions3D.STEP, Extensions3D.STL, Extensions3D.VRML, ExtensionsArchives.ISO, ExtensionsArchives.RAR, ExtensionsArchives.ZIP, ExtensionsArchives.SZ, ExtensionsArchives.ZIP2,  ],
    preview: [Extensions.JPEG, Extensions.MP4, Extensions.GIF, Extensions.PNG],
    fileSize: 4194304,
    previewSize: 4194304,
}
export const ExtensionsINTERACTIVE = {
    file: [ ExtensionsArchives.APK, ExtensionsArchives.DMG, ExtensionsArchives.ISO, ExtensionsArchives.RAR, ExtensionsArchives.ZIP,  ExtensionsArchives.RAR2, ExtensionsArchives.RAR3, ExtensionsArchives.SZ, ExtensionsArchives.ZIP2,],
    preview: [Extensions.JPEG, Extensions.MP4, Extensions.GIF, Extensions.MP4],
    fileSize: 4194304,
    previewSize: 4194304,
}
// export enum ContentTypes {
//     IMAGE = ExtensionsImages,
//     PANOIMG = ExtensionsGif,
//     VECTOR = 'image/tiff',
//     VIDEO = 'image/png',
//     PANOVID = 'video/webm',
//     DDDVID = 'video/mp4',
//     DDDMODEL = 'video/mp4',
//     INTERACTIVE = 'video/mp4',
// }