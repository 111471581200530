import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserStore } from "../../../stores/user/UserStore";
import styled from "styled-components";
import { Buttons, getDateAuc } from "..";
import { ModalsEnum, ModalStore } from "../../../stores/ModalStore";
import { Price } from "../index";
// import { faHourglassStart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SubmitButton } from "../../../shared/forms/forms";
import { toast } from "react-toastify";
const AucTime = styled.div`
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 7px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 13px;
  margin-top: 16px;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  margin-bottom: -16px;
`;

export const Auction = observer(
  ({ oneNFT, auc, history, web3, stop,isCopy }) => {
    const [timer, setTimer] = useState("");
    const [timerToStart, setTimerToStart] = useState("");
    const [delay, setDelay] = useState(auc?.delay);
    const [day, setDay] = useState("");
    const [dayStart, setDayStart] = useState("");
    const { t,i18n } = useTranslation();
    const userStore = useInjection(UserStore);
    const modalStore = useInjection(ModalStore);

    
    useEffect(()=>{
      let dateNow = Date.now();
      let dateStart = Date.parse(oneNFT?.auction?.startDate);
      let dateEnd = Date.parse(oneNFT?.auction?.dateEnd);
      if(auc){
        if(auc?.delay && dateStart > dateNow){
          setDelay(true)
        } else if (auc?.delay && dateStart < dateNow){
          setDelay(false)
        } 
      }
    },[auc])
    useEffect(() => {

      let int = setInterval(() => {
        setTimer(aucDateEnd());
        setTimerToStart(aucDateStart());
      }, 1000);
      if (auc?.open) {
        int;
      }
      return () => {
        clearInterval(int);
      };
    }, [auc]);

    useEffect(() => {
      let dateNow = Date.now();
      let dateEnd = Date.parse(oneNFT?.auction?.dateEnd);
      let timeLeft = dateEnd - dateNow;
      let days = Math.trunc(timeLeft / (24 * 1000 * 60 * 60));
      setDay(days < 5 && days > 0 ? "дня" : days >= 5 ? "дней" : "");
    }, [timer]);
    useEffect(() => {
      let dateNow = Date.now();
      let dateEnd = Date.parse(oneNFT?.auction?.startDate);
      let timeLeft = dateEnd - dateNow;
      let days = Math.trunc(timeLeft / (24 * 1000 * 60 * 60));
      setDayStart(days < 5 && days > 0 ? "дня" : days >= 5 ? "дней" : "");
    }, [timerToStart]);
    const showModal =() => {
        const metamask = userStore.user?.metamask

      if(!metamask){
        toast.error(t('toasts::confirmWallet'))
        return
      }

        modalStore.showModal (ModalsEnum.Test, stop)
    }
    // const downloadFile = (link, filename) =>{
    //   fetch(link, {
    //     method: "GET",
    //     headers: {}
    //   })
    //     .then(response => {
    //       response.arrayBuffer().then(function(buffer) {
    //         const url = window.URL.createObjectURL(new Blob([buffer]));
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download", filename); //or any other extension
    //         document.body.appendChild(link);
    //         link.click();
    //       });
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // };
    
    const aucDateEnd = () => {
      let dateNow = Date.now();
      let dateEnd = Date.parse(oneNFT?.auction?.dateEnd);
      let timeLeft = dateEnd - dateNow;
      let days = Math.trunc(timeLeft / (24 * 1000 * 60 * 60) + 1);
      let hours = Math.trunc(timeLeft / (1000 * 60 * 60));
      let minutes = Math.trunc((timeLeft - hours * 3600000) / (1000 * 60));
      let seconds = Math.trunc(
        (timeLeft - hours * 3600000 - minutes * 60000) / 1000
      );
      let prhours = hours >= 10 ? hours : "0" + hours;
      let prminutes = minutes >= 10 ? minutes : "0" + minutes;
      let prseconds = seconds >= 10 ? seconds : "0" + seconds;
      
      
      if (timeLeft > 86400000) {
        return days.toString();
      }
      if (timeLeft < 86400000 && timeLeft > 0) {
        return prhours + " : " + prminutes + " : " + prseconds;
      }
    };
    const aucDateStart = () => {
      let dateNow = Date.now();
      let dateEnd = Date.parse(oneNFT?.auction?.startDate);
      let timeLeft = dateEnd - dateNow;
      let days = Math.trunc(timeLeft / (24 * 1000 * 60 * 60) + 1);
      let hours = Math.trunc(timeLeft / (1000 * 60 * 60));
      let minutes = Math.trunc((timeLeft - hours * 3600000) / (1000 * 60));
      let seconds = Math.trunc(
        (timeLeft - hours * 3600000 - minutes * 60000) / 1000
      );
      let prhours = hours >= 10 ? hours : "0" + hours;
      let prminutes = minutes >= 10 ? minutes : "0" + minutes;
      let prseconds = seconds >= 10 ? seconds : "0" + seconds;
      
      if (timeLeft <= 0){
        setDelay(false)
        return '0'
      }
      if (timeLeft > 86400000) {
        return days.toString();
      }
      if (timeLeft < 86400000 && timeLeft > 0) {
        return prhours + " : " + prminutes + " : " + prseconds;
      }
    };
    return (
      <div>
        <AucTime>
          {console.log(oneNFT?.instances?.length)}
          {delay ? (
            <Wrapper> 
              {/* delay wrapper */}
                <div style={{display:'flex'}}>
                  <img src={require('../../../images/icons/hourglass-start.svg')} style={{width:'17px', height:'17px',marginRight:'3px'}}/>
                  {/* <FontAwesomeIcon icon={faHourglassStart} style={{ marginRight: "5px", marginTop:'3px' }} /> */}
                  <p>{t("nft::aucStartsAt")} <b>{timerToStart}&ensp;{dayStart}</b></p>
                </div>
                <p style={{ color: "#959EA7", fontSize: "14px" }}>{getDateAuc(auc?.startDate)}</p>
            </Wrapper>
          ) : auc?.open ? (
            <Wrapper>
              {/* auc timer wrapper */}
              <div>
                <img
                  style={{ marginRight: "5px" }}
                  src={require("../../../images/timeico.png")}
                ></img>
                {t("nft::aucCloseDate")}
                <b>
                  {" "}
                  {timer}&ensp;{day}
                </b>
              </div>
              <p style={{ color: "#959EA7", fontSize: "14px" }}>
                {getDateAuc(auc?.dateEnd)}
              </p>
            </Wrapper>
          ) : !auc?.open &&
            oneNFT?.owner.user_id === userStore.user?.user_id &&
            !oneNFT?.isSelling ? (
            <Wrapper>
              {/* disabled auc, put up for sale/auction modal wrapper */}
              <p>{t("nft::youAreOwner")}</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {t("nft::takeTo")}
                <p
                  style={{ color: "#894ADA", cursor: "pointer" }}
                  onClick={() =>
                    modalStore.showModal(ModalsEnum.SellToken, {
                      id: oneNFT?._id,
                      redirect: true,
                      history: history,
                      amount: oneNFT?.instances?.length ? oneNFT?.instances?.length : 1
                    })
                  }
                >
                  &ensp;{t("nft::sell")}
                </p>
                {!isCopy && (
                  <>/
                <p
                  style={{ color: "#894ADA", cursor: "pointer" }}
                  onClick={() =>
                    modalStore.showModal(ModalsEnum.AucToken, {
                      id: oneNFT?._id,
                      redirect: true,
                      history: history,
                    })
                  }
                >
                  {t("nft::auc")}
                </p></>)}
              </div>
            </Wrapper>
          ) : (
            <Wrapper>
              {/* auction is finished wrapper */}
              <p>{t("nft::aucFinished")}</p>
            </Wrapper>
          )}
        
        </AucTime>
        {/* {(!auc?.open &&
            oneNFT?.owner.user_id === userStore.user?.user_id &&
            !oneNFT?.isSelling)&& 
              <div onClick={()=>downloadFile("https://ipfs.digarty.com/ipfs/" +oneNFT?.file, oneNFT?.title+'.png')}>
                Скачать файл
              </div>} */}
        {auc?.open && (
          <>
          <div style={{display:'flex',maxWidth:'469px',justifyContent:'space-between'}}>
            <Price>
              <p>
                {t("nft::bet")}
                <br />
                <b>
                  {auc?.currentPrice &&
                    web3.utils.fromWei(
                      auc?.currentPrice?.toString(),
                      "ether"
                    )}{" "}
                  BNB
                </b>
                <span>
                ≈ {i18n.language=='ru'?'₽':'$'}{" "}
                  {auc?.price && userStore.bnb && i18n.language=='ru' &&
                    (
                      userStore.bnb *
                      parseFloat(
                        web3.utils.fromWei(
                          auc?.price?.toString(),
                          "ether"
                        )
                      )
                    ).toFixed(2)}
                  {auc?.price && userStore.bnbUsd &&  i18n.language!='ru' &&
                  (
                    userStore.bnbUsd *
                    parseFloat(
                      web3.utils.fromWei(
                        auc?.price?.toString(),
                        "ether"
                      )
                    )
                  ).toFixed(2)}
                </span>
              </p>
            </Price>
            <Price>
              <p> 
              {t("nft::buyout")}
                <br />
                <b>
                  {auc?.fullPrice &&
                    web3.utils.fromWei(
                      auc?.fullPrice?.toString(),
                      "ether"
                    )}{" "}
                  BNB
                </b>
                <span>
                  ≈ {i18n.language=='ru'?'₽':'$'}{" "}
                  {auc?.fullPrice && userStore.bnb && i18n.language=='ru' &&
                    (
                      userStore.bnb *
                      parseFloat(
                        web3.utils.fromWei(
                          auc?.fullPrice?.toString(),
                          "ether"
                        )
                      )
                    ).toFixed(2)}
                  {auc?.fullPrice && userStore.bnbUsd &&  i18n.language!='ru' &&
                  (
                    userStore.bnbUsd *
                    parseFloat(
                      web3.utils.fromWei(
                        auc?.fullPrice?.toString(),
                        "ether"
                      )
                    )
                  ).toFixed(2)}
                </span>
              </p>
            </Price>
            </div>
            <Buttons>
              <SubmitButton
                disabled={
                  oneNFT?.owner.user_id === userStore.user?.user_id || 
                  delay || 
                  stop
                }
                onClick={showModal}
                style={{ marginTop: "4px" }}
              >
                <img src={require('../../../images/icons/cart-shopping.svg')} style={{width:'17px', height:'17px',marginRight:'3px'}}/>
                {/* <FontAwesomeIcon icon={faShoppingCart} /> */}
                {t("nft::bet")}
              </SubmitButton>
              {/* <b>{web3.utils.fromWei(nftStore.oneNFT?.price?.toString(), "ether" )} BNB</b> */}
            </Buttons>
          </>
        )}
      </div>
    );
  }
);
