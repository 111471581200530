import React from 'react';
import { observer } from "mobx-react";
import { useState } from "react"
import {LoginData} from '../../../interfaces/auth'
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import { FormOptions, ModalForm, TextInput, BG, Container, Title } from '../../../shared/forms/forms';
import styled  from "styled-components"
import { NavLink } from 'react-router-dom';
import PrivatePages from "../../../components/auth/auth";
import { useTranslation } from 'react-i18next';


const ButtonWhite = styled.button`
    height: 50px;
    background: white;
    border-radius: 8px;
    border: none;
    font-family: Releway-semiBold;
    color: #894ADA;
    width: 100%;
    margin-top: 16px;
`


const LinkWalletForm = observer(() => {
    const [formData, setFormData] = useState<LoginData>({
        email: '',
        password: ''
    })
    const store = useInjection(UserStore);

    const onChange = (e: any) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }
const {t} = useTranslation()


    const onSubmit = (e: any) => {
        e.preventDefault();
        if(formData){
            store.Login(formData)
        }
    }
   
    return(
        <PrivatePages>
            <ModalForm onSubmit={onSubmit} title={`${t('form::connectWallet2')}`} button={`${t('button::connectWalletButton')}`}>
                {/* <TextInput title='E-mail/Кошелек:' onChange={onChange} hidden={false} />
                <TextInput title='Пароль:' onChange={onChange} hidden={true} />
                <FormOptions rememberPassword linkWallet forgotPassford /> */}
            </ModalForm>    
                    
        </PrivatePages>

    )
})


export default LinkWalletForm