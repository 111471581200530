import {useInjection} from 'inversify-react'
import {observer} from 'mobx-react'
import { NavLink, useRouteMatch} from 'react-router-dom'
import { NavButton } from '../../../shared/buttons/buttons'
import styled from 'styled-components'
import { isProd } from '../../../constants';

import { UIStore } from '../../../stores/uiStore/uiStore'
import { useTranslation } from 'react-i18next'

import { useEffect } from 'react'
import { NFTstore } from '../../../stores/NFTstore.'
//   background-color: ${({theme}) => theme.colors.inputs};
const Container = styled.div`

  height: 112px;

`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 24px;
  padding: 20px 0px;
  border-top: 2px solid #F1EBE9;
  box-sizing: border-box;

  & .ft-leftColumn{
    width: max-content;
    height: 100%;
  }
  & .ft-rightColumn{
    width: auto;
    display: flex;
    align-items: center;
  }
  & .flex{
      display: flex;
      align-items: center;
      margin: 5px 0px;
  }
  & img {
      margin-right: 15px;
  }
  & a{
    white-space: nowrap
  }
    @media (max-width:1000px){

        & .ft-rightColumn .flex{
            flex-direction: column;
            width: 100%;
            align-items: flex-end;
        }
        & .ft-leftColumn .flex:not(#links){
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
        }
        & a{
            margin:0 !important;
            margin-top: 5px;
        }
       
    }
    @media (max-width:450px){
        flex-direction: column;
        & .ft-leftColumn {
            width: 100% !important; 
            display: flex;
            flex-direction: column;
            align-items: center !important;
        }
        & .ft-rightColumn .flex{
            width: 100%;
            align-items: center !important;
        }
        & .ft-leftColumn .flex{
            width: 100%;
            align-items: center !important;

        }
        & #links{
            width: 100%;
            justify-content: center !important;

        }
    }

`
const navStyles = {
    textDecoration:"none",
    color: 'black',
}



const Footer = observer(() => {
    const nftStore = useInjection(NFTstore)
    const {t} = useTranslation()
    let match = useRouteMatch();
    // const footer = useInView();

    // useEffect(()=>{
    //     if(footer.inView&&location.pathname==='/p/catalog'&&nftStore?.NFT?.length>=10) {
    //         nftStore.loadNewPage()
    //     }
    // },[footer.inView])
    return (

        <Container>
            <Flex>
                <div className='ft-leftColumn'>
                    <div id='links' className='flex'>
                        <a href='https://www.instagram.com/_digarty_/' target='_blank'><img src={require('../../../images/ig.png')}/></a>
                        {/* <a href='https://www.instagram.com/_digarty_/' target='_blank'><img src={require('../../../images/tg.png')}/></a> */}
                        <a href='https://www.facebook.com/DIGARTY' target='_blank'><img src={require('../../../images/fb.png')}/></a>
                        <a href='https://twitter.com/DIGARTY1' target='_blank'><img src={require('../../../images/tw.png')}/></a>
                        {/* <img src={require('../../../images/ds.png')}/> */}
                    </div>
                    <div>{t('footer::company')}</div>
                    <div className='flex'>
                        <NavLink 
                        style={{...navStyles, marginRight:'15px'}}
                        to='/p/privacy'>{t('footer::privacy')}</NavLink>
                        {/* <NavLink 
                        style={navStyles}
                        to=''>Реквизиты компании</NavLink> */}
                    </div>
                    
                </div>
                <div className='ft-rightColumn' style ={{display:isProd?'none':'flex'}}>
                    {/* <div className='flex'>
                        <NavLink 
                        style={{...navStyles, marginLeft:'15px'}}
                        to=''>О платформе</NavLink>
                        <NavLink 
                        style={{...navStyles, marginLeft:'15px'}}
                        to=''>Правила площадки</NavLink>
                        <NavLink 
                        style={{...navStyles, marginLeft:'15px'}}
                        to=''>Контакты</NavLink>
                        <NavLink 
                        style={{...navStyles, marginLeft:'15px'}}
                        to=''>Помощь</NavLink>
                    </div> */}
                  
                </div>
            </Flex>
        </Container>
    )
})

export default Footer

const AuthLink =    {
        title: 'Войти',
            path: 'auth'
    };


const Links: ILinks[] = [
    {
        title: 'Новости',
        path: ''
    },
    {
        title: 'Каталог',
        path: 'catalog',
        dropdown: [
            {title:'Все события', path:'all-events'},
            {title:'Все события', path:'all-events'},
            {title:'Все события', path:'all-events'},
        ]
    },

    {
        title: 'Мероприятия',
        path: 'events',
        dropdown: [
            {title:'Все события', path:'all-events'},
            {title:'Все события', path:'all-events'},
            {title:'Все события', path:'all-events'},
        ]
    },
    {
        title: 'Как это работает?',
        path: 'faq'
    },
    {
        title: 'Кошелек',
        path: 'wallet'
    },

]


interface ILinks {
    title: string;
    path: string;
    dropdown?: any;
}