
import {observer} from "mobx-react";
import '../profile.sass'
import {useEffect, useState} from "react";
import { ButtonLight } from "../../../shared/buttons/buttons";
import { useInjection } from "inversify-react";
import { RegisterData, UserStore } from "../../../stores/user/UserStore";
import {NavLink, Redirect, useHistory, useLocation} from "react-router-dom";
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { DropdownMenu } from "../../../shared/buttons/dropdownMenu";
import { Container, SubmitButton, TextInput, Title } from "../../../shared/forms/forms";

import { useTranslation } from "react-i18next";


const Flex = styled.div`
display: flex;
align-items: center;
margin: 10px 0px;

& span {
    margin: 0 10px;
}
`
const NothingHere = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: flex-start;
justify-content:center;
border:1px solid rgba(211, 211, 211, 1);
border-radius: 8px;

h1 {
    font-size:32px
}
`
const Text = styled.div`
padding:28px;
display: flex;
flex-direction: column;
align-items: flex-start;
margin-top:50px;

h1 {
    max-width: 350px;
}
p {
    text-align: left;
    max-width:365px
}
`

export const Path = process.env.REACT_APP_IP;


const BeAnArtist = observer(({match}:any) => {
    const userStore = useInjection(UserStore)
    const location = useLocation();
    const history = useHistory()
    const {t} = useTranslation()
    const [auth, setAuth] = useState(true);
    const [formData, setFormData] = useState<RegisterData>();
    const [check, setCheck] = useState(false)

    useEffect(() => {
        setTimeout(() => setAuth(userStore.isAuth), 500)
    }, [location])
    // console.log('become',  match)
    const onSubmit =(e)=>{
        e.preventDefault()
        if(!check){
            toast.error(t('toasts::access'))
            return
        }
      
        userStore.changeType(formData)
        history.goBack()
    }
    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    if(!auth){
        return <Redirect to={'/p/auth'} />
    }
    
    
    if(userStore.user?.type_pending){
        return(
            <NothingHere >
                <Text>
                    <h1>{t('form::sent')}</h1>
                    <p>{t('form::pendingСonsideration')}</p>
                    {/* {/* <ButtonLight style={{width:'70%',minWidth:'180px'}}onClick={()=>modalStore.showModal(ModalsEnum.Collections)}>{t('collections::create')}</ButtonLight> */}
                    <ButtonLight style={{width:'70%',minWidth:'120px'}}onClick={()=>history.push("../../../../p/catalog")}>{t('button::toCatalog')}</ButtonLight> 
                </Text>
                
                <img src={require('../../../images/looking-out.svg')} style={{padding:'28px'}}/>
            </NothingHere>
        )
    }

    return (
        // <PrivatePages>
           <Container >
               <form  onSubmit={onSubmit}>
               <Title>{t('form::beAnArtistFormTitle')}</Title>
               <TextInput required title={`${t('form::aboutU')}`} onChange={onChange} name='about' border defaultValue={userStore.user?.about} type='text' hidden={false} />
               <TextInput required title={`${t('form::education')}`} onChange={onChange} name='education' border defaultValue={userStore.user?.education} type='text' hidden={false} />
               <TextInput required title={`${t('form::socials')}`}onChange={onChange} name='social' border defaultValue={userStore.user?.social} type='text' hidden={false} />
               <Flex>
                    <input  type='checkbox' checked={check} onChange={(e)=>setCheck(e.target.checked)}  />
                    <span>{t('form::agree')}</span>
                    <NavLink to='/p/contract' target='_blank'>{t('form::ppilicy')}</NavLink>
               </Flex>
               <SubmitButton disabled={!check} type='submit'>{t('button::sendRequest')}</SubmitButton>
               </form>

           </Container>
        // </PrivatePages>
    )
})


export default  BeAnArtist