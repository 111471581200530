import React from 'react';
import { observer } from "mobx-react";
import { useState } from "react"
import {LoginData} from '../../../interfaces/auth'
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import { FormOptions, ModalForm, TextInput, BG, Container, Title } from '../../../shared/forms/forms';
import styled  from "styled-components"
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ButtonWhite = styled.button`
    height: 50px;
    background: white;
    border-radius: 8px;
    border: none;
    font-family: Releway-semiBold;
    color: #894ADA;
    width: 100%;
    margin-top: 16px;
`


const GreetingForm = observer(() => {
    const [formData, setFormData] = useState<LoginData>({
        email: '',
        password: ''
    })
    const store = useInjection(UserStore);
    const {t} = useTranslation()

   
    return(
        <BG>
            <Container >
                <Link to={'/'}>
                    <img className='modalCross' src={require('../../../images/modal-cross.png')} />
                </Link>
                <Title>{t('form::authIn')}</Title>
                <p style={{textAlign:'center'}}> {t('form::logIn')} <br /> {t('form::connectWallet')}</p>
                <NavLink to='auth/signup'>
                    <ButtonWhite>{t('button::registrate')}</ButtonWhite>
                </NavLink>
                {/* <NavLink to='/auth/linkWallet'>
                    <ButtonWhite>Подключить кошелек</ButtonWhite>
                </NavLink> */}
                <NavLink to='auth/login'>
                    <ButtonWhite>{t('button::logToSystem')}</ButtonWhite>
                </NavLink>
            </Container>
        </BG>
    )
})


export default GreetingForm