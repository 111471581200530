// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const DropdownMenu = styled.div`
position: absolute;
top: 21px;
width: max-content;
box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
border-radius: 9px;
padding: 16px 0px;
background-color: white;
/* display: grid; */
/* grid-template-columns: 1fr; */
/* row-gap: 30px; */
z-index: 9999;

`
export const DropdownMenuFilter = styled.div`
position: absolute;
top: 47px;
width: max-content;
box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
border-radius: 9px;
padding: 16px 0px;
background-color: white;
/* display: grid; */
/* grid-template-columns: 1fr; */
/* row-gap: 30px; */
z-index: 9999;
@media(max-width: 985px) {
    top:25px
}
`
const DropdownChild = styled.div`
height: 40px;
display: flex;
align-items: center;
padding: 0 20px;
&:hover {
        background-color: #E8E8F3;
    }
& div{
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
& a {
    text-decoration: none;
    color: #273847;
    font-family: Montserrat-semiBold;
    
    
}
`
export const DropdownLink = styled(NavLink)`
height: 40px;
display: flex;
align-items: center;
padding: 0 20px;
text-decoration: none;
color: #273847;
font-family: Montserrat-semiBold;
&:hover {
        background-color: #E8E8F3;
        color: #273847;
    }
`
export const DropdownP = styled.p`
height: 40px;
display: flex;
align-items:flex-start;
padding: 0 20px;
text-decoration: none;
color: #273847;
font-family: Montserrat-semiBold;
margin: 0;
&:hover {
        background-color: #E8E8F3;
        color: #273847;
    }
`
export const DropdownButton = styled.div`
height: 40px;
display: flex;
align-items:center;
padding: 0 20px;
text-decoration: none!impontant;
color: #273847;
font-family: Montserrat-semiBold;
&:hover {
        background-color: #E8E8F3;
    }
`
interface IDdItem {
    icon?: any;
}

type P = React.PropsWithChildren<IDdItem>

export const DropdownItem = ({children, icon}:P) => {

   return(
       <DropdownChild>
           {icon&&
           <div>
               <img src={icon} style={{width:'17px', height:'17px'}}/>
               {/* <FontAwesomeIcon icon={icon} /> */}
               
           </div>
           }
           {children}
       </DropdownChild>
   )
}
