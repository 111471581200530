import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { NavLink, useHistory } from "react-router-dom";
import { DropdownLink, DropdownMenu } from "../../buttons/dropdownMenu";
import styled from "styled-components";
import { UserStore } from "../../../stores/user/UserStore";
import NavbarButton from "./navbarButton";
import { MutableRefObject, useEffect, useRef, useState } from "react";
// import { faBars, faExclamation, faFutbol, faGamepad, faHourglassStart, faMusic, faPallet, faTh } from '@fortawesome/free-solid-svg-icons'
import { SearchInput } from "../../../shared/forms/forms";
import { UIStore } from "../../../stores/uiStore/uiStore";
import { useTranslation } from "react-i18next";
import { NFTstore } from "../../../stores/NFTstore.";
import i18n from "../../../i18n";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Path = process.env.REACT_APP_IP;

const Container = styled.div`
  width: 100%;
  height: 65px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-image: url(${require("../../../images/backgroundGradient.png")});
  background-size: cover;
  opacity: 1 !important;
`;

const Flex = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(240px, 2fr) 1fr repeat(
      8,
      max-content
    );
  column-gap: 40px;
  align-items: center;
  padding: 0 20px;
  height: 100%;

  @media (max-width: 1300px) {
    column-gap: 20px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: max-content minmax(200px, 2fr) 1fr max-content max-content;
  }
  @media (max-width: 600px) {
    grid-template-columns: max-content 1fr max-content max-content;
    & span {
      display: none;
    }
  }
`;

const AvatarMenu = styled.div`
  & img {
    width: 32px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 100%;
    box-sizing: border-box;
    overflow: hidden;
    object-fit: cover;
  }

  @media (max-width: 1000px) {
    & .desktop {
      display: none;
    }
    & .mobile {
      display: grid;
    }
  }
  @media (min-width: 1001px) {
    & .desktop {
      display: grid;
    }
    & .mobile {
      display: none;
    }
  }
`;
const Logo = styled.div`
  & img {
    width: 100px;
  }
  @media (max-width: 1000px) {
    & img {
      width: 80px;
    }
  }
`;

const Navbar = observer(() => {
  const userStore = useInjection(UserStore);
  const nftStore = useInjection(NFTstore);
  const uiStore = useInjection(UIStore);
  const history = useHistory();
  const [avaDropdown, setAvaDropdown] = useState(false);
  const { t } = useTranslation();
  const [tags, setTags] = useState([
    { title: `${t("navbar::allNFT")}`, path: "p/catalog" },
    { title: `${t("navbar::new")}`, path: "p/catalog" },
    { title: `${t("navbar::soon")}`, path: "p/delay" },
  ]);

  const searchInputRef = useRef<MutableRefObject<HTMLInputElement>>(null);

  useEffect(() => {
    if (history.location.pathname === "/p/catalog") {
      setTimeout(() => {
        searchInputRef?.current?.focus();
      }, 50);
    }
  }, [nftStore.searchField, history.location.pathname]);

  useEffect(() => {
    nftStore.getAllPartitions().then((res) => {
      console.log("parts", res);
      const a = res.map((el, i) => {
        return { title: el.title, path: `p/catalog/${el._id}` };
      });
      const b = tags.concat(a);
      setTags(b);
    });
  }, []);

  const debounce = (func) => {
    let timer;
    // console.log('1')

    return (...args) => {
      // console.log('2')

      clearTimeout(timer);
      // console.log('22')

      timer = setTimeout(() => {
        console.log("3");
        func.apply(this, args);
      }, 50);
    };
  };

  const onSearch = (e) => {
    e.preventDefault();
    if (history.location.pathname !== "/p/catalog") {
      history.push("/p/catalog");
    }

    nftStore.navSearchBarCatalogFilter(e.target.value);
  };
  const debSearch = debounce(onSearch);

  const changeCurrentLang = (lang) => {
    let l = lang === "EN" ? "ru" : "en";
    i18n.changeLanguage(l);
    localStorage.setItem("lang", l);
  };
  const Links: ILinks[] = [
    {
      title: `${t("navbar::constructor")}`,
      path: "p/constructor",
    },
    {
      title: `${t("navbar::news")}`,
      path: "p/news",
    },
    {
      title: `${t("navbar::catalog")}`,
      path: "p/catalog",
      dropdown: tags,
    },

    // {
    //     title: 'Мероприятия',
    //     path: 'p/events',
    //     dropdown: [
    //         {title:'В работе', path:'p/events'},
    //         {title:'Все события', path:'p/events'},
    //         {title:'Трансляции', path:'p/events'},
    //         {title:'Мастерклассы', path:'p/events'},
    //     ]
    // },
    {
      title: `${t("navbar::wallet")}`,
      path: "p/profile/me/wallet",
      private: true,
    },
    {
      title: `${t("navbar::faq")}`,
      path: "p/faq",
    },
    {
      title: `${t("navbar::3dgalleries")}`,
      path: "p/3dgalleries",
    },

    {
      title: `${localStorage
        .getItem("lang")
        ?.toUpperCase()
        .replace(/\"/g, "")}`,
      path: null,
      dropdown: [{ title: "Русский" }, { title: "English" }],
    },
  ];

  return (
    <Container style={{ display: uiStore.navbar ? "block" : "none" }}>
      <Flex>
        <Logo>
          <NavLink
            to="/p/news"
            style={{ textDecoration: "none", color: "white" }}
          >
            <img src={require("../../../images/logo.png")} />
          </NavLink>
        </Logo>

        <div style={{ height: "35px" }}>
          <SearchInput
            inputRef={searchInputRef}
            onChange={(e) => debSearch(e)}
            id="navbarSearchInput"
          />
        </div>

        <span />

        {Links.map((link, i) => {
          return <NavbarButton link={link} key={i} i={i} />;
        })}

        <AvatarMenu
          onMouseEnter={() => setAvaDropdown(true)}
          onMouseLeave={() => setAvaDropdown(false)}
        >
          {userStore.isAuth ? (
            <img src={Path + "/" + userStore.user?.photo} />
          ) : (
            // <FontAwesomeIcon icon={faBars} />
            <img
              src={require("../../../images/icons/bars.svg")}
              style={{ width: "17px", height: "17px", marginLeft: "6px" }}
            />
          )}
          {avaDropdown && (
            <>
              <DropdownMenu
                className="desktop"
                style={{
                  right: "20px",
                  top: userStore.isAuth ? "48px" : "45px",
                }}
              >
                {userStore.isAuth ? (
                  <>
                    <DropdownLink to={"/p/profile/me"}>
                      {t("navbar::profile")}
                    </DropdownLink>
                    <DropdownLink
                      to={"/"}
                      onClick={() => {
                        userStore.LogOut();
                      }}
                    >
                      {t("navbar::logout")}
                    </DropdownLink>
                  </>
                ) : (
                  <DropdownLink to={"/p/auth"}>
                    {t("navbar::login")}
                  </DropdownLink>
                )}
              </DropdownMenu>

              <DropdownMenu
                className="mobile"
                style={{
                  right: "20px",
                  top: userStore.isAuth ? "49px" : "45px",
                }}
              >
                {Links.map((link, i) => {
                  if (link.private && !userStore.isAuth) return;
                  return (
                    <DropdownLink
                      to={
                        link.path ? "/" + link.path : window.location.pathname
                      }
                      onClick={() =>
                        !link.path && changeCurrentLang(link.title)
                      }
                      key={i}
                    >
                      {link.title}
                    </DropdownLink>
                  );
                })}
                {userStore.isAuth ? (
                  <>
                    <DropdownLink to={"/p/profile/me"}>
                      {t("navbar::profile")}
                    </DropdownLink>
                    <DropdownLink
                      to={"/"}
                      onClick={() => {
                        userStore.LogOut();
                      }}
                    >
                      {t("navbar::logout")}
                    </DropdownLink>
                  </>
                ) : (
                  <DropdownLink to={"/p/auth"}>
                    {t("navbar::login")}
                  </DropdownLink>
                )}
              </DropdownMenu>
            </>
          )}
        </AvatarMenu>
      </Flex>
    </Container>
  );
});

export default Navbar;

interface ILinks {
  title: string;
  path: string;
  dropdown?: any;
  private?: boolean;
}
