import React from 'react';
import { observer } from "mobx-react";
import { useState } from "react"
import { useInjection } from "inversify-react";
import {LoginData, UserStore} from "../../../stores/user/UserStore";
import { FormOptions, ModalForm, TextInput, BG, Container, Title } from '../../../shared/forms/forms';
import styled  from "styled-components"
import { NavLink } from 'react-router-dom';
import PrivatePages from "../../../components/auth/auth";
import { useTranslation } from 'react-i18next';



const LogInForm = observer(() => {
    const [formData, setFormData] = useState<LoginData>({
        password: '',
        value: ''
    })
    const userStore = useInjection(UserStore);
    const {t} = useTranslation()
    const onChange = (e: any) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }



    const onSubmit = (e: any) => {
        e.preventDefault();
        userStore.Login(formData);

    }
   
    return(
        <PrivatePages>
            <ModalForm onSubmit={onSubmit} title={`${t('form::loginTitle')}`} button={`${t('button::send')}`}>
                <TextInput title={`${t('form::emailOrWallet')}`} name={'value'} onChange={onChange} hidden={false} />
                <TextInput title={`${t('form::password')}`} name={'password'} onChange={onChange} hidden={true} />
                <FormOptions rememberPassword forgotPassford />
            </ModalForm>    
        </PrivatePages>

    )
})


export default LogInForm