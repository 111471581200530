import React from 'react';
import {
    Redirect,
    Route, Switch, useLocation,
} from "react-router-dom";
import PrivatePages from "../../components/auth/auth";
import GreetingForm from './forms/greetingForm';
import SignUpForm from './forms/signUpForm';
import LogInForm from './forms/logInForm';
import LinkWalletForm from './forms/linkWalletForm';
import RestorePasswordForm from './forms/restorePasswordForm';
import NewPasswordForm from './forms/newPasswordForm';
import {useInjection} from "inversify-react";
import {UserStore} from "../../stores/user/UserStore";
import {observer} from "mobx-react";
import Validation from "./validation";
import ReStore from "./validation/restore";

const Auth = observer(({match}:any) => {
    const userStore = useInjection(UserStore)
    const location = useLocation();
    if(userStore.isAuth && location.pathname!==`${match.path}/restorePassword` && location.pathname !== `${match.path}/newPassword`){
        return <Redirect to={'/p/catalog'} />
    }

    return (
        <PrivatePages>
            <Switch>
                <Route exact path={`${match.path}/`} component={GreetingForm} />
                <Route exact path={`${match.path}/signup`} component={SignUpForm} />
                <Route exact path={`${match.path}/login`} component={LogInForm} />
                <Route exact path={`${match.path}/linkWallet`} component={LinkWalletForm} />
                <Route exact path={`${match.path}/restorePassword`} component={RestorePasswordForm} />
                <Route exact path={`${match.path}/newPassword`} component={NewPasswordForm} />
                <Route exact path={`${match.path}/code/:id`} component={Validation} />
                <Route exact path={`${match.path}/restore/:id`} component={ReStore} />
            </Switch>
        </PrivatePages>

    )
})

export default Auth