import {observer} from "mobx-react";
import './profileComponents.sass'
import CatalogItem from "../../pages/Catalog/catalogItem";
import { Table } from "../../shared/tables/tables";
import styled from "styled-components";
import SearchBar from "./searchBar";
import { ButtonLight } from "../../shared/buttons/buttons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";
import Message, { IMsg } from "./message";
// import { faImage, faPaperclip } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalStore,ModalsEnum } from "../../stores/ModalStore";
import { toast } from "react-toastify";
interface IBlog {
    _id?: string,
    user_id: string
}
const Blog = observer(({_id, user_id}:IBlog) => {
    const {t} = useTranslation()
    const userStore = useInjection(UserStore)
    const modalStore = useInjection(ModalStore)
    const [focusTa, setFocusTa] = useState(false)
    const [focusI, setFocusI] = useState(false)
    const [advice, setAdvice] = useState<string>('')
    const [file, setFile] = useState(null)
    const [me, setMe] = useState(false)
    const [fileName ,setFileName] =useState<any>('')
    const [text ,setText] =useState<string>('')
    const [title ,setTitle] =useState<string>('')
    const sendMsg = (e) =>{
        e.preventDefault()
        if(text!==''){
            const MsgData = new FormData();
            if(title){
                MsgData.append('title', title)
                setTitle('')
            }
            if(file){
                MsgData.append('file', file)
                setFile(null)
                setFileName('')
            }
            if(userStore.currentPostNft){
                let nfts = [userStore.currentPostNft]
                // console.log(nfts)
                for (let i = 0; i < nfts.length; i++) {
                    MsgData.append('nfts[]', nfts[i]);
                } 
            }
            MsgData.append('text', text)
            userStore.createNewPost(MsgData).then(()=>{
                toast.success('Пост создан')
                setText('')
                userStore.setCurrentPostNft(null)
            })
        }
        else {
            toast.error('Введите текст сообщения')
        }
        // userStore.createNewPost()
    }
    const deleteNftBind = () =>{
        userStore.setCurrentPostNft(null)
    }

    const deleteFile = () =>{
        setFile (null)
        setFileName('')
    }
    const uploadFile = (e) =>{
        console.log(e.target.files[0])
        setFile (e.target.files[0])
        if(e.target.files[0]) {
            setFileName(e.target.files[0].name)
        }
    }
    useEffect(()=>{
        userStore.getOwnedNFT(_id)
        
    },[_id])
    useEffect(()=>{
        if(userStore?.user?.user_id===user_id) {
            userStore.getposts()
            setMe(true)  
        }
        else{
            console.log(_id)
            userStore.getUserPosts(_id)
            setMe(false)
        }
    },[user_id])
    useEffect(()=>{
        return () =>{
            userStore.clearPostStore()
            userStore.setCurrentPostNft(null)
        }
    },[])
    const note = (text) => {
       setAdvice(text)
    }
    return (
        <SmoothAppearance trigger={userStore.user}>
        {/* {console.log(userStore?.posts)} */}
        <div className='lk-myNft'>
            {me&&<form className='lk-blog' onSubmit={sendMsg}>
                <input value={title} onChange={(e)=>setTitle(e.target.value)} placeholder={t('sidebar::title')} className='lk-input' style={{boxShadow:!focusI?'0px 1px 2px 1px rgba(0,0,0,0.61)':'0px 1px 5px 2px rgba(0,0,0,0.61)'}} onFocus={()=> setFocusI(true)} onBlur={()=>setFocusI(false)}/>
                <textarea value={text} onChange={(e)=>setText(e.target.value)}placeholder={t('sidebar::placeholder')} className='lk-textarea' style={{boxShadow:!focusTa?'0px 1px 2px 1px rgba(0,0,0,0.61)':'0px 1px 5px 2px rgba(0,0,0,0.61)' }} onFocus={()=> setFocusTa(true)} onBlur={()=>setFocusTa(false)}></textarea>
                <div style={{display:'flex'}}>
                    <div className='lk-blog-part' style={{display:fileName!==''?'flex':'none'}}> 
                        <span>{fileName}</span>
                        <img src={require('../../images/modal-cross.png')} onClick={deleteFile}></img>
                    </div>
                    <div className='lk-blog-part' style={{display:userStore?.currentPostNft?'flex':'none',marginLeft:'7px'}}> 
                        <span>{userStore?.currentPostNft?.name}</span>
                        <img src={require('../../images/modal-cross.png')} onClick={deleteNftBind}></img>
                    </div>
                </div>
                <div className='lk-blog-icons'>
                
                    <div style={{display:'flex',alignItems:'center'}}>
                        <input type='file' onChange={uploadFile} className="lk-blog-file" onMouseEnter={()=>note(t('sidebar::addImg'))} onMouseLeave={()=>note('')} />
                        <img src={require('../../images/icons/image.svg')} style={{width:'17px', height:'17px'}}/>
                        <img src={require('../../images/icons/paperclip.svg')} onClick={()=>modalStore.showModal(ModalsEnum.MyNFTs, {type: 'blog'})} style={{width:'17px',height:'17px', marginLeft:'5px',fontWeight:'lighter', color:'rgba(0,0,0,0.61)'}} onMouseEnter={()=>note(t('sidebar::attach'))} onMouseLeave={()=>note("")}/>
                        {/* <FontAwesomeIcon icon={faImage}  style={{width:'20px',height:'20px',fontWeight:'lighter', color:'rgba(0,0,0,0.61)'}}/>
                        <FontAwesomeIcon icon={faPaperclip}onClick={()=>modalStore.showModal(ModalsEnum.MyNFTs)} style={{width:'20px',height:'20px', marginLeft:'5px',fontWeight:'lighter', color:'rgba(0,0,0,0.61)'}} onMouseEnter={()=>note(t('sidebar::attach'))} onMouseLeave={()=>note("")}/> */}
                        <div className='lk-text' style={{fontFamily: 'Montserrat-regular',marginLeft:'5px', color:'rgba(0,0,0,0.61)'}}>{advice}</div> 
                    </div>
                    
                    <ButtonLight width='180px' violet type='submit'>Отправить</ButtonLight>
                </div>
                
            </form>
            }
            <div style={{marginTop:me?'170px':'0px'}}>
                
                { userStore?.posts?.map((el,i)=>{
                    return (
                        <Message text={el?.text} time={el?.date} title={el?.title} nft={el?.nfts} img={el?.image} _id={el._id} key={i} me={me}></Message>  
                    )
                }).reverse()}
            </div>
            
            
        </div>
        </SmoothAppearance>
    )
})


export default  Blog