import React from 'react';
import { observer } from "mobx-react";
import { useState } from "react"
import {LoginData} from '../../../interfaces/auth'
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import { FormOptions, ModalForm, TextInput, BG, Container, Title } from '../../../shared/forms/forms';
import styled  from "styled-components"
import { NavLink } from 'react-router-dom';
import PrivatePages from "../../../components/auth/auth";
import {toast} from "react-toastify";
import { useTranslation } from 'react-i18next';



const RestorePasswordForm = observer(() => {
    const [formData, setFormData] = useState<LoginData>({
        email: ''
    })
    const [complite, setComplite] = useState(false);

    const store = useInjection(UserStore);
    const {t} = useTranslation()
    const onChange = (e: any) => {
         setFormData({...formData, [e.target.name]: e.target.value})
    }




    const onSubmit = (e: any) => {
        e.preventDefault();
        store.restorePassword(formData).then((res) => {
            if(res){
                toast.success(t('toasts::linkSent'));
                setComplite(true)
            }

        }).catch(() => console.log('err'))


    }
   
    return(
        <PrivatePages>

            <ModalForm onSubmit={onSubmit} title={`${t('form::passwordLetter')}`} button={`${t('button::send')}`} complite={complite}>
                <TextInput title={`${t('form::email')}`} name={'email'} onChange={onChange} hidden={false} />
                <FormOptions somthWrong reSend={onSubmit} complite={complite} />
            </ModalForm>
                    
        </PrivatePages>

    )
})


export default RestorePasswordForm