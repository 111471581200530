import { observer } from "mobx-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { url } from "../../../components/auth/helper";
import { Description } from "../index";

export const LeftCol = observer(({oneNFT}) => {
const [playing, setPlaying] = useState(true)
const [muted, setMuted] = useState(true)
const {t} = useTranslation()
  return (
    <div className="nft-left-column">
      <div className="nft-img" onClick={() => setPlaying(!playing)}>
        {oneNFT?.category === "Видео" &&
        !oneNFT.preview.includes("gif") ? (
          <ReactPlayer
            url={url + "/" + oneNFT?.preview}
            muted={muted}
            style={{ cursor: "pointer" }}
            loop={true}
            playing={playing}
            volume={0.3}
            width="100%"
            height="100%"
            onMouseEnter={()=>{setMuted(false)}}
            onMouseLeave={()=>{setMuted(true)}}
          />
        ) : (
          <img src={url + "/" + oneNFT?.preview} />
        )}
      </div>

      <Description className="ntf-desctop-description">
        <h2>{t("nft::description")}</h2>
        {oneNFT?.about}
      </Description>
    </div>
  );
})
