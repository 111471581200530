import React from 'react';
import Modal from "../components/Modal";
import {ModalsEnum, ModalStore} from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { SubmitButton,TextInput, Title  } from '../shared/forms/forms';
import { UserStore } from '../stores/user/UserStore';
import { toast } from 'react-toastify';
import { NFTstore } from "../stores/NFTstore.";
import Web3 from 'web3';
import { WithTranslation, withTranslation } from 'react-i18next';
interface IConnectModalProps extends WithTranslation {
    data: { 
        id: string,
        redirect?: boolean,
        history?:any,
        amount?: number
     };
    idx: number;
}

interface IConnectModalState {
}

@observer
class SellTokenModal extends React.Component<IConnectModalProps, IConnectModalState> {
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(UserStore)
    declare protected readonly userStore: UserStore;
    @resolve( NFTstore)
    declare protected readonly nftStore:  NFTstore;
    state = { price: 0, amount:1 }
    
    onChange = (e) =>{
        // console.log(this.props.data.history)
        let web3 = new Web3(process.env.RPC_URL_97);
        this.setState({price:web3.utils.toWei(e.target.value,"ether")})
    }
    onChangeAmount = (e) =>{
        this.setState({amount: e.target.value})
    }
    onSubmit = (e) =>{
        e.preventDefault()
        if(this.state.price==0 ){
            toast.error(this.props.t("toasts::allFields"))
            return
        }
        if(this.props.data.amount<this.state.amount){
            toast.error(this.props.t("toasts::correct"))
            return
        }
            this.userStore.startSelling(this.props.data.id, {price: this.state.price, amount: this.state.amount})
               .then(res =>{
                // console.log(res)
                   toast.success(this.props.t("toasts::onSale"))
                   this.modalStore.hideModal(this.props.idx)
                   if(this.props.data.redirect){
                    this.props.data.history.push('../../../p/catalog')  
                   }
               })
               .catch(err=>{
                //    console.log(err)
                   let msg = err.response.data.err
                   err?.message && toast.error(msg)
               })
    }
    render() {
        return (
            <Modal modalKey={ModalsEnum.SellToken} idx={this.props.idx}>
                <Title>{this.props.t('modals::toSale')}</Title>
                <form onSubmit={this.onSubmit}>
                    <TextInput  title={this.props.t('modals::enterPrice')} type='number' onChange={this.onChange} border></TextInput>
                    {this.props.data.amount>1 && <TextInput  title={this.props.t('modals::copyAmount')+this.props.data.amount+")"} type='number'  onChange={this.onChangeAmount} border></TextInput>}
                    <SubmitButton type='submit'>{this.props.t('modals::confirm')}</SubmitButton>
                </form>
            </Modal>
        )
    }
}
export default withTranslation()(SellTokenModal);

