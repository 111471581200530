import styled from 'styled-components'

export interface IFilter {
    bg:string
  }
export interface IButton {
  width?:string,
  color?:string,
  violet?: boolean
}

export const NavButton = styled.div`
display:flex;
align-items:center;
position: relative;
border-bottom: 2px solid transparent;
@media(max-width:1000px){
  display: none;
}
`
export const Chevron = styled.img`
height: 4px;
width:7px;
margin-left: 5px;
`

export const Filter = styled.div`
    background-color:#F1EBE9;
    border:2px solid #F1EBE9;
    position: relative;
    backdrop-filter: blur(2px);
    border-radius: 8px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 49px;
    padding: 0px 25px;
    margin-left:10px;
    font-family: Releway-medium;
    font-size: 14px;
    color:#273847;
    cursor:pointer;
    &:hover{
      background-color: white;
    }
    @media (max-width:1000px){
      height: 30px;
      padding: 0px 15px;
      font-size: 12px;
    }
`
export const Sort = styled.div`
    background-color:#F1EBE9;
    border:2px solid #F1EBE9;
    position: relative;
    backdrop-filter: blur(2px);
    border-radius: 40px;
    width: fit-content;
    align-items: center;
    justify-content: center;
    height: 49px;
    padding: 0px 25px;
    margin-left:10px;
    font-family: Releway-medium;
    font-size: 14px;
    color:#273847;
    cursor:pointer;
    &:hover{
      background-color: white;
    }
    & div{
      top:46px;
      right:-20px;
      display:grid;
    }
    @media (max-width:1000px){
      height: 30px;
      padding: 0px 15px;
      font-size: 12px;
      & div {
        top:28px;
        right:-40px;

      }
    }
`
export const ButtonLight = styled.button`
    margin-bottom: 8px ;
    width: ${(p: IButton) => p.width? p.width : '100%'};
    height: 40px;
    color:  ${(p: IButton) => p.violet?'white':'#894ADA'};
    margin-top: 8px;
    font-family: Releway-semiBold;
    font-size: 16px;
    background:${(p: IButton) => p.violet?'rgba(138, 74, 218, 1)':'rgba(138, 74, 218, 0.25)'} ;
    border: none;
    border-radius: 8px;

    & label {
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex ;
      align-items: center;
      justify-content: center;
    }
    & a {
      text-decoration: none;
      color: #894ADA;

    }
`

export const ButtonText = styled.button`
color: ${(p: IButton) => p.color? p.color : 'black'};
background: none;
border: none;

`
