import {observer} from "mobx-react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components'


const Container = styled.div`
width:100%;
 height:75vh;
`

const Сontract = observer(({match}) => {
 const {t} = useTranslation()
 
    return (
        // <PrivatePages>
            <Container >
                <iframe src={require('../../images/dogovor.pdf')} width={'100%'} height={'100%'} />
            </Container>

        // </PrivatePages>
    )
})


export default  Сontract