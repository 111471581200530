

const addXmlHotspots =(room,hotspots)=>{
   console.log('%caddXmlHotspot.ts line:4 hotspots', 'color: #007acc;', hotspots, room);
    let preset=(url, params, hotspot, scene, type, link?:any,)=>{
      
       if(type==='Видео') {
         return `<hotspot  name="hotspot_${hotspot}" style="vid_hs_addhs" type="image" keep="true" url="%FIRSTXML%/plugins/videoplayer.js" distorted="true" zorder="5"
             ${params}
 			videourl="${url}" 
 			pausedonstart="true" loop="true" playbackrate="1" onvideoplay="set(play_vh,true);" autopause="true" autoresume="false"
 			install_onclick="11" info_Ah="Russian Pavilion.mp4" scene_EL="scene_1-${scene}" drag_hs="false" fov_EL="120" play_vh="false" enabled2="true" visible2="true"
 			Vsound3D="false" volume_max="0.5" range_sound="90"
 			onclick="togglepause(); if(hotspot[sound_hs_addhs], delete_sound_hs_addhs(); );   if(ispaused,set(play_vh,true);,set(play_vh,false); );"
			ondown="drag_LinkSM()"
 		/>` 
       } else if(type==='Изображение') {
         return `<hotspot name="hotspot_${hotspot}" style="link_hs_sm" type="image" keep="true" url="${url}" distorted="true" enabled="false" visible="false" zorder="5"
                     ${params} ${(!link||link===undefined||link==='undefined') ? '' : link}
                    install_onclick="5" title="hotspot_4" scene_EL="scene_1-${scene}" fov_EL="131.54" enabled2="true" visible2="true" drag_hs="true"
                    ondown="drag_LinkSM()"
        />`
       }
        
    }
   
    if(room==='classic'||room==='dark-hall'||room==='neon'){
        return `
        <krpano>
        
            <include url="plugins_l/plugin_a/photo.xml"/>
            <include url="plugins_l/plugin_a/youtube.xml"/>
            <include url="plugins_l/plugin_a/video.xml"/>
            <include url="plugins_l/plugin_a/poligon.xml"/>
        
            <addhs_settings  pin_cod="" 
                              sound_close_click="true" 
                              video_close_click="true" 
                              youtube_close_click="true" 
                             FIRSTXML="tour" 
                             path="%FIRSTXML%/" 
                             action_list="'add_hotspot/plugins_l/actionSM.html'" 
                             photo="true" 
                             video="true" 
                             youtube="true" 
                            />
            
        ${
           paramsByType.classic.map((el,i)=>{
            if(i===0||i===7||i===8||i===14) {
               return preset(hotspots[0].link,el.param,el.hotspot, el.scene, hotspots[0].type,i===0?`onclick="openurl(${hotspots[0].openurl}, _blank);"`:`onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"`)
            }  else if(i===1||i===6||i===9||i===13) {
               return preset(hotspots[1].link,el.param,el.hotspot, el.scene, hotspots[1].type,i===9?`onclick="openurl(${hotspots[1].openurl}, _blank);"`:`onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"`)
            }  else if(i===2||i===4||i===10||i===15) {
               return preset(hotspots[2].link,el.param,el.hotspot, el.scene, hotspots[2].type,i===4?`onclick="openurl(${hotspots[2].openurl}, _blank);"`:`onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"`)
            }  else if(i===3||i===5||i===11||i===12) {
               return preset(hotspots[3].link,el.param,el.hotspot, el.scene, hotspots[3].type,i===12?`onclick="openurl(${hotspots[3].openurl}, _blank);"`:`onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"`)
            }
                

        })
            
      }
            
         <style name="pl_hs_add_hs"
                 keep="true"
                enabled2="true"
                 visible2="true"
                 visible="false"
                 alpha="1"
                 alpha2="1"
                 capture="false"
                 handcursor="true"
                 zorder="0"
                 install_onclick="14"
                 fov_EL="90"
                 fillcolor="0x1500FF"
                 fillcolor2="0xE85A5A"
                 fillalpha="0.50"
                 fillalpha2="0.50"
                 borderwidth="1"
                 borderwidth2="1"
                 bordercolor="0x55FF00"
                 bordercolor2="0x55FF00"
                 borderalpha="1.00"
                 borderalpha2="1.00"
                 onover="set_pol_2(get(name));"
                 onout="set_pol_1(get(name));"
                 ondown="ondown_pl_hs();"
                 blendmode="normal"
             />
        
             <!-- polygonal hotspot -->
        
         <hotspot name="hs_polig_4" style="pl_hs_add_hs" alpha="NaN" fillcolor="0x1500FF" fillalpha="NaN" borderwidth="NaN" bordercolor="0x55FF00" borderalpha="NaN"
                   title="hs_polig_4" scene_EL="scene_1-2" alpha2="0"
                   onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="75.334" atv="10.421" />
                   <point ath="76.748" atv="-14.456" />
                   <point ath="104.011" atv="-11.476" />
                   <point ath="104.112" atv="10.087" />
         </hotspot>
        
         <hotspot name="hs_polig_2" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_2" scene_EL="scene_1-1" alpha2="0"
                   onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="68.708" atv="28.605" />
                   <point ath="70.152" atv="-18.193" />
                   <point ath="137.552" atv="-17.26" />
                   <point ath="131.773" atv="24.241" />
         </hotspot>
        
         <hotspot name="hs_polig_1" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_1" scene_EL="scene_1-1" alpha2="0"
                   onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="151.858" atv="20.061" />
                   <point ath="150.246" atv="-15.974" />
                   <point ath="-151.852" atv="-15.662" />
                   <point ath="-154.138" atv="18.791" />
         </hotspot>
        
         <hotspot name="hs_polig_3" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_3" scene_EL="scene_1-1" alpha2="0"
                   onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="-129.239" atv="24.301" />
                   <point ath="-129.294" atv="-21.182" />
                   <point ath="-75.646" atv="-16.517" />
                   <point ath="-75.18" atv="23.649" />
         </hotspot>
        
         <hotspot name="hs_polig_5" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_5" scene_EL="scene_1-2" alpha2="0"
                   onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="142.629" atv="-13.224" />
                   <point ath="140.586" atv="13.259" />
                   <point ath="-169.289" atv="17.533" />
                   <point ath="-170.701" atv="-18.684" />
         </hotspot>
        
         <hotspot name="hs_polig_6" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_6" scene_EL="scene_1-2" alpha2="0"
                   onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="-17.745" atv="31.882" />
                   <point ath="-14.305" atv="-22.421" />
                   <point ath="55.468" atv="-14.035" />
                   <point ath="56.653" atv="14.341" />
         </hotspot>
        
         <hotspot name="hs_polig_7" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_7" scene_EL="scene_1-4" alpha2="0"
                   onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="-67.68" atv="21.234" />
                   <point ath="-66.305" atv="-8.502" />
                   <point ath="-35.901" atv="-7.183" />
                   <point ath="-34.475" atv="14.037" />
         </hotspot>
        
         <hotspot name="hs_polig_8" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_8" scene_EL="scene_1-4" alpha2="0"
                   onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="-21.624" atv="14.061" />
                   <point ath="-20.841" atv="-14.059" />
                   <point ath="20.434" atv="-11.98" />
                   <point ath="21.062" atv="13.413" />
         </hotspot>
        
         <hotspot name="hs_polig_9" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_9" scene_EL="scene_1-4" alpha2="0"
                   onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="69.661" atv="-16.693" />
                   <point ath="69.728" atv="20.19" />
                   <point ath="32.849" atv="16.152" />
                   <point ath="35.075" atv="-15.332" />
         </hotspot>
        
         <hotspot name="hs_polig_10" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_10" scene_EL="scene_1-3" alpha2="0"
                   onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="-57.698" atv="16.12" />
                   <point ath="-56.587" atv="-18.097" />
                   <point ath="16.471" atv="-26.289" />
                   <point ath="19.147" atv="24.687" />
         </hotspot>
        
         <hotspot name="hs_polig_11" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_11" scene_EL="scene_1-3" alpha2="0"
                   onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="62.914" atv="21.055" />
                   <point ath="64.031" atv="-21.922" />
                   <point ath="115.604" atv="-19.853" />
                   <point ath="116.232" atv="20.757" />
         </hotspot>
        
         <hotspot name="hs_polig_12" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_12" scene_EL="scene_1-3" alpha2="0"
                   onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="-137.836" atv="12.402" />
                   <point ath="-139.196" atv="-10.098" />
                   <point ath="167.673" atv="-15.144" />
                   <point ath="165.968" atv="18.628" />
         </hotspot>
        
         <hotspot name="hs_polig_14" style="pl_hs_add_hs" alpha="0"
                   title="hs_polig_14" scene_EL="scene_1-3" alpha2="0"
                   onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
                >
                   <point ath="-105.25" atv="-11.428" />
                   <point ath="-72.466" atv="-9.826" />
                   <point ath="-72.342" atv="12.843" />
                   <point ath="-105.061" atv="13.389" />
         </hotspot>
        
        
         
        </krpano>
        `
    } else if (room==='loft') {
        return `
        <krpano>
        
           <include url="plugins_l/plugin_a/photo.xml"/>
           <include url="plugins_l/plugin_a/youtube.xml"/>
           <include url="plugins_l/plugin_a/video.xml"/>
           <include url="plugins_l/plugin_a/poligon.xml"/>
        
           <addhs_settings  pin_cod="" 
                         sound_close_click="true" 
                         video_close_click="true" 
                         youtube_close_click="true" 
                        FIRSTXML="tour" 
                        path="%FIRSTXML%/" 
                        action_list="'add_hotspot/plugins_l/actionSM.html'" 
                        photo="true" 
                        video="true" 
                        youtube="true" 
                        />
           
        
                        ${
                           paramsByType.loft.map((el,i)=>{
                              if(i===1||i===9||i===14 ||i===2) {
                                 return preset(hotspots[0].link,el.param,el.hotspot, el.scene, hotspots[0].type,i===2?`onclick="openurl(${hotspots[0].openurl}, _blank);"`:`onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"`)
                              }  else if(i===7||i===12||i===13||i===6) {
                                 return preset(hotspots[1].link,el.param,el.hotspot, el.scene, hotspots[1].type,i===12?`onclick="openurl(${hotspots[1].openurl}, _blank);"`:`onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"`)
                              }  else if(i===8||i===3||i===0||i===15) {
                                 return preset(hotspots[2].link,el.param,el.hotspot, el.scene, hotspots[2].type,i===0?`onclick="openurl(${hotspots[2].openurl}, _blank);"`:`onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"`)
                              }  else if(i===11||i===4||i===10||i===5) {
                                 return preset(hotspots[3].link,el.param,el.hotspot, el.scene, hotspots[3].type,i===11?`onclick="openurl(${hotspots[3].openurl}, _blank);"`:`onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"`)
                              }
                                
                
                        })
                            
                      }
           
         <style name="pl_hs_add_hs"
               keep="true"
              enabled2="true"
               visible2="true"
               visible="false"
               alpha="1"
               alpha2="1"
               capture="false"
               handcursor="true"
               zorder="0"
               install_onclick="14"
               fov_EL="90"
               fillcolor="0x1500FF"
               fillcolor2="0xE85A5A"
               fillalpha="0.50"
               fillalpha2="0.50"
               borderwidth="1"
               borderwidth2="1"
               bordercolor="0x55FF00"
               bordercolor2="0x55FF00"
               borderalpha="1.00"
               borderalpha2="1.00"
               onover="set_pol_2(get(name));"
               onout="set_pol_1(get(name));"
               ondown="ondown_pl_hs();"
               blendmode="normal"
            />
        
            <!-- polygonal hotspot -->
        
         <hotspot name="hs_polig_4" style="pl_hs_add_hs" alpha="NaN" fillcolor="0x1500FF" fillalpha="NaN" borderwidth="NaN" bordercolor="0x55FF00" borderalpha="NaN"
                 title="hs_polig_4" scene_EL="scene_1-2" alpha2="0"
                 onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="-111.572" atv="18.195" />
                 <point ath="-110.1" atv="-15.946" />
                 <point ath="-69.152" atv="-14.954" />
                 <point ath="-67.679" atv="18.786" />
         </hotspot>
        
         <hotspot name="hs_polig_2" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_2" scene_EL="scene_1-1" alpha2="0"
                 onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="68.708" atv="28.605" />
                 <point ath="70.152" atv="-18.193" />
                 <point ath="137.552" atv="-17.26" />
                 <point ath="131.773" atv="24.241" />
         </hotspot>
        
         <hotspot name="hs_polig_1" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_1" scene_EL="scene_1-1" alpha2="0"
                 onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="151.858" atv="20.061" />
                 <point ath="150.246" atv="-15.974" />
                 <point ath="-151.852" atv="-15.662" />
                 <point ath="-154.138" atv="18.791" />
         </hotspot>
        
         <hotspot name="hs_polig_3" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_3" scene_EL="scene_1-1" alpha2="0"
                 onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="-129.239" atv="24.301" />
                 <point ath="-129.294" atv="-21.182" />
                 <point ath="-75.646" atv="-16.517" />
                 <point ath="-75.18" atv="23.649" />
         </hotspot>
        
         <hotspot name="hs_polig_5" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_5" scene_EL="scene_1-2" alpha2="0"
                 onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="172.074" atv="-19.16" />
                 <point ath="167.628" atv="24.803" />
                 <point ath="-124.049" atv="17.791" />
                 <point ath="-131.161" atv="-17.492" />
         </hotspot>
        
         <hotspot name="hs_polig_6" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_6" scene_EL="scene_1-2" alpha2="0"
                 onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="-2.893" atv="33.491" />
                 <point ath="-1.344" atv="-20.529" />
                 <point ath="-62.95" atv="-14.675" />
                 <point ath="-61.67" atv="17.473" />
         </hotspot>
        
         <hotspot name="hs_polig_7" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_7" scene_EL="scene_1-4" alpha2="0"
                 onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="-83.347" atv="28.658" />
                 <point ath="-80.613" atv="-26.11" />
                 <point ath="-30.707" atv="-15.28" />
                 <point ath="-28.902" atv="14.669" />
         </hotspot>
        
         <hotspot name="hs_polig_8" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_8" scene_EL="scene_1-4" alpha2="0"
                 onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="-22.658" atv="16.551" />
                 <point ath="-23.168" atv="-16.49" />
                 <point ath="22.621" atv="-13.655" />
                 <point ath="22.899" atv="15.767" />
         </hotspot>
        
         <hotspot name="hs_polig_9" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_9" scene_EL="scene_1-4" alpha2="0"
                 onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="79.285" atv="-20.79" />
                 <point ath="80.762" atv="22.826" />
                 <point ath="32.849" atv="16.152" />
                 <point ath="35.075" atv="-15.332" />
         </hotspot>
        
         <hotspot name="hs_polig_10" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_10" scene_EL="scene_1-3" alpha2="0"
                 onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="-10.134" atv="28.531" />
                 <point ath="-5.992" atv="-27.804" />
                 <point ath="53.044" atv="-18.377" />
                 <point ath="59.475" atv="19.268" />
         </hotspot>
        
         <hotspot name="hs_polig_11" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_11" scene_EL="scene_1-3" alpha2="0"
                 onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="67.98" atv="19.646" />
                 <point ath="69.995" atv="-21.52" />
                 <point ath="112.806" atv="-20.341" />
                 <point ath="111.975" atv="18.624" />
         </hotspot>
        
         <hotspot name="hs_polig_12" style="pl_hs_add_hs" alpha="0"
                 title="hs_polig_12" scene_EL="scene_1-3" alpha2="0"
                 onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"
              >
                 <point ath="-172.862" atv="25.662" />
                 <point ath="-176.22" atv="-17.401" />
                 <point ath="130.909" atv="-14.963" />
                 <point ath="128.611" atv="17.892" />
         </hotspot>
        
        
         
        </krpano>
        `
    }else if (room==='minimalism') {
      return `
      

      <krpano>
        
      <include url="plugins_l/plugin_a/photo.xml"/>
      <include url="plugins_l/plugin_a/youtube.xml"/>
      <include url="plugins_l/plugin_a/video.xml"/>
      <include url="plugins_l/plugin_a/poligon.xml"/>
  
      <addhs_settings  pin_cod="" 
                        sound_close_click="true" 
                        video_close_click="true" 
                        youtube_close_click="true" 
                       FIRSTXML="tour" 
                       path="%FIRSTXML%/" 
                       action_list="'add_hotspot/plugins_l/actionSM.html'" 
                       photo="true" 
                       video="true" 
                       youtube="true" 
                      />
      
                      ${
                        paramsByType.minimalism.map((el,i)=>{
                           if(i===1||i===9||i===14 ||i===2) {
                              return preset(hotspots[0].link,el.param,el.hotspot, el.scene, hotspots[0].type)
                           }  else if(i===7||i===12||i===13||i===6) {
                              return preset(hotspots[1].link,el.param,el.hotspot, el.scene, hotspots[1].type)
                           }  else if(i===8||i===3||i===0||i===15) {
                              return preset(hotspots[2].link,el.param,el.hotspot, el.scene, hotspots[2].type)
                           }  else if(i===11||i===4||i===10||i===5) {
                              return preset(hotspots[3].link,el.param,el.hotspot, el.scene, hotspots[3].type)
                           }
                             
             
                     })
                         
                   }
      
   <style name="pl_hs_add_hs"
           keep="true"
          enabled2="true"
           visible2="true"
           visible="false"
           alpha="1"
           alpha2="1"
           capture="false"
           handcursor="true"
           zorder="0"
           install_onclick="14"
           fov_EL="90"
           fillcolor="0x1500FF"
           fillcolor2="0xE85A5A"
           fillalpha="0.50"
           fillalpha2="0.50"
           borderwidth="1"
           borderwidth2="1"
           bordercolor="0x55FF00"
           bordercolor2="0x55FF00"
           borderalpha="1.00"
           borderalpha2="1.00"
           onover="set_pol_2(get(name));"
           onout="set_pol_1(get(name));"
           ondown="ondown_pl_hs();"
           blendmode="normal"
       />
  
       <!-- polygonal hotspot -->
  
   <hotspot name="hs_polig_4" style="pl_hs_add_hs" alpha="NaN" fillcolor="0x1500FF" fillalpha="NaN" borderwidth="NaN" bordercolor="0x55FF00" borderalpha="NaN"
             title="hs_polig_4" scene_EL="scene_1-2" alpha2="0"
             onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="75.334" atv="10.421" />
             <point ath="76.748" atv="-14.456" />
             <point ath="104.011" atv="-11.476" />
             <point ath="104.112" atv="10.087" />
   </hotspot>
  
   <hotspot name="hs_polig_2" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_2" scene_EL="scene_1-1" alpha2="0"
             onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="68.708" atv="28.605" />
             <point ath="70.152" atv="-18.193" />
             <point ath="137.552" atv="-17.26" />
             <point ath="131.773" atv="24.241" />
   </hotspot>
  
   <hotspot name="hs_polig_1" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_1" scene_EL="scene_1-1" alpha2="0"
             onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="151.858" atv="20.061" />
             <point ath="150.246" atv="-15.974" />
             <point ath="-151.852" atv="-15.662" />
             <point ath="-154.138" atv="18.791" />
   </hotspot>
  
   <hotspot name="hs_polig_3" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_3" scene_EL="scene_1-1" alpha2="0"
             onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="-129.239" atv="24.301" />
             <point ath="-129.294" atv="-21.182" />
             <point ath="-75.646" atv="-16.517" />
             <point ath="-75.18" atv="23.649" />
   </hotspot>
  
   <hotspot name="hs_polig_5" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_5" scene_EL="scene_1-2" alpha2="0"
             onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="142.629" atv="-13.224" />
             <point ath="140.586" atv="13.259" />
             <point ath="-169.289" atv="17.533" />
             <point ath="-170.701" atv="-18.684" />
   </hotspot>
  
   <hotspot name="hs_polig_6" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_6" scene_EL="scene_1-2" alpha2="0"
             onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="-17.745" atv="31.882" />
             <point ath="-14.305" atv="-22.421" />
             <point ath="55.468" atv="-14.035" />
             <point ath="56.653" atv="14.341" />
   </hotspot>
  
   <hotspot name="hs_polig_7" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_7" scene_EL="scene_1-4" alpha2="0"
             onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="-67.68" atv="21.234" />
             <point ath="-66.305" atv="-8.502" />
             <point ath="-35.901" atv="-7.183" />
             <point ath="-34.475" atv="14.037" />
   </hotspot>
  
   <hotspot name="hs_polig_8" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_8" scene_EL="scene_1-4" alpha2="0"
             onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="-21.624" atv="14.061" />
             <point ath="-20.841" atv="-14.059" />
             <point ath="20.434" atv="-11.98" />
             <point ath="21.062" atv="13.413" />
   </hotspot>
  
   <hotspot name="hs_polig_9" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_9" scene_EL="scene_1-4" alpha2="0"
             onclick="loadscene(scene_1-3,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="69.661" atv="-16.693" />
             <point ath="69.728" atv="20.19" />
             <point ath="32.849" atv="16.152" />
             <point ath="35.075" atv="-15.332" />
   </hotspot>
  
   <hotspot name="hs_polig_10" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_10" scene_EL="scene_1-3" alpha2="0"
             onclick="loadscene(scene_1-1,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="-57.698" atv="16.12" />
             <point ath="-56.587" atv="-18.097" />
             <point ath="16.471" atv="-26.289" />
             <point ath="19.147" atv="24.687" />
   </hotspot>
  
   <hotspot name="hs_polig_11" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_11" scene_EL="scene_1-3" alpha2="0"
             onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="62.914" atv="21.055" />
             <point ath="64.031" atv="-21.922" />
             <point ath="115.604" atv="-19.853" />
             <point ath="116.232" atv="20.757" />
   </hotspot>
  
   <hotspot name="hs_polig_12" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_12" scene_EL="scene_1-3" alpha2="0"
             onclick="loadscene(scene_1-4,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="-137.836" atv="12.402" />
             <point ath="-139.196" atv="-10.098" />
             <point ath="167.673" atv="-15.144" />
             <point ath="165.968" atv="18.628" />
   </hotspot>
  
   <hotspot name="hs_polig_14" style="pl_hs_add_hs" alpha="0"
             title="hs_polig_14" scene_EL="scene_1-3" alpha2="0"
             onclick="loadscene(scene_1-2,null,MERGE|KEEPVIEW,BLEND(0,5))"
          >
             <point ath="-105.25" atv="-11.428" />
             <point ath="-72.466" atv="-9.826" />
             <point ath="-72.342" atv="12.843" />
             <point ath="-105.061" atv="13.389" />
   </hotspot>
  
  
   
  </krpano>
      `
  }
}
export default addXmlHotspots

export const paramsByType = 
    {
        classic: [{ param: 'ath="0.6859" atv="-9.3528" width="prop" height="100" rx="-9.54" scale="5.338" edge="center"', hotspot: 1, scene: '1'},
                { param: 'ath="106.3758" atv="-3.4716" width="prop" height="100" rx="-2.48" ry="-13.22" scale="1.809" edge="center"', hotspot: 2, scene: '1'},
                { param: 'ath="-106.3476" atv="-3.5069" width="prop" height="100" rx="-2.48" ry="13.22" scale="1.809" edge="center"', hotspot:3, scene: '1'},
                { param: 'ath="179.7827" atv="-2.442" width="prop" height="100" rx="-4.12" scale="1.5" edge="center"', hotspot: 4, scene: '1'},
                { param: 'ath="-89.9138" atv="-6.7474" width="prop" height="100" rx="-7.52" scale="3.11" edge="center"', hotspot: 5, scene: '2'},
                { param: 'ath="161.8004" atv="-2.3515" width="prop" height="100" rx="-1.92" ry="18.96" scale="1.703" edge="center"', hotspot: 6, scene: '2'},
                { param: 'ath="89.9612" atv="-2.9619" width="prop" height="100" rx="-5.92" scale="1.414" edge="center"', hotspot: 7, scene: '2'},
                { param: 'ath="26.9454" atv="-4.2588" width="prop" height="100" rx="-3.3" ry="-25.38" scale="2.796" edge="center"', hotspot: 8, scene: '2'},
                { param: 'ath="-26.4631" atv="-3.6848" width="prop" height="100" rx="-6.04" ry="32.04" scale="2.664" edge="center"', hotspot: 9, scene: '3'},
                { param: 'ath="90.0318" atv="-6.7657" width="prop" height="100" rx="-6.26" scale="3.134" edge="center"', hotspot: 10, scene: '3'},
                { param: 'ath="-89.9271" atv="-3.0588" width="prop" height="100" rx="-4.96" scale="1.409" edge="center"', hotspot: 11, scene: '3'},
                { param: 'ath="-162.9223" atv="-2.1879" width="prop" height="100" rx="-2.16" ry="-16.22" scale="1.696" edge="center"', hotspot: 12, scene: '3'},
                { param: 'ath="179.8783" atv="-11.6358" width="prop" height="100" rx="-12.48" scale="5.499" edge="center"', hotspot: 13, scene: '4'},
                { param: 'ath="51.6001" atv="-2.1976" width="prop" height="100" rx="-2.3" ry="36.26" scale="1.342" edge="center"', hotspot: 14, scene: '4'},
                { param: 'ath="0.2537" atv="-1.8914" width="prop" height="100" rx="-6.56" scale="1.444" edge="center"', hotspot: 15, scene: '4'},
                { param: 'ath="-51.5436" atv="-2.3047" width="prop" height="100" rx="-2.3" ry="-36.26" scale="1.342" edge="center"', hotspot: 16, scene: '4' }],

         loft: [{ param: 'ath="-89.6973" atv="-15.5726" width="prop" height="100" rx="-14.18" scale="7.489" edge="center"', hotspot: 3, scene: '3'},
                { param: 'ath="179.6298" atv="-4.5342" width="prop" height="350" rx="-8.2" scale="0.46" edge="center"', hotspot: 4, scene: '1'},  
                { param: 'ath="-179.9665" atv="-15.711" width="prop" height="100" rx="-11.36" ry="-0.04" scale="7.885" edge="center"', hotspot: 5, scene: '4'},
                { param: 'ath="-90.7623" atv="-2.2722" width="prop" height="250" rx="-4.88" scale="0.513" edge="center"', hotspot: 10, scene: '2'},
                { param: 'ath="-36.4109" atv="-3.7151" width="prop" height="350" rx="-4.22" ry="38.46" scale="0.7" edge="center"', hotspot: 11, scene: '2'},   
                { param: 'ath="-0.632" atv="-2.4269" width="prop" height="100" rx="0.22" scale="1.349" edge="center"', hotspot: 14, scene: '4'},
                { param: 'ath="44.9039" atv="-3.4851" width="prop" height="100" rx="-3.08" ry="45.26" scale="1.71" edge="center"', hotspot: 15, scene: '4'},               
                { param: 'ath="107.7347" atv="-7.1852" width="prop" height="331" rx="-6.02" ry="-16.58" scale="0.777" edge="center"', hotspot: 16, scene: '1'}, 
                { param: 'ath="-106.7491" atv="-4.7595" width="prop" height="331" rx="-3.68" ry="16.56" scale="0.663" edge="center"', hotspot: 17, scene: '1'},
                { param: 'ath="-154.881" atv="-3.8183" width="prop" height="250" rx="-2.26" ry="-21.06" scale="0.64" edge="center"', hotspot: 18, scene: '2'},
                { param: 'ath="45.7646" atv="-4.6383" width="prop" height="350" rx="-4.46" ry="-46.68" scale="0.707" edge="center"', hotspot: 19, scene: '3'},
                { param: 'ath="-0.2324" atv="-12.3158" width="prop" height="331" rx="-8.26" scale="2.1" edge="center"', hotspot: 21, scene: '1'},
                { param: 'ath="90.4106" atv="-15.9996" width="prop" height="350" rx="-11.68" ry="0.28" scale="1.954" edge="center"', hotspot: 22, scene: '2'},
                { param: 'ath="91.6447" atv="-4.6864" width="prop" height="350" rx="-7.28" scale="0.456" edge="center"', hotspot: 23, scene: '3'},
                { param: 'ath="151.922" atv="-4.4028" width="prop" height="350" rx="-7.28" ry="25.66" scale="0.502" edge="center"', hotspot: 24, scene: '3'},
                { param: 'ath="-45.9531" atv="-3.2433" width="prop" height="100" rx="-3.08" ry="-47.08" scale="1.71" edge="center"', hotspot: 25, scene: '4'}],
         minimalism: [
                  { param: 'ath="1.7785" atv="-8.858" width="prop" height="100" rx="-9.54" scale="5" edge="center"', hotspot: 1, scene: '1'},
                  { param: 'ath="101.4792" atv="-0.851" width="prop" height="100" rx="-2.48" ry="-13.22" scale="1.9" edge="center"', hotspot: 2, scene: '1'},
                  { param: 'ath="-100.9743" atv="-3.3197" width="prop" height="100" rx="-2.48" ry="13.22" scale="2.2" edge="center"', hotspot: 3, scene: '1'},
                  { param: 'ath="179.7827" atv="-2.442" width="prop" height="100" rx="-4.12" scale="1.5" edge="center"', hotspot:4, scene: '1'},
                  { param: 'ath="-88.7849" atv="-3.8303" width="prop" height="100" rx="-7.52" scale="1.51" edge="center"', hotspot: 5, scene: '2'},
                  { param: 'ath="-156.7619" atv="-6.7826" width="prop" height="100" rx="-10" ry="-31" scale="2.703" edge="center"', hotspot:6, scene: '2'},
                  { param: 'ath="90.789" atv="-1.2611" width="prop" height="100" rx="-5.92" scale="4" edge="center"', hotspot: 7, scene: '2'},
                  { param: 'ath="-25.2041" atv="-2.3584" width="prop" height="100" ry="23" scale="2.796" edge="center" depth="2000"', hotspot: 8, scene: '2'},
                  { param: 'ath="87.6339" atv="-2.3754" width="prop" height="100" rx="-6.26" scale="3.134" edge="center"', hotspot: 10, scene: '3'}, 
                  { param: 'ath="-88.7688" atv="-4.7805" width="prop" height="100" rx="-4.96" scale="4" edge="center"', hotspot: 11, scene: '3'},
                  { param: 'ath="163.7979" atv="-7.9185" width="prop" height="100" rx="-6.16" ry="12.22" scale="2.696" edge="center"', hotspot: 12, scene: '3'}, 
                  { param: 'ath="179.8783" atv="-11.6358" width="prop" height="100" rx="-12.48" scale="5.499" edge="center"', hotspot: 13, scene: '4'},
                  { param: 'ath="56.206" atv="-0.2499" width="prop" height="100" rx="-2.3" ry="36.26" scale="1.342" edge="center"', hotspot: 14, scene: '4'},
                  { param: 'ath="0.2537" atv="-1.8914" width="prop" height="100" rx="-6.56" scale="1.444" edge="center"', hotspot: 15, scene: '4'},
                  { param: 'ath="-60.1337" atv="-1.8784" width="prop" height="100" rx="-2.3" ry="-36.26" scale="1.9" edge="center"', hotspot: 16, scene: '4'},
                  { param: 'ath="22.3933" atv="-7.8823" width="prop" height="100" rx="-4.96" ry="-20" scale="4" edge="center"', hotspot: 17, scene: '3'}]
                
    }
