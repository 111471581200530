import '../../pages/Catalog/catalog.sass'

import {observer} from "mobx-react";
import {useInjection} from "inversify-react";

import { useEffect, useState } from 'react';
import { UserStore } from '../../stores/user/UserStore';
import CatalogItem from '../../pages/Catalog/catalogItem';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from "react-click-away-listener";


const OneCollection = observer(({user_id}) => {
    const userStore = useInjection(UserStore)
    const location = useLocation();
    const {t} = useTranslation()
    const [editor, setEditor] = useState<string>('')
    const [editableTitle, setEditableTitle] = useState<string>('')
    const [editableDesc, setEditableDesc] = useState<string>('')
    const [isMe, setIsMe] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [debounced, setDebouced] = useState('')
	const [timeout , updateTimeout] = useState(undefined)
    useEffect(()=>{

            userStore.getOneCollection(location.pathname.split('/').pop()).then(()=>{
                console.log('then collection',userStore.oneCollection)
                if(userStore.oneCollection?.bundles?.length===0){
                    setEmpty(true)
                }
                setEditableDesc(userStore.oneCollection?.description)
                setEditableTitle (userStore.oneCollection?.name)
            })
        
    },[])
    useEffect(()=>{
        if(user_id===userStore?.user?.user_id&&user_id) {
            setIsMe(true)
        }
    },[user_id])
    const editCollectionTitle = (e)=> {
        if(e.target.value!==''){
            setDebouced(e.target.value)
		}
        setEditableTitle(e.target.value)
        // userStore.editCollection(e.target.value, 'name',userStore.oneCollection?._id)
    }
    const editCollectionDescription = (e)=> {
        if(e.target.value!==''){
            setDebouced(e.target.value)
		}
        setEditableDesc(e.target.value)
        // userStore.editCollection(e.target.value, 'description', userStore.oneCollection?._id)
    }
    const startEdit =(key)=>{
        setEditor(key)
        if(key==='description') {
            
            setEditableDesc (userStore.oneCollection?.description!==editableDesc?
                editableDesc:
                userStore.oneCollection?.description)
        }
        else if(key==='name') {
            setEditableTitle(userStore.oneCollection?.name!==editableTitle?
                editableTitle:
                userStore.oneCollection?.name)
        }
    }
    const debounce =(fn,ms)=>{
		const huy=()=> {
				clearTimeout(timeout)
				updateTimeout(setTimeout(fn, ms)) 
		}
		return huy()
	}
    const onTextChange =()=>{
		let value = debounced
        console.log(value)
	    userStore.editCollection(value, editor, userStore.oneCollection?._id)
	};
	useEffect(()=>{
		if(debounced!==''){
			debounce(onTextChange,500)
		}
	},[debounced])
    return (
        <div>
            <div  style={{height:'70px', width:'100%', display:'flex',alignItems:'center'}}>
                {editor!=='name'?
                <div style={{ display:'flex',alignItems:'center'}}>
                    <div style={{fontSize:'36px',lineHeight:'40px',marginBottom:'15px'}}>{editableTitle}</div>
                    {(isMe&&empty)&&<img src={require("../../images/edit1.jpg")}style={{width:'16px',marginLeft:'15px',cursor:'pointer'}} onClick={()=>startEdit('name')}></img>}
                </div>:
                <ClickAwayListener onClickAway={() => setEditor('')}>
                    <input value={editableTitle}style={{ fontSize:'36px',width:'80%'}}  onChange={editCollectionTitle} onKeyDown={(e)=>e.key==='Enter'?setEditor(''):''}></input>
                </ClickAwayListener> 
                }
                
            </div>
            <div  style={{ width:'100%', display:'flex',alignItems:'center',marginBottom:'30px'}}>
                {editor!=='description'?
                <div  style={{ display:'flex',alignItems:'center'}}>
                    <p style={{marginBottom:'0px'}}>{editableDesc}</p>
                    {(isMe)&&<img src={require("../../images/edit1.jpg")}style={{width:'16px',marginLeft:'15px',cursor:'pointer'}} onClick={()=>startEdit('description')}></img>}
                </div>:
                <ClickAwayListener onClickAway={() => setEditor('')}>
                     <textarea value={editableDesc}  style={{width:'100%',resize:'none',height:"120px"}} onChange={editCollectionDescription} onKeyDown={(e)=>e.key==='Enter'?setEditor(''):''}></textarea>
                </ClickAwayListener>
               
                }

                
            </div>
            <div className='ct-contentGrid'>
                {userStore.oneCollection?.tokens?.length>0 ? userStore.oneCollection.tokens.map((el,i)=>{
                        return(
                            <CatalogItem key={'cat-item'+i} el={el} isAuc={i%2===0?true:false}/>
                        )
                        }
                ): 
                        <div>{t('collections::noNft')}</div>
                }
            </div>
        </div>
    )
})

export default OneCollection