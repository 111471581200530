import {observer} from "mobx-react";
// import './profileComponents.sass'
import { useState } from "react";
import { Table } from "../../shared/tables/tables";
import styled from "styled-components";
import { ButtonText } from "../../shared/buttons/buttons";
import { getDateAuc } from "../../pages/NFT";
import { web3 } from "../../constants";
import { url } from "../auth/helper";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { useTranslation } from "react-i18next";

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;

& button {
    color: #894ADA;
    margin: 30px 0 0 0;
    font-family: Releway-semiBold;
    font-size: 18px;
}
`
const Bid = styled.p`
margin: 0;
color: #959EA7;
font-family: Releway-medium;
font-size: 14px;
text-align: left;
`
const Username = styled.p`
margin: 0;
color: #894ADA;
font-family: Releway-medium;
font-size: 16px;
text-align: left;
line-height: 1em;

`
const Time = styled.p`
margin: 0;
color: #959EA7;
font-family: Releway-medium;
font-size: 14px;
text-align: center;
`
const Price = styled.p`
margin: 0;
color: black;
font-family: Releway-semiBold;
font-size: 22px;
line-height: 1em;
text-align: right;
`
const Сurrency = styled.p`
margin: 0;
color: #959EA7;
font-family: Releway-medium;
font-size: 14px;
text-align: right;
`
export const BidHistory = observer(({allBids}) => {
    const userStore = useInjection(UserStore)
    const [ammount, setAmmount] = useState(3)
    const {t} = useTranslation()
    const getMinutes = (bidTime) =>{
        let passed = (Date.now() - Date.parse(bidTime))/60000
        if(passed<60&&Math.trunc(passed)!==0){
            return Math.trunc(passed) +" минут"
        } 
        else if(Math.trunc(passed)===0) {
            return t('nft::now')
        } 
        else return getDateAuc(bidTime)
    }
    return (
        <Container>
        <Table >
            <tbody>
            {allBids&&allBids?.length>0  ? allBids?.map((el,i)=>{
                if(i<ammount&&allBids?.length>0){
                    return(
                        <tr key={i}>
                            <td style={{width:'1px', padding: '0 10px 0 0'}}>
                                <img src={url+'/'+ el.user?.photo} />
                            </td>
                            <td>
                                <Bid>{t('nft::bet')}</Bid>
                                <Username>{el.user?.fullname}</Username>
                            </td>
                            <td>
                                <Time>{getMinutes(el.date)}</Time>
                            </td>
                            <td>
                                <Price>{el.bid && web3.utils.fromWei(el?.bid?.toString(), "ether" )}&ensp;BNB</Price>
                                <Сurrency>≈ ₽ {userStore.bnb&&(userStore.bnb*parseFloat((web3.utils.fromWei(el?.bid?.toString(), "ether" )))).toFixed(2)}</Сurrency>
                            </td>
                        </tr>
                    )
                }
                  
            })
            :<tr  style={{border:'none'}}><td>
                <Price style={{textAlign:'left', marginLeft:'6px', marginTop:'17px'}}>{t('nft::nobet')}</Price>
            </td></tr>}
            </tbody>         
        </Table>
                    <ButtonText style={{display: allBids?.length<=ammount||allBids?.length<4||!allBids?'none':'block'}} onClick={()=>setAmmount(ammount+3)}>{t('nft::more')}</ButtonText>

        </Container>
       
    )
})

export const SellHistory = observer(({allSells}) => {
    const userStore = useInjection(UserStore)
    const [ammount, setAmmount] = useState(3)
    const {t} = useTranslation()
    const getMinutes = (bidTime) =>{
        let passed = (Date.now() - Date.parse(bidTime))/60000
        if(passed<60&&Math.trunc(passed)!==0){
            return Math.trunc(passed) +" минут"
        } 
        else if(Math.trunc(passed)===0) {
            return "сейчас"
        } 
        else return getDateAuc(bidTime)
    }
    return (
    //     "more":"More...",
    // "nobuy":"No purchases yet",
    // "nobet":"No bets yet",
    // "seller":"Seller",
    // "buyer":"Buyer"
        <Container>
        <Table >
        <tbody>
            {allSells&&allSells?.length>0 ? allSells?.map((el,i)=>{
                if(i<ammount&&allSells?.length>0){
                    return(
                        <tr key={i}>
                            <td style={{width:'1px', padding: '0 10px 0 0'}}>
                                <img src={url+'/'+ el.to?.photo} />
                            </td>
                            <td>
                                <Bid>{t('nft::buyer')}</Bid>
                                <Username>{el.to?.fullname}</Username>
                            </td>
                            <td style={{width:'1px', padding: '0 10px 0 10px'}}>
                                <img src={url+'/'+ el.from?.photo} />
                            </td>
                            <td>
                                <Bid>{t('nft::seller')}</Bid>
                                <Username>{el.from?.fullname}</Username>
                            </td>
                            <td>
                                <Time>{getMinutes(el.date)}</Time>
                            </td>
                            <td>
                                <Price>{el?.price && web3.utils.fromWei(el?.price?.toString(), "ether" )}&ensp;BNB</Price>
                                <Сurrency>≈ ₽ {el?.price && userStore.bnb&&(userStore.bnb*parseFloat((web3.utils.fromWei(el?.price?.toString(), "ether" )))).toFixed(2)}</Сurrency>
                            </td>
                        </tr>
                    )
                }
                  
            })
            :<tr style={{border:'none'}}><td>
            <Price style={{textAlign:'left', marginLeft:'6px', marginTop:'17px'}}>{t('nft::nobuy')}</Price>
        </td></tr>}  
        </tbody>       
        </Table>
                    <ButtonText style={{display: allSells?.length<=ammount||allSells?.length<4||!allSells?'none':'block'}} onClick={()=>setAmmount(ammount+3)}>{t('nft::more')}</ButtonText>

        </Container>
       
    )
})