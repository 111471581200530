import { observer } from "mobx-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InWork, { Hidden, Ipfs } from "../../../components/tables/inwork";
import { Description } from "..";
import { ButtonText } from "../../../shared/buttons/buttons";
import styled from 'styled-components'
import {BidHistory, SellHistory} from "../../../components/tables/sellHistory";
import Analytics from "../../../components/profile/analytics";

export const SwitchMenu = styled.div`

    & button {
        font-family: Releway-semiBold;
        font-size: 18px;
        margin-right: 18px;
        border-bottom: 2px solid transparent;
        &:hover {
            border-color: #894ADA;
        }
    }
`

export const BottomMenu = observer(({ hidden, isAuc, oneNFT, allBids, allSells, isOpen }) => {
    const {t} = useTranslation()
    // console.log(allSells)
  const [swithMenuState, setSwithMenuState] = useState(0);
  return (
    <div>
      <SwitchMenu>
        {isAuc ? (
          <ButtonText onClick={() => setSwithMenuState(0)} style={{ borderColor: swithMenuState === 0 ? "#894ADA" : "" }}>{t("nft::btnBetHistory")}</ButtonText>
        ) : (
          <ButtonText onClick={() => setSwithMenuState(0)} style={{ borderColor: swithMenuState === 0 ? "#894ADA" : "" }}>{t("nft::btnPurchaseHistory")}</ButtonText>
        )}
        <ButtonText onClick={() => setSwithMenuState(1)} style={{ borderColor: swithMenuState === 1 ? "#894ADA" : "" }}>{t("nft::btnAnalytic")} </ButtonText>
        {/* <ButtonText onClick={() => setSwithMenuState(2)} style={{ borderColor: swithMenuState === 2 ? "#894ADA" : "" }}>{t("nft::btnIPFS")}</ButtonText> */}
        {hidden && (<ButtonText onClick={() => setSwithMenuState(3)} style={{ borderColor: swithMenuState === 3 ? "#894ADA" : "" }}> {t("nft::btnHidenCont")}
</ButtonText>
        )}
      </SwitchMenu>
      {swithMenuState === 0 && isAuc && isOpen? (
        <BidHistory allBids={allBids?.slice().reverse()}/>
      ):
      swithMenuState === 0 &&  !isOpen? (
        <SellHistory allSells={allSells?.slice().reverse()}/>
      ):''
    
    }
      {swithMenuState === 1 && <Analytics allSalles={allSells}/>}
      {swithMenuState === 2 && (
        <Ipfs ipfs={"https://ipfs.digarty.com/ipfs/" +oneNFT?.file} />
      )}
      {swithMenuState === 3 && (
        <Hidden isFirst={hidden} id={oneNFT?._id} />
      )}
      <Description className="ntf-mobile-description">
        <h2>{t("nft::about")}</h2>
        {oneNFT?.about}
      </Description>
    </div>
  );
});
