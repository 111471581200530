import {observer} from "mobx-react";
import './profileComponents.sass'
import CatalogItem from "../../pages/Catalog/catalogItem";
import { Table } from "../../shared/tables/tables";
import styled from "styled-components";
import SearchBar from "./searchBar";
import { ButtonLight } from "../../shared/buttons/buttons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";
import { useHistory } from "react-router";


const NothingHere = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;

align-items: flex-start;
justify-content:center;
border:1px solid rgba(211, 211, 211, 1);
border-radius: 8px;

h1 {
    font-size:32px
}


`
const Text = styled.div`
padding:28px;
display: flex;
flex-direction: column;
align-items: flex-start;
margin-top:50px;
p {
    text-align: left;
    max-width:365px
}
`
const MyNFT = observer(({user}) => {
    const [ammount, setAmmount] = useState(4)
    const {t} = useTranslation()
    const userStore = useInjection(UserStore)
    const history = useHistory()

    useEffect(()=>{
       user && userStore.getOwnedNFT(user?._id)
    },[user])

    return (
        <SmoothAppearance trigger={userStore.ownedNFT}>
        <div className='lk-myNft'>
           <SearchBar owned user_id={user?._id} />
            {userStore.ownedNFT?.length>0?
            <div className='mynft-container'>
                {userStore.ownedNFT?.map((el,i)=>{

                    if(i<ammount){
                        return(
                            <CatalogItem key={'my-nft'+i} el={el} isAuc={true} />
                        )
                    }
                })}
            </div>:
            <NothingHere style={{display:userStore.user?._id===user?._id?'flex':'none'}}>
                <Text>
                    <h1>{t('pMyNft::nothing')}</h1>
                    <p>{t('pMyNft::uCanBuyNft')}</p>
                    <ButtonLight style={{width:'70%',minWidth:'180px'}}onClick={()=>history.push("../../../../p/catalog")}>{t('button::toCatalog')}</ButtonLight>
                </Text>
                
                <img src={require('../../images/looking-out.svg')} style={{padding:'28px'}}/>
            </NothingHere>
            }

        
            {ammount<user?.works?.length && <div style={{display:'flex', justifyContent:'center', margin: '20px 0 0'}}>
                <ButtonLight  width='200px' onClick={()=>setAmmount(ammount+4)}>{t('button::showMore')}</ButtonLight>
            </div>}
          
        </div>
        </SmoothAppearance>
    )
})


export default  MyNFT