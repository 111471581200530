import PrivatePages from "../../../components/auth/auth";
import {observer} from "mobx-react";
import '../profile.sass'
import {useEffect, useState} from "react";
import PersonalData from "../../../components/profile/personalData";
import MyWallet from "../../../components/profile/myWallet";
import SellingHistory from "../../../components/profile/sellingHistory";
import Referals from "../../../components/profile/referals";
import Analytics from "../../../components/profile/analytics";
import { ButtonLight } from "../../../shared/buttons/buttons";
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import MyNFT from "../../../components/profile/myNFT";
import {NavLink, Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { DropdownMenu } from "../../../shared/buttons/dropdownMenu";
import CreateNFT from "../nft/create";
import BeAnArtist from "./beAnArtist";
import CreatedNFT from "../../../components/profile/created";
import CollectionsNFT from "../../../components/profile/collections";
import { UsersStore } from "../../../stores/users/UsersStore";
import Selling from "../../../components/profile/selling";
import OneCollection from "../../../components/profile/oneCollection";
import WishList from "../../../components/profile/wishlist";
import Blog from "../../../components/profile/blog";
import Post from "../../../components/profile/post";
import GalleryPage from "../../../components/profile/3d";
import GalleryCreate from "../../../components/profile/create3d";


export const Path = process.env.REACT_APP_IP;


const Container = styled.div`

@media (max-width: 1000px) {
    flex-direction: column;
    & .desktop{
        display: none;
    }
    & .mobile{
        display: block;
    }
}
@media (min-width: 1001px) {
    & .desktop{
        display: block;
    }
    & .mobile{
        display: none;
    }
}
`

const Dashboard = observer(({match}) => {
    const userStore = useInjection(UserStore)
    const location = useLocation();
    const [auth, setAuth] = useState(true);

    useEffect(() => {
   
        setTimeout(() => setAuth(userStore.isAuth), 500)
    }, [location])

    if(!auth){
            return <Redirect to={'/p/auth'} />
        }
    return (
        // <PrivatePages>
                <Container className='mainBlock'>
                    <Switch>
                        <Route exact path={`${match.path}/profile`} component={PersonalData} />
                        <Route exact path={`${match.path}/wallet`} component={MyWallet} />
                        <Route exact path={`${match.path}/referals`} component={Referals} />
                        <Route exact path={`${match.path}/analytics`} component={Analytics} />
                        <Route exact path={`${match.path}/`}>
                            <MyNFT user={userStore.user}/>
                        </Route>
                        <Route exact path={`${match.path}/created`}>
                            <CreatedNFT user={userStore.user} isSold={false}/>
                        </Route>
                        <Route exact path={`${match.path}/collections`}>
                            <CollectionsNFT user={userStore.user} match={match}/>
                        </Route>
                        <Route exact path={`${match.path}/collections/:id`}>
                            <OneCollection user_id={userStore.user?.user_id}/>
                        </Route>
                        <Route exact path={`${match.path}/wishlist`} component={WishList} />
                        <Route exact path={`${match.path}/blog`}  component={Blog}  >
                            <Blog _id={userStore.user?._id} user_id={userStore.user?.user_id}/>
                        </Route>
                        <Route exact path={`${match.path}/blog/:id`}  component={Post}  >
                            <Post  user_id={userStore.user?.user_id}></Post>
                        </Route>
                        <Route exact path={`${match.path}/3d`}  component={GalleryPage}  >
                            <GalleryPage user={userStore.user} match={match} myPage={true}/>
                        </Route>
                        <Route exact path={`${match.path}/3d/create`}  component={GalleryCreate}  >
                            <GalleryCreate _id={userStore.user?._id}/>
                        </Route>
                        <Route exact path={`${match.path}/sold`}>
                            <CreatedNFT user={userStore.user} isSold={true}/>
                        </Route>
                        <Route exact path={`${match.path}/selling`} component={Selling} />
                        <Route exact path={`${match.path}/create_NFT`} component={CreateNFT} />
                        <Route exact path={`${match.path}/become_artist`} component={BeAnArtist} />
                    </Switch>
                   
                </Container>
        // </PrivatePages>
    )
})


export default  Dashboard




        {/* <Mobile className='mobile'>
                        <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', borderBottom: '2px solid #F2E9EA', marginBottom:'20px'}}>
                            <div className='lk-title' style={{borderColor:'transparent', margin:0, padding:0}}>Личный кабинет</div>
                            <div style={{position:'relative', }}
                                onMouseEnter={()=>setMobSettings(true)}
                                onMouseLeave={()=>setMobSettings(false)}
                            >
                                    <img src={require('../../../images/settings.png')} style={{width: '30px', height:'30px'}} />
                                    {mobSettings&&
                                    <DropdownMenu style={{ right:'0px',top:'30px'}}>
                                        <CopyToClipboard text={`${userStore.user?.public_key}`}>
                                            <a onClick={linkToClipboard}>Скопировать кошелек</a>
                                        </CopyToClipboard>
                                        <CopyToClipboard text={`${window.location.origin}/?ref=${userStore.user?.referal_code}`}>
                                            <a onClick={linkToClipboard}>Скопировать реферальную ссылку</a>
                                        </CopyToClipboard>
                                    </DropdownMenu>}
                            </div>
                        </div>
                        
                        <div className='mob-btn-container' onMouseEnter={()=>setMobDropdown(true)} onMouseLeave={()=>setMobDropdown(false)}>
                            <button style={{fontFamily: 'Releway-bold'}}>
                                Меню
                                <Chevron/>
                            </button>
                            {mobDropdown && 
                            <DropdownMenu style={{top: '45px'}}>
                                <NavLink activeStyle={navActive} to={`${match.path}/profile`}>Личные данные</NavLink>
                                <NavLink activeStyle={navActive} to={`${match.path}/wallet`}>Мой кошелек</NavLink>
                                <NavLink activeStyle={navActive} to={`${match.path}/referals`}>Рефералы</NavLink>
                                {userStore.user?.user_type==='artist'&&
                                <NavLink activeStyle={navActive} to={`${match.path}/analytics`}>Аналитика</NavLink>}
                                <NavLink activeStyle={navActive} to={`${match.path}/nft`}>Мои NFT</NavLink>
                            </DropdownMenu>}
                        </div>
             
                    </Mobile>*/}