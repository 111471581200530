
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {getDateNumbers} from '../date/getDate';



const Chart =({dates,prices, actors})=> {
  const {t} =useTranslation()
  const data = {
    labels: dates.map((el)=>{
      return getDateNumbers(el, false)
    }),
    extended: dates,
    datasets: [
      {
        data: prices,
        fill: true,
        backgroundColor:"#F3EDFB",
        pointBorderColor:"#894ADA",
        borderColor: '#894ADA',
        borderWidth: 5,
        pointBorderWidth:6,
        pointRadius: 4,
        tension: 0.1
      },
   
    ],
    
  };
  
  const options = {
    
    plugins:{
      legend:{display:false},
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (el)=> {
            return getDateNumbers(dates[el[0].dataIndex], true) 
          },
          beforeLabel: (el)=>{
            return actors[el.dataIndex].from +' > '+actors[el.dataIndex].to
            
          },
          label: (el)=>{
   
            return t("nft::price")+ el.raw + ' BNB' 
          },
          afterLabel: (el)=>{
            
            if(el.dataIndex!==0) {
              let currentChange = el.dataset.data[el.dataIndex]-(el.dataset.data[el.dataIndex-1])
              return t("nft::change")+ (currentChange>0?'+':'')+ (currentChange.toFixed(5))+ ' BNB'
            }
            else return
          },
          
          afterBody: (el)=>{  
            if(el[0].dataIndex===0) {
              return t("nft::sum")+el[0].formattedValue + ' BNB'
            }
            else {
              let x:number;
              let currentArray = el[0].dataset.data.slice(0, el[0].dataIndex+1)
              currentArray.map(i=>x+=i, x=0).reverse()[0]
              return t("nft::sum")+  x.toFixed(5) + ' BNB'
            }
            
          }
        }
      },
    
    },
    
    scales: {
      y:{
        ticks:{
          color:"black",
          font:{
            size:12
          }
        },
        grid:{
          color:"#F3EDFB"
        }
      },
      x:{
        ticks:{
          color:"black",
          font:{
            size:12
          }
        }
      }
      
    },
  };
  return (
    <div>
      <Line data={data} options={options}/>
    </div>
  );
}

export default Chart;