import { Provider } from "inversify-react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-datepicker/dist/react-datepicker.css";
import { Router, Route, Switch, useLocation, Redirect } from "react-router-dom";
import { RootStore } from "./stores/RootStore";
import ThemeContext from "./shared/theme/context";
import { ModalsContainer } from "./modals";
import Auth from "./pages/auth";
import Layout from "./shared/layout";
import News from "./pages/admin/News";
import Catalog from "./pages/Catalog";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./pages/profile";
import CreateNFT from "./pages/profile/nft/create";
import "./bootstrap/css/bootstrap.min.css";
import FAQ from "./pages/FAQ";
import Galleries3D from "./pages/3D";
import Gallery3D from "./pages/3D/oneGallery";
import Events from "./pages/Events";
import Dev from "./pages/development";
import "bootstrap/dist/css/bootstrap.min.css";
import Nft from "./pages/NFT";
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "./utils/getLibrary";
import PPolicy from "./pages/ppolicy";
import MainPage from "./pages/mainPage";
import Сontract from "./pages/contract";
import Partition from "./pages/Catalog/partition";
import Constructor from "./pages/constructor";

const RedirectFunction = () => {
  const location = useLocation();

  if (location.pathname == "/") {
    return <Redirect to={"/p/news"} />;
  }
  return <div></div>;
};

const App = () => {
  const rootStore = new RootStore();
  const container = rootStore.container;

  return (
    <Router history={rootStore.historyStore as any}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Provider container={container}>
          <ThemeContext>
            <Switch>
              <Layout>
                <Route path={"/"} component={RedirectFunction} />
                <Route exact path={`/p/main`} component={MainPage} />
                <Route exact path={`/p/news`} component={News} />
                <Route exact path={`/p/catalog`}>
                  <Catalog showDelayTokens={false} />
                </Route>
                <Route exact path={`/p/delay`}>
                  <Catalog showDelayTokens={true} />
                </Route>
                <Route exact path={`/p/catalog/:id`} component={Partition} />
                <Route exact path={`/p/events`} component={Events} />
                <Route exact path={`/p/development`} component={Dev} />
                <Route path={`/p/faq`} component={FAQ} />
                <Route path={`/p/3dgalleries`} exact component={Galleries3D} />
                <Route path={`/p/3dgalleries/:id`} component={Gallery3D} />
                <Route path={`/p/privacy`} component={PPolicy} />
                <Route path={`/p/contract`} component={Сontract} />
                <Route path={`/p/wallet`} component={Profile} />
                <Route path={"/p/auth"} component={Auth} />
                <Route path={"/p/profile"} component={Profile} />
                <Route exact path={"/p/profile/nft"} component={CreateNFT} />
                <Route exact path={"/p/nft/:id"} component={Nft} />
                <Route exact path={"/p/constructor"} component={Constructor} />
              </Layout>
            </Switch>
          </ThemeContext>
          <ModalsContainer />
          <ToastContainer />
        </Provider>
      </Web3ReactProvider>
    </Router>
  );
};

export default App;
