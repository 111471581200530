import {BG, ModalForm, NFTFormContainer, SubmitButton, TextInput} from "../../../shared/forms/forms";
import {useState} from "react";
import {useInjection} from "inversify-react";
import {UserStore} from "../../../stores/user/UserStore";
import {toast} from "react-toastify";
import {Redirect, useHistory} from "react-router";
import './stylesNFT.scss'
import './all.css'
import './styles.css'
import '../profile.sass'
import { observer } from "mobx-react";
import {NFTstore} from "../../../stores/NFTstore.";
import Web3 from "web3";
import Loader from "../../../shared/loader/loader";
import { isProd } from "../../../constants";
import {Extensions, ExtensionsModels, ExtensionsGif, ExtensionsImages, ContentCutter, ExtensionsVector, ExtensionsVideo, ExtensionsPanoVid, Extensions3DVid, Extensions3DModel, ExtensionsINTERACTIVE, ExtensionsPanoImg} from "./creation/extensions";
import { useEffect } from "react";
import Select from 'react-select'
// import "@types/react-select"
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { UsersStore } from "../../../stores/users/UsersStore";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";


const CreateNFT = observer(({close}:any) => {

  const refCoauthorSelect = useRef()
  const refCoauthorShare = useRef()
 const [byNow, setBynow] = useState(true)
 const [isAuction, setAuc] = useState(false)
 const [aboutCount, setAboutCount] = useState<number>(0)
const showBuynow = () => {
    setBynow(true)
    setAuc(false)
}
const showAuction = (e) => {
    setBynow(false)
    setAuc(true)
    setNFTdata ({
      ...nftData, amount:1 
    })
}

const userStore = useInjection(UserStore);
const usersStore = useInjection(UsersStore);
 const nftStore = useInjection(NFTstore)
    const history = useHistory();
  const {i18n, t} = useTranslation()
  const pre = useRef(null)
  const pre2 = useRef(null)
  const file = useRef(null)
  const file2 = useRef(null)
  const hidden = useRef(null)
  const hidden2 = useRef(null)
  
    // console.log(ExtensionsModels.file.WEBM, 'files here')
    const [startDate, setStartDate] = useState(new Date());
    const [nftType, setNFTtype] = useState<ContentCutter>(ExtensionsImages)
    // useEffect(()=>{
    //   console.log(nftType)
    // },[nftType])
    const [testFile, setTestFile] = useState({
        file: null,
        preview: null,
        hideContent: null
    })
   

    const [nftData, setNFTdata] = useState({
        title: '',
        about: '',
        price: '',
        category: '',
        coauthors: '',
        file: null,
        hidden: null,
        preview: null,
        hiddenContent: false,
        startPrice: undefined,
        fullPrice: null,
        date: null,
        amount: 1,
        tags:[],
        collections:[],
        rights:null,
        startDate:null,
        delay:false
    })
    const [loader, setLoader] = useState(false)

    const [coauthor, setCoauthors] = useState([])
    const [coauthorUser, setCoauthorUser] = useState<any>({})
    const [coauthorsShare, setCoauthorsShare] = useState(0)

useEffect(()=>{
  nftStore.getAllTags()

},[])
useEffect(()=>{console.log(nftData.amount)

},[nftData])
useEffect(()=>{
  userStore.getCollections('self')
},[userStore.user])


    const setCurrentType = (type, category) => {
      pre.current?pre.current.value = null:''
      pre2.current?pre2.current.value = null:''
      file.current?file.current.value = null:''
      file2.current?file2.current.value = null:''
      hidden.current?hidden.current.value = null:''
      hidden2.current?hidden2.current.value = null:''
      setNFTtype(type);
      setNFTdata({...nftData, category: category})
    }

    const onCostChange = (e) => {
        let web3 = new Web3(process.env.RPC_URL_97);
        setNFTdata({...nftData, [e.target.name]: web3.utils.toWei(e.target.value,"ether")})
    }
  
    const onChange = (e) => {
      if(e.target.name==='about') {
        setAboutCount(e.target.value.length)
      }
        setNFTdata({...nftData, [e.target.name]: e.target.value})
    }

//  coauthors   /////////////////
    const findCoauthors = (text) => {
      const myId = userStore.user?._id
      usersStore.getUsersQuery(text,myId)
    } 
    const findDebounce = debounce(findCoauthors, 300);

    const onCoauthor = (e) => {
      setCoauthorUser({id:e.value, name:e.label})
    }
    const onShare =(e) => {
      const share = e.target.value
      if(share<1) return setCoauthorsShare(1)
      if(share>5) return  setCoauthorsShare(5)
      setCoauthorsShare(share)
    }
    const addCoauthor = () => {
      if(coauthorUser.id?.length<2||!coauthorUser.id) return toast.warn(t('toasts::chooseCoauthor'))
      if(coauthorsShare<1||coauthorsShare>5) return toast.warn(t('toasts::chooseShare'))
      if(coauthor.some(item=>item.user===coauthorUser.id)) return toast.error(t('toasts::alreadyExists'))
      refCoauthorShare.current.value=''
      refCoauthorSelect.current.state.value=null
      setCoauthors([...coauthor, {user: coauthorUser.id, share: coauthorsShare, name:coauthorUser.name}])
    }
    const removeCoauthor = (i) => {
      const arr = Array.from(coauthor)
      arr.splice(i,1)
      setCoauthors(arr)
    }

    useEffect(()=>{
      if (coauthor.length>0){
        const arr = coauthor.map((el,i)=>{
          return {user:el.user, share:el.share/100}
        })
        const obj = JSON.stringify(arr)
        setNFTdata({...nftData, coauthors: obj})
      } else setNFTdata({...nftData, coauthors: null})
    },[coauthor])

    
// /////////////////



    const fileUpload = (e) => {
        e.preventDefault();
     
        if(e.target.files[0]){
            let ext = e.target.files[0].type;
            let correctSize = e.target.files[0].size<500000000;

            if(!nftType.file.some(el=>el===ext)){
              return toast.error(t('toasts::wrong'));
            }
            if(!correctSize){
              return toast.error(t('toasts::limit500'));
            }

            setNFTdata({...nftData, file: e.target.files[0]})
            toast.success(t('toasts::file'));
        }
    }
    const previewUpload = (e) => {
        console.log(e.target.files[0])
        e.preventDefault();
        // console.log(e.target.files)
        if(e.target.files[0]){
          let ext = e.target.files[0].type;
          let correctSize = e.target.files[0].size<5000000;

          if(!nftType.preview.some(el=>el===ext)){
            return toast.error(t('toasts::wrong'));
          }
          if(!correctSize){
            return toast.error(t('toasts::limit5'));
          }

          setNFTdata({...nftData, preview: e.target.files[0]})
          toast.success(t('toasts::file'));
      }
     
    }
    const hiddenUpload = (e) => {
      e.preventDefault();
   
      if(e.target.files[0]){
          let correctSize = e.target.files[0].size<100000000;

          if(!correctSize){
            return toast.error(t('toasts::limit100'));
          }
          setNFTdata({...nftData, hiddenContent: true, hidden: e.target.files[0]})
          toast.success(t('toasts::file'));
      }
  }


    const startDateChange = (date) => {
      const difference = Date.parse(date) - Date.now()

      if(difference<(-5000))return toast.error(t('toasts::incorrectStartDate'))

      setStartDate(date)
      setNFTdata({...nftData, delay:true, startDate:date})
    }

    const onSubmit = (e) => {

      
      const metamask = userStore.user?.metamask

      if(!metamask){
        toast.error(t('toasts::confirmWallet'))
        return
      }

        
        e.preventDefault();
        const formDataNFT = new FormData();
        for (const [key, value] of Object.entries(nftData)) {
            formDataNFT.append(key, value )
        }


        setLoader(true)
        nftStore.nftCreation(formDataNFT, isAuction)
            .then(res => {
                toast.success(t('toasts::created'))
                setLoader(false)
                setTimeout(() => {
                  history.goBack()
                }, 200);
            })
            .catch(err => {
              setLoader(false)

            let msg = err.response.data.err
            err?.message && toast.error(msg)
        })
    }
    const handleSelect = selectedOption => {
        const tags = selectedOption.map(el=>el.value)
        setNFTdata({...nftData,  tags:tags})
      };
    const onChangeCollection = selectedOption => {
      // console.log(selectedOption)
      setNFTdata({...nftData,  collections:selectedOption.value})


    }

    const onRightsChange = (e) => {
      setNFTdata({...nftData, rights: e.value})
      console.log(e.value)
      
    }


    if(isProd) {
      return <Redirect to={'/p/development'} />
  }

    return (
     
      <div className='mainBlock'>
        <Loader visible={loader} description={t('loaders::cretin')}/>
        <form onSubmit={onSubmit}>
        <div className='lk-title'>{t('creation::title')}</div>
        <div className="my-3 w-100" role="group" aria-label="NFT type">
          <div className="row my-3 d-flex " >
            <div className="col-md-3 d-flex align-items-center ">
              <input required type="radio"  className="btn-check" name="contenttype" id="ctype-image" autoComplete="off" />
              <label className="itemtype btn btn-outline-primary" htmlFor="ctype-image" onClick={()=>setCurrentType(ExtensionsImages,'Изображение')}><i className="far fa-image" /><br />{t('creation::image')}</label>
            </div>
            <div className="col-md-3">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-panimage" autoComplete="off" />
              <label className="itemtype btn btn-outline-primary" htmlFor="ctype-panimage" onClick={()=>setCurrentType(ExtensionsPanoImg,'Панорамное изображение')}><i className="fas fa-globe" /><br />{t('creation::panoImage')}</label>
            </div>
            <div className="col-md-3">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-vector" autoComplete="off" />
              <label className="itemtype btn btn-outline-primary" htmlFor="ctype-vector" onClick={()=>setCurrentType(ExtensionsVector,'Векторное изображение')}><i className="fas fa-bezier-curve" /><br />{t('creation::vectorImage')}</label>
            </div>
            <div className="col-md-3">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-video" autoComplete="off" />
              <label className="itemtype btn btn-outline-primary" htmlFor="ctype-video" onClick={()=>setCurrentType(ExtensionsVideo,'Видео')}><i className="fas fa-film" /><br />{t('creation::video')}</label>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-3">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-panvideo" autoComplete="off" />
              <label className="itemtype btn btn-outline-primary" htmlFor="ctype-panvideo" onClick={()=>setCurrentType(ExtensionsPanoVid,'Панорамное видео')}><i className="fas fa-video" /><br />{t('creation::panoVideo')}</label>
            </div>
            <div className="col-md-3">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-3dvideo" autoComplete="off" />
              <label className="itemtype btn btn-outline-primary" htmlFor="ctype-3dvideo" onClick={()=>setCurrentType(Extensions3DVid,'3D Видео')}><i className="fas fa-vr-cardboard" /><br />{t('creation::video3d')}</label>
            </div>
            <div className="col-md-3">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-3dmodel" autoComplete="off" />
              <label className="itemtype btn btn-outline-primary" htmlFor="ctype-3dmodel" onClick={()=>setCurrentType(Extensions3DModel,'3D Модель')}><i className="fab fa-unity" /><br />{t('creation::model3d')}</label>
            </div>
            <div className="col-md-3">
              <input type="radio" className="btn-check" name="contenttype" id="ctype-interactive" autoComplete="off" />
              <label className="itemtype btn btn-outline-primary" htmlFor="ctype-interactive" onClick={()=>setCurrentType(ExtensionsINTERACTIVE,'Интерактивный контент')}><i className="fas fa-gamepad" /><br />{t('creation::interactive')}</label>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-8">
            <div className="row justify-content-center my-3">
              <div className="col-md-10">
                <div className="input-group mb-3">
                  <span className="input-group-text w-25" id="token-author-desc">{t('creation::author')}</span>
                  <input type="text" className="form-control" id="token-author" aria-describedby="token-author-desc" defaultValue={userStore.user?.fullname} disabled />
                </div>
                    <div className="input-group my-3" >
                      <span className="input-group-text w-25" id="token-author-desc">
                        {t('creation::coAutors')}
                      </span>
                      <div style={{flexGrow:3}}>
                        <Select
                            
                            ref={refCoauthorSelect}
                            placeholder={t('creation::startWriting')}
                            onChange={onCoauthor}
                            onInputChange={findDebounce}
                            options={usersStore.usersQuery}
                          />
                      </div>
                      <input ref={refCoauthorShare} name='coauthorsShare' type="number" min={1} max={5} step={1} onChange={e=>onShare(e)} className="form-control" id="token-author" placeholder={`(1-5%)`} aria-describedby="token-author-desc" defaultValue='' />
                      <img src={require('../../../images/icons/check.svg')}onClick={addCoauthor}  style={{width:'17px', height:'17px',marginLeft:'6px',filter:'invert(1)'}}/>
                      {/* <FontAwesomeIcon onClick={addCoauthor} icon={faCheck} className='coauthorsAddButton'/> */}
                    </div>
                {coauthor.map((el,i)=>{
                  
                    return(
                      <div style={{margin:0}} className="input-group" key={el.user}>
                        <span style={{visibility: 'hidden'}} className="input-group-text w-25" id="token-author-desc"/>
                        <div style={{display:'flex', justifyContent:'space-between'}} className="form-control" id="token-author" >
                          <div>{el.name+' | '+el.user}</div>
                          <div style={{minWidth:'max-content'}}>{'| '+el.share+' %'}</div>
                        </div>
                        {/* <FontAwesomeIcon icon={faTimes} style={{margin:'auto 5px', color:'grey'}} onClick={()=>removeCoauthor(i)}/> */}
                        <img src={require('../../../images/modal-cross.png')}onClick={()=>removeCoauthor(i)}  style={{width:'17px', height:'17px',marginLeft:'6px',filter:'invert(1)'}}/>
                      </div>
                    )
                })}
                
                
                

                <div className="input-group my-3">
                  <span className="input-group-text w-25" id="token-name-desc">{t('creation::nftTitle')} *</span>
                  <input required name='title' type="text" onChange={onChange} className="form-control" id="token-name" aria-describedby="token-name-desc" />
                </div>
                <div className="input-group my-3">
                  <span className="input-group-text w-25" id="token-name-сoll">{t('creation::collection')} *</span>


                  <div style={{flexGrow:3}}>
                    <Select

                      // styles={selectStyles}
                      placeholder=''
                      onChange={onChangeCollection}
                      options={userStore.collections && userStore.collections.map((el)=> {
                                return { value: el._id, label: el.name }
                              })}
                    />
                  </div>
                  

                </div>
                <div className="input-group my-3">
                  <span className="input-group-text w-25" id="token-name-сoll">{t('creation::tags')} *</span>
                  <div style={{flexGrow:3}}>
                    <Select
                      isMulti
                      // styles={selectStyles}
                      placeholder=''
                      onChange={handleSelect}
                      options={nftStore.tags && nftStore.tags.map((el)=> {
                                const langTag = el.languages? (el.languages[i18n.language]?el.languages[i18n.language]:el.tag) : el.tag
                                return { value: el.tag, label: langTag }
                              })}
                    />
                  
                  </div>
                  <div className="input-group my-3">
                    <span className="input-group-text w-25" id="token-name-desc">{t('creation::authorTags')}</span>
                    <input name='hashtags' type="text" onChange={onChange} placeholder={t('creation::tagPlaceholder')} className="form-control" id="token-name" aria-describedby="token-name-desc" />
                  </div>
                  {/* <select required name='tags' multiple  className="form-control" id="token-name" aria-describedby="token-name-desc" >
                      <option>
                         Выберите тэги
                      </option>
                    {nftStore.tags && nftStore.tags.map((el,i)=> {
                      
                      return(
                        <option onClick={e=>e.preventDefault()} value={el.tag}>
                          {el.tag}
                        </option>
                      )
                    })}
                  </select> */}
                </div>
                <div className="row justify-content-center">
                  <div className="col-10">
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="row justify-content-center my-3">
              <div className="col-md-10">
                <h3 >
                  {t('creation::objDescription')}
                </h3>
                <div className="form-floating">
                  <textarea name='about' onChange={(onChange)} className="form-control" placeholder="Введите описание токена" id="token-description" style={{height: '200px'}} maxLength={1000} defaultValue={""} />
                  <label style={{display:aboutCount>0?'none':'block'}} htmlFor="token-description">{t('creation::objDescriptionInside')}</label>
                </div>
                <small>
                  {aboutCount+t('creation::objDescriptionMax')}
                </small>
              </div>
            </div>
            <hr />
            <div className="row justify-content-center my-3">
              <div className="col-md-10">
                <h3>
                  {t('creation::preview')}
                </h3>
                <p>
                  {t('creation::pnote')} 
                </p>
                <div className="file-drop-area" style={{backgroundColor:nftData.preview?'#DAF9D2':''}}>
                  <div style={{position:'absolute', top:'20px', left:'50px'}}>{nftData.preview?.name}</div>
                  {!nftData.preview?
                    <>
                      <span className="fake-btn">{t('creation::choose')}</span>
                      <span className="file-msg">{t('creation::drag')}</span>
                      <input required ref={pre} onChange={previewUpload} className="file-input" type="file"  />
                    </>
                    :
                    <>
                      <span className="fake-btn">{t('creation::chooseAnother')}</span>
                      <span className="file-msg">{t('creation::drag')}</span>
                      <input required ref={pre2} onChange={previewUpload} className="file-input" type="file"  />
                    </>
                  }
                </div>
              </div>
            </div>
            <hr />
            <div className="row justify-content-center my-3 ">
              <div className="col-md-10">
                <h3>
                  {t('creation::mainFile')}
                </h3>
                <p>
                  {t('creation::fnote')} 
                </p>
                <div className="file-drop-area" style={{backgroundColor:nftData.file?'#DAF9D2':''}}>
                  <div style={{position:'absolute', top:'20px', left:'50px'}}>{nftData.file?.name}</div>
                  {!nftData.file?
                    <>
                      <span className="fake-btn">{t('creation::choose')}</span>
                      <span className="file-msg">{t('creation::drag')}</span>
                      <input required ref={file} onChange={fileUpload} className="file-input" type="file"  />
                    </>
                    :
                    <>
                      <span className="fake-btn">{t('creation::chooseAnother')}</span>
                      <span className="file-msg">{t('creation::drag')}</span>
                      <input required ref={file2}  onChange={fileUpload} className="file-input" type="file"  />
                    </>
                  }
                </div>
              </div>
            </div>
            <hr />
            <div className="row justify-content-center my-3 ">
              <div className="col-md-10">
                <h3>
                  {t('creation::hiddenContent')}
                </h3>
                <p>
                  {t('creation::hnote')} 
                </p>
                <div className="file-drop-area" style={{backgroundColor:nftData.hidden?'#DAF9D2':''}}>
                  <div style={{position:'absolute', top:'20px', left:'50px'}}>{nftData.hidden?.name}</div>
                {!nftData.hidden?
                    <>
                      <span className="fake-btn">{t('creation::choose')}</span>
                      <span className="file-msg">{t('creation::drag')}</span>
                      <input className="file-input" ref={hidden} onChange={hiddenUpload} type="file"  />
                    </>
                    :
                    <>
                      <span className="fake-btn">{t('creation::choose')}</span>
                      <span className="file-msg">{t('creation::drag')}</span>
                      <input className="file-input" ref={hidden2} onChange={hiddenUpload} type="file"  />
                    </>
                  }
                  
                </div>
              </div>
            </div>
            <div className="row justify-content-center text-center my-3 ">
              <div className="col-6">
                <SubmitButton  type='submit' className="frm_button">{t('creation::createNftButton')}</SubmitButton>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="btn-group my-3 w-100" role="group" aria-label="Purchase type">
                <input  type="radio" className="btn-check" name="purchasetype" id="ptype-buynow" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="ptype-buynow" onClick={showBuynow}>{t('creation::buyNow')}</label>
                <input  type="radio" className="btn-check " name="purchasetype" id="ptype-auction" autoComplete="off" />
                <label className="btn btn-outline-primary d-disabled" htmlFor="ptype-auction" onClick={showAuction}>{t('creation::auc')}</label>
              
            </div>
            {byNow && <div id="block-buynow" className="format-info">
              <div className="input-group my-3">
                <span className="input-group-text w-50" id="token-price-fix">{t('creation::buyPrice')}, BNB*</span>
                <input required name='price' step="any" type="number"min={0.0001}max={10000000} onChange={(e) => onCostChange(e)}  className="form-control" id="token-price-fix" aria-describedby="basic-addon3" />
              </div>
              <div className="input-group my-3">
                <span className="input-group-text w-50" id="token-copies">{t('creation::copies')}</span>
                <input required type="number" step="any" className="form-control" name={'amount'} id="token-copies"onChange={onChange} defaultValue={1} min={1}max={100} aria-describedby="basic-addon3" />
              </div>
            </div>}
            {isAuction && <div id="block-auction" className="format-info">
              
              <div className="input-group my-3">
                <span className="input-group-text w-50" id="price-auc-base">{t('creation::aucStart')}, BNB*</span>
                <input required type="number" step="any" min={0.0001}max={10000000} name='startPrice' onChange={onCostChange} className="form-control" id="price-auc-base" aria-describedby="basic-addon3" />
              </div>
              <div className="input-group my-3">
                <span className="input-group-text w-50" id="price-auc-buyout">{t('creation::aucBuyOut')}, BNB*</span>
                <input required type="number" step="any" min={0.0001}max={10000000}  name='fullPrice' onChange={onCostChange}  className="form-control" id="price-auc-buyout" aria-describedby="basic-addon3" />
              </div>
              <div className="input-group my-3" style={{flexWrap:'nowrap'}}>
                <span className="input-group-text w-50" id="price-auc-base">{t('creation::aucDateStart')}</span>
                <DP><DatePicker selected={startDate} showTimeSelect dateFormat="Pp" onChange={(date) => startDateChange(date)} /></DP>
              </div>
              <div className="input-group my-3">
                <span className="input-group-text w-50" id="price-auc-days">{t('creation::aucDays')}</span>
                <input required type="number"  name='date' min={1} onChange={onChange} className="form-control" id="price-auc-days" aria-describedby="basic-addon3" />
              </div> 
              
            </div>}
            <div className="my-3 text-end" id="block-buynow">
              {/* <small> {t('creation::bnbRates')} <b>23 787,30</b> {t('creation::bnbRatesCurrency')}руб.</small> */}
            </div>
            <hr />
            <div className="input-group mb-3">
              <label className="input-group-text w-50" htmlFor="token-rights">{t('creation::rights')} *</label>
              <div style={{flexGrow:3}}>
              <Select
                      // styles={selectStyles}
                      placeholder=''
                      onChange={onRightsChange}
                      options={[
                        {value:'Исключительная лицензия', label: t('creation::exclusiveRights')},
                        {value:'Не исключительная лицензия', label: t('creation::nonExclusiveRights')}
                      ]}
                    />
              </div>
              {/* <select required className="form-select" id="token-rights" onChange={onRightsChange} >
                <option disabled>{t('creation::chooseFrom')}</option>
                <option value={'Исключительная лицензия'}>{t('creation::exclusiveRights')}</option>
                <option value={'Не исключительная лицензия'}>{t('creation::nonExclusiveRights')}</option>
              </select> */}
            </div>
            {nftData.category==='Изображение' &&
             <div id="format-img" className="format-info format-show">
              <hr />
              <h5>{t('creation::formatRequirements')}</h5>
              - JPG<br />
              - PNG<br />
              - TIFF<br />
              <br />
              {/* <h6>Требования к качеству</h6>
              Файлы должны быть предоставлены в одном из 5и размеров:<br />
              - больше 72 ppi <br />
              - больше 150 ppi<br />
              - больше 300 ppi<br />
              - больше 600 ppi <br />
              - 1200 ppi<br />
              (ppi – pixel per inch – точек на дюйм)
              <br /><br /> */}
              <h6>{t('creation::previewFormat')}</h6>
              - JPG. мин. Ширина: 800px. Макс. ширина: 1200px<br /><br />
            </div>}
            {nftData.category==='Видео' &&
             <div id="format-video" className="format-info">
              <hr />
              <h5>{t('creation::formatRequirements')}</h5>
              - MP4<br />
              - MOV<br />
              - MKV<br />
              - WMV<br />
              <br />
              {/* <h6>Требования к качеству</h6>
              Файлы должны быть предоставлены в одном из 5и размеров:<br />
              - 480p (SD)<br />
              - 720p (HD)<br />
              - 1080p (FHD)<br />
              - 1440p (3k)<br />
              - 2160p (4k)<br />
              <br /><br /> */}
              <h6>{t('creation::previewFormat')}</h6>
              - MP4 - 480p (SD) макс. Длительность 10с.<br />
              - GIF покадровая нарезка – длительность 5c.	<br /><br />
            </div>}
            {nftData.category==='Векторное изображение' &&
             <div id="format-vector" className="format-info">	
              <hr />				
              <h5>{t('creation::formatRequirements')}</h5>
              - SVG<br />
              - AI<br />
              - CDR<br />
              <br />
              <h6>{t('creation::previewFormat')}Формат превью:</h6>
              JPG/PNG. мин. Ширина: 800px. Макс. ширина: 1200px.<br /><br />
            </div>}
            {nftData.category==='3D Модель' &&
             <div id="format-model" className="format-info">
              <hr />			
              <h5>{t('creation::formatRequirements')}</h5>
              - STL<br />
              - OBJ<br />
              - FBX<br />
              - 3DS<br />
              - IGES<br />
              - STEP<br />
              - VRML/X3D<br />
              - COLLADA<br />
              <br />
              <h6>{t('creation::previewFormat')}</h6>
              - JPG<br />
              - PNG<br />
              - GIF<br />
              - MP4 - 480p (SD) макс. Длительность 10с.<br />
            </div>}
            {nftData.category==='Панорамное изображение' &&
             <div id="format-panoramaimg" className="format-info">
              <hr />
              <h5>{t('creation::formatRequirements')}</h5>
              - JPG<br />
              - PNG<br />
              <br />
              {/* <h6>Требования к качеству</h6>
              Файлы должны быть предоставлены в одном из 5и размеров:<br />
              - 8000х4000px<br />
              - 16000х8000px<br />
              - 27000х13500px<br />
              - 45000х22500px<br />
              <br /><br /> */}
              <h6>{t('creation::previewFormat')}</h6>
              - JPG<br />
              - MP4 - 480p (SD) макс. Длительность 10с.<br />
              - GIF покадровая нарезка – длительность 5c.	<br />
            </div>}
            {nftData.category==='Панорамное видео' &&
             <div id="format-panoramavid" className="format-info">
              <hr />
              <h5>{t('creation::formatRequirements')}</h5>
              - MP4<br />
              - MKV<br />
              - AVI<br />
              - MOV<br />
              <br />
              {/* <h6>Требования к качеству</h6>
              Файлы должны быть предоставлены в одном из 5и размеров:<br />
              - Мин разрешение: 1920х960px<br />
              - Макс разрешение: 8000х4000px<br />
              <br /><br /> */}
              <h6>{t('creation::previewFormat')}</h6>
              - JPG. мин. Ширина: 800px. Макс. ширина: 1200px<br /><br />
            </div>}
            {nftData.category==='3D Видео' &&
             <div id="format-3dvideo" className="format-info">
              <hr />
              <h5>{t('creation::formatRequirements')}</h5>
              - MP4<br />
              - MKV<br />
              - AVI<br />
              - MOV<br />
              <br />
              {/* <h6>Требования к качеству</h6>
              Файлы должны быть предоставлены в одном из 5и размеров:<br />
              - 480p (SD)<br />
              - 720p (HD)<br />
              - 1080p (FHD)<br />
              - 1440p (3k)<br />
              - 2160p (4k)<br />
              <br /> */}
              <h6>{t('creation::previewFormat')}</h6>
              - MP4 - 480p (SD) макс. Длительность 10с.<br />
              - GIF покадровая нарезка – длительность 5c.	<br />
            </div>}
            {nftData.category==='Интерактивный контент' &&
             <div id="format-inter" className="format-info">
              <hr />
              <h5>{t('creation::formatRequirements')}</h5>
              - ZIP<br />
              - RAR<br />
              - APK<br />
              - DMG<br />
              - ISO <br />
              <br />
              <h6>{t('creation::previewFormat')}</h6>
              - JPG<br />
              - PNG<br />
              - GIF<br />
              - MP4 - 480p (SD) макс. Длительность 10с.<br />
              - GIF покадровая нарезка – длительность 5c<br />
            </div>}
          </div>
      </div>
      </form>

     
      </div>

    )
})


export default CreateNFT



      // let imgWidth = 0
      // let imgHeight = 0
      // var _URL = window.URL || window.webkitURL;
      // var file = e.target.files[0]
      // var img = new Image();
      // var objectUrl = _URL.createObjectURL(file);
      // img.onload = function () {
      //     imgWidth = this.width
      //     imgHeight = this.height
      //     _URL.revokeObjectURL(objectUrl);
      // };
      // img.src = objectUrl;


      const DP = styled.span`
      width: 50%;
        input {
          display: block;
          width: 100%;
          padding: .375rem .75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #212529;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border-radius: .25rem;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;        
        }
      `
