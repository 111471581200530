import { useInjection } from 'inversify-react'
import { useEffect, useState } from 'react'
import useKrpano from 'react-krpano-hooks'
import { NFTstore } from '../../stores/NFTstore.'
import { url } from '../../components/auth/helper'
const GalleryPage = () =>{
    const nftStore = useInjection(NFTstore)
    const [gallery, setGallery] = useState(null)
    useEffect(()=>{
        if(nftStore) {
            nftStore.showGalleryById(window.location.pathname.split('/')[window.location.pathname.split('/').length-1]).then(r=>{
                setGallery(nftStore.oneGallery)
            })
        } 
    },[nftStore])
    return (
        <>
            {gallery ? <LoadGallery gallery={gallery}/>: <div> Gallery not found</div>}
        </>
    )
}
export default GalleryPage

export const LoadGallery = ({gallery}) =>{
     const {containerRef} = useKrpano({
        globalFunctions: {
          logNewScene: (scene) => {
            console.log('New scene: ', scene)
          },
        },
        scriptPath:  url+'/'+gallery.tourJS ,
        embeddingParams: {
          xml: url+'/'+gallery.tourXML ,
        }
      
      })
    return (
      <div style={{width:'100%', overflow:'hidden'}}>
        <div ref={containerRef} />
      </div>
       
    )
}
