import Navbar from "./component/Navbar"
import styled  from "styled-components"
import {useInjection} from "inversify-react";
import {UIStore} from "../../stores/uiStore/uiStore";
import {observer} from "mobx-react";
import {useEffect, useRef} from "react";
import {UserStore} from "../../stores/user/UserStore";
import Footer from "./component/Footer";
import {Redirect, useLocation} from "react-router-dom";
import {NFTstore} from "../../stores/NFTstore.";
import io from "socket.io-client";
import MetaTags from 'react-meta-tags';


const Container = styled.div`
    background-color: ${({theme}) => theme.colors.main};
    display: flex;
    flex-direction: column;
    min-height: 97vh;

    & .children {
        flex-grow: 3;
    }
`

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const SocketConnection =  () => {
    //@ts-ignore
    const newSocket = io?.connect(process.env.REACT_APP_IP_SOCKET, { transports: ["websocket"]} );
    return newSocket
}

const Layout = observer(({ children }: any) => {
    const uiStore = useInjection(UIStore);
    const userStore = useInjection(UserStore)
    const nftStore = useInjection(NFTstore)
    const query = useQuery();
    const location = useLocation();
    const ref = useRef()
    if(query.get('ref')){
        localStorage.setItem('ref', query.get('ref'));
    }

    if(localStorage.getItem('token')){
        userStore.Auth();
    }
    useEffect(() => {
        userStore.getSEO()
        nftStore.socketConnect(SocketConnection());
    }, [])
    useEffect(()=>{
        userStore.getBnbPriceRub()
        userStore.getBnbPriceUsd()
    },[])

    useEffect(() => {
        userStore.GetUser(localStorage.getItem('token'));
    }, [location.pathname])


    return (
        <Container>
            {uiStore.navbar && <Navbar />}
                {(userStore.seo&&userStore?.seo?.length!==0)&&<MetaTags  ref={ref} >
                <div dangerouslySetInnerHTML={{ __html: userStore.seo.toString()}}></div>
                    
            </MetaTags>

         } 
            <div className='children'>
                {children}
            </div>
            <Footer />
        </Container>

    )
    
});


export default Layout