
import {observer} from "mobx-react";
import './nft.sass'
import {useEffect, useState} from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";

import {Redirect, useHistory, useLocation} from "react-router-dom";
import { toast } from 'react-toastify';
import styled from 'styled-components'
import { NFTstore } from "../../stores/NFTstore.";
import Web3 from "web3";
import Loader from "../../shared/loader/loader";
import { isProd } from '../../constants';
import { useTranslation } from "react-i18next";
import { LeftCol } from "./components/leftColumn";
import { BasicInfo } from "./components/basicInfo";
import { Auction } from "./components/auc";
import { BottomMenu } from "./components/bottomMenu";
import { SimilarNFT } from "./components/similarNft";
import { SimpleBuy } from "./components/simpleBuy";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";

export const Path = process.env.REACT_APP_IP;
export const months = ['Январь' , 'Февраль' , 'Март' , 'Апрель' , 'Май' , 'Июнь' , 'Июль' , 'Август' , 'Сентябрь' , 'Октябрь' , 'Ноябрь' , 'Декабрь']
export const getDateAuc = (aucEnd) =>{
    let date = new Date(aucEnd)
    return months[date.getMonth()]+" "+date.getDate()+", "+
    (date.getHours()<10?'0'+date.getHours():date.getHours())+":"
    +(date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes())
}
export const Author = styled.span`
cursor: pointer;
flex-grow: 3;
    & img{
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin: 0 11px;
    }
            
    &  span{
        font-family: Releway-regular;
        color: #894ADA;
        font-size: 14px;
        margin-left: 5px;
    }
`
export const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 15px 0;
    & h1 {
        font-family: Releway-bold;
        font-size: 32px;
        margin: 0;
        @media screen and ( max-width: 1000px ){
            font-size: 22px;
        }
    }   
`
export const Info = styled.div`
display: flex;
align-items: center;
flex-wrap:wrap;
`
export const Price = styled.div`
    margin-top:25px;
    font-size: 14px;
    color: #808080;
    & b{
            font-family: Releway-bold;
            font-size: 23px;
            color: black;
            margin-right:5px;
            @media screen and ( max-width: 1000px ){
                font-size: 20px;
            }
        }
    & p {
        margin: 0;
        font-size: 14px;
    
        @media screen and ( max-width: 1000px ){
            font-size: 12px;
        }
        
    & span {
        margin-left: 10px;
        height: 20px;
    }
    }
    
`

export const Description = styled.div`
    font-family: Releway-regular;
    font-size: 14px;
    margin: 34px 0 15px 0;
    
    & h2 {
        font-family: Releway-semiBold;
        margin: 0;
        font-size: 18px;
        margin: 0 0 15px 0;

    }
  

`
export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    & button {
       width: 469px;
       /* background-color: lightgray; */
       /* color: gray; */
       /* cursor: default; */
    }
    & svg {
       /* color: white; */
       cursor: pointer;
    }

`

interface origCopy {
    owner: any
    isSelling: boolean
    price: number
}

interface IAuc {
    
    allBids: []
    currentBid: any
    currentPrice: number
    date: number
    dateEnd: string
    fullPrice: number
    open: boolean
    startPrice: number

}


const Nft = observer(({match}) => {
   
    const nftStore = useInjection(NFTstore)
    const userStore = useInjection(UserStore);
    const history = useHistory()
    const {t} = useTranslation()
    const [isAuc, setAuc] = useState(true)
    const [hidden, setHidden] = useState(false)
    const [stop, setStop] = useState(false)
    const [auc, setAucState] = useState<IAuc>()
    const [loader, setLoader] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [copiesLeft, setCopiesLeft] = useState(0);
    const [allCopies, setAllCopies] = useState(0);
    const [authorOffer, setAuthorOffer] = useState([]);
    const [ownedCopies, setOwnedCopies] = useState <origCopy[]>([]);
    const [origCopies, setOrigCopies] = useState <origCopy[]>([]);
    let web3 = new Web3(process.env.RPC_URL_97);
    const onSuccess = (res) => {
        if(res.msg==='STOP') {
            setStop(true)
        }
        console.log(res)
        if(res.msg==='BOUGHT') {
            setStop(false)
            history.push('../../../p/catalog')
            toast.success(t('toasts::lot'))
        }
        if(res.msg==='NEW_BID') {
            setStop(false)
            setAucState(res.auction)
            toast.success(t('toasts::bet'));
        }
        if(res.msg==='CONTINUE') {
            setStop(false)
            toast.error(t('toasts::error'));
        }
    }
    


    useEffect(() => {
        if(nftStore.oneNFT?.first===userStore.user?._id) {
            setHidden(true)
        }
    }, [userStore.user])
    useEffect(()=>{
        return()=>{
            nftStore.socket.off(nftStore.oneNFT._id, onSuccess)
            console.log('no connection')
            nftStore.clearNFTState()
        } 
    },[])
    
    useEffect(()=>{
        nftStore.getOneNFT(match.params.id).then(res => {
            console.log(nftStore.oneNFT)
            let now = new Date()
            let token = 'viewtoken'+nftStore.oneNFT._id
            if(!localStorage.getItem(token)||parseInt(localStorage.getItem(token))<Date.parse(now.toString())){
                
                
                let ttl:number =60000
                Date.parse(now.toString()) + ttl
                localStorage.setItem(token, `${Date.parse(now.toString()) + ttl}`)
                nftStore.increaseUsersCount(nftStore.oneNFT._id)
            } 
            nftStore.socket.on(nftStore.oneNFT._id, onSuccess)
            if(nftStore.oneNFT.auction?.startPrice){
                setAuc(true)
                setAucState(nftStore.oneNFT.auction)
                
                
            }
            if(!nftStore.oneNFT.auction?.open&&nftStore.oneNFT?.isSelling) {
                setAuc(false)
                
            }
            if(nftStore?.oneNFT?.instances?.length>1&&nftStore?.oneNFT?.instances){
                
                nftStore?.oneNFT?.instances?.map ((el=>{
                    
                    
                        setOwnedCopies (ownedCopies => [...ownedCopies,{
                            owner: el.owner,
                            isSelling: el.isSelling,
                            price: el.price,
                        }] )
                 
                }))
                setIsCopied(true)
                setCopiesLeft(nftStore?.oneNFT?.instances?.filter(copy=>copy?.isSelling).length)
                setAllCopies (nftStore?.oneNFT?.instances?.length)
            }
        })
    },[match.params.id])
    useEffect(()=>{
        // console.log('asdasd')
        if(ownedCopies.length===nftStore?.oneNFT?.instances?.length) {
            
            const uniqBy=(copies, key)=> {
                let seen = {};
                // console.log(copies)
                return copies.filter((item)=>{
                    
                    let k = key(item);
                    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
                })
            }
            setOrigCopies (uniqBy(ownedCopies, JSON.stringify))
            let autorFilter = nftStore?.oneNFT?.instances.filter(el=>el.owner.user_id===nftStore?.oneNFT?.author.user_id&&el.isSelling)
            setAuthorOffer([autorFilter[autorFilter.length - 1]])
        }
    },[ownedCopies])

    const BuyNFT =(id) => {
        if(!userStore.isAuth) toast.error('Необходимо авторизоваться для покупки')
         else if (userStore.user.balance>nftStore.oneNFT.price) {
            const metamask = userStore.user?.metamask

            if(!metamask){
                toast.error(t('toasts::confirmWallet'))
              
              return
            }

             setLoader(true)
             nftStore.buyNFT(id)
                .then(res =>{
                    setLoader(false)
                })
                .catch(err=>{
                    setLoader(false)
                    let msg = err.response.data.err
                    err?.message && toast.error(msg)
                })
        }
        else {
            toast.error(t('toasts::nofunds'))
        }
    }

    
    if(isProd) {
        return <Redirect to={'/p/development'} />
    }
    return (
        <SmoothAppearance trigger={nftStore.oneNFT}>
            <div className='nft-container' >
                <Loader visible={loader} description={`${t('loader::purchaseInProg')}`} />
                    <LeftCol oneNFT={nftStore.oneNFT}></LeftCol>      
                    <div className='nft-data'>
                        <BasicInfo oneNFT={nftStore.oneNFT} history={history} match={match}></BasicInfo>
                        {isAuc ?<Auction oneNFT={nftStore.oneNFT} auc={auc} history={history}isCopy={isCopied} web3={web3} stop={stop}/>:
                                <SimpleBuy oneNFT={nftStore.oneNFT} web3={web3}BuyNFT={BuyNFT}authorOffer={authorOffer} stop={stop} isCopied={isCopied} origCopies={origCopies} copiesLeft={copiesLeft} allCopies={allCopies}/>
                        }
                        <BottomMenu hidden={hidden} isAuc={isAuc} oneNFT={nftStore.oneNFT} allSells={nftStore?.oneNFT?.transfers} allBids={auc?.allBids} isOpen={auc?.open}></BottomMenu>  
                    </div> 
                    <SimilarNFT NFT={nftStore?.NFT}></SimilarNFT>                       
            </div>
        </SmoothAppearance>
    )
})


export default  Nft