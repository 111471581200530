import React from 'react';
import Modal from "../components/Modal";
import {ModalsEnum, ModalStore} from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { SubmitButton,TextAreaInput,TextInput, Title  } from '../shared/forms/forms';
import { UserStore } from '../stores/user/UserStore';
import { toast } from 'react-toastify';
import { NFTstore } from "../stores/NFTstore.";
import Web3 from 'web3';
import { WithTranslation,withTranslation} from 'react-i18next';
interface IConnectModalProps extends WithTranslation {
    data: { id: string, title: string, userId:string };
    idx: number;
}

interface IConnectModalState {
}

@observer
class СollectionDelete extends React.Component< IConnectModalProps, IConnectModalState > {
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(UserStore)
    declare protected readonly userStore: UserStore;
    @resolve( NFTstore)
    declare protected readonly nftStore:  NFTstore;
  

    onSubmit = () =>{   
        this.nftStore.deleteGallery(this.props.data.id, this.props.t('3D::deleted'))
            .then(res =>{
                
                // toast.success(this.props.t('collections::deleted'))
                
                
                this.modalStore.hideModal(this.props.idx)
                this.nftStore.showUser3Ds(this.props.data.userId)

            })
            .catch(err=>{
                console.log(err)
                let msg = err.response.data.err
                err?.message && toast.error(msg)
            })
    }
    onCancel = () =>{   
       this.modalStore.hideModal(this.props.idx)
    }
    render() {
        console.log(this.props.data)
        const { t } = this.props;
        return (
            <Modal modalKey={ModalsEnum.GalleryDelete} idx={this.props.idx}>
                <Title>
                    {t('3D::yousure')}&ensp;{this.props.data.title} ?
                </Title>
                <SubmitButton onClick={this.onSubmit}>{t('collections::delete')} </SubmitButton>
                <SubmitButton type="button" style={{backgroundColor:'grey',color:'white'}} onClick={this.onCancel}>{t('collections::cancel')} </SubmitButton>
            </Modal>
        )
    }
}
export default withTranslation()(СollectionDelete);
