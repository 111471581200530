import './catalog.sass'
import CatalogItem from './catalogItem'
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";

import Loader from "react-loader-spinner";
import {NFTstore} from "../../stores/NFTstore.";
import { ButtonLight } from '../../shared/buttons/buttons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SmoothAppearance } from '../../shared/layout/smoothAppearance';
import styled from 'styled-components';
import { UsersStore } from '../../stores/users/UsersStore';
import { useHistory } from 'react-router';
import { ModalsEnum, ModalStore } from '../../stores/ModalStore';
const url = process.env.REACT_APP_IP


const Header = styled.div`
text-align: center;
& .banner {
    width: 100%;
    height: 169px;
    object-fit: cover;
}
& div {
    margin:15px 60px 25px;
    padding-bottom: 10px;
border-bottom: 1px solid #dfdfdf;

}
`
const Users = styled.div`
    margin:25px 60px;
    display: flex;

& div {
    margin:10px 20px;
    cursor: pointer;

    & img {
        height: 50px;
        width: 50px;
        border-radius: 100%;
        overflow: hidden;
        object-fit: cover; 
        margin-right: 10px;
    }
}
`
const Tokens = styled.div`
    margin:40px 60px;
`

const Partition = observer(({match}:any) => {
    const nftStore = useInjection(NFTstore)
    const usersStore = useInjection(UsersStore)
    const modalStore = useInjection(ModalStore)
    const history = useHistory()
    const {t} = useTranslation()
    let age = localStorage.getItem('userAge')
    useEffect(()=>{
        nftStore.getOnePartition(match.params.id)
    },[match.params.id])

    useEffect(()=>{
        if(age !== 'adult'){
            if(modalStore.activeModals.some(el=>el.key===ModalsEnum.Rating)){
                return
            }
            nftStore.partition?.rating==='adult'&& modalStore.showModal(ModalsEnum.Rating)
        }
    },[nftStore.partition])

    const userClick = (user) => {
        // e.stopPropagation()
        usersStore.getOneUser(user.user_id)
        history.push({
            pathname:'/p/profile/artist/'+user.user_id, 
            state:{user_id:user.user_id}
        })
    }


    useEffect(()=>{
        return () => {
            nftStore.resetPage()
            nftStore.clearPartition()
        }
    },[])
    

    if(nftStore.partition?.rating==='adult' && age !== 'adult'){
        return(
            <div></div>
        )
    }
    return (
        <SmoothAppearance trigger={nftStore.partition}>
            <Header>
                <img className='banner' src={url+'/'+nftStore.partition?.banner}/>
                <div>
                    <h1>{nftStore.partition?.title}</h1>
                    <p>{nftStore.partition?.description}</p>
                </div>
            </Header>
            <h4 style={{textAlign:'center'}}>Художники раздела</h4>

            <Users>
                {nftStore.partition?.users?.map((user,i)=>{
                    return(
                        <div onClick={()=>userClick(user)}>
                            <img src={url+'/'+user.photo} />
                            {user.fullname}
                        </div>
                    )
                })}
            </Users>
            <Tokens>
                <div className='ct-contentGrid' >
                    {nftStore?.partitionTokens && nftStore.partitionTokens?.map((el,i)=>{
                            return(
                                <CatalogItem key={'cat-item'+i} el={el} isAuc={i%2===0?true:false}/>
                            )
                    })}
                </div>
            </Tokens>
            {nftStore.partition?.tokens?.length>=10&&
            <div style={{display:'flex', justifyContent:'center'}}>
                <ButtonLight width='200px' onClick={()=>nftStore.partitionNewPage(nftStore.partition._id)}>{t('button::showMore')}</ButtonLight>
            </div>}
           
        </SmoothAppearance>
    )
})

export default Partition