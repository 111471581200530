import {observer} from "mobx-react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import {NavLink, Redirect, useHistory, useLocation} from "react-router-dom";

import { NFTstore } from "../../stores/NFTstore.";

import { UsersStore } from "../../stores/users/UsersStore";

import { isProd } from '../../constants';
import { ModalsEnum, ModalStore } from "../../stores/ModalStore";
import styled from "styled-components";
import { SubmitButton } from "../../shared/forms/forms";
import CatalogItem from "../Catalog/catalogItem";
import { useEffect } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { SmoothAppearance } from "../../shared/layout/smoothAppearance";


const Container = styled.div`
    margin-bottom: 100px;

`
const Banner = styled.div`
position: relative;
& img {
    width: 100%;
}
& .buttons {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 330px;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    & button {
        width: 150px;
        /* color: #8A4ADA; */
        & a {
            font-family: Releway-semiBold;
            text-decoration: none;
            color: inherit;
        }
    }
    & .light {
    background-color: #C2ABE0;
    color: #8A4ADA;
}
}

`
const LastNft = styled.div`

padding: 60px 60px;
& h1 {
    text-align: center;
    margin-bottom: 80px;
}
& .nft-grid {
        width: 100%;
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 20px;
}
@media screen and ( max-width: 1000px ){
    & .nft-grid {
        grid-template-columns:  1fr;
        row-gap: 20px;
}  
}
            
`
const VideoBanner = styled.div`

width: 100%;
`
const About = styled.div`
& h1 {
    text-align: center;
    margin: 80px 0px;
    
}
& p {
    margin: 0px 100px;
    text-align: center;
}
& .principles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1fr;
    row-gap: 60px;
    & h3 {
        text-align: center;
    }
    & p {
        text-align: center;
    }

}
@media  ( max-width: 1300px ){
    & .principles {
        grid-template-columns: 1fr 1fr;
    }
}
@media  ( max-width: 1000px ){
    & .principles {
        grid-template-columns: 1fr ;
    }
}
`

const MainPage = observer(({match}) => {
    const {t} = useTranslation()

    const nftStore = useInjection(NFTstore);
    const history = useHistory()
    useEffect(() => {
        nftStore.getNFTcatalog().then(res => console.log(res, 'res in comp'))
    }, [])
    // console.log(nftStore.oneNFT?.auction?.currentPrice)
    if(isProd) {
        return <Redirect to={'/p/development'} />
    }
    return (
        
            <Container>
                <Banner>
                    <img src={require('../../images/banner.jpg')} />
                    <div className='buttons'>
                        <SubmitButton onClick={()=>history.push('/p/auth')}>
                            <NavLink to='/p/auth'>
                                {t('button::signup')}
                            </NavLink>
                        </SubmitButton>
                        <SubmitButton className='light' onClick={()=>history.push('/p/catalog')}>
                            <NavLink to='/p/catalog'>
                                {t('button::catalog')}
                            </NavLink>
                        </SubmitButton>
                       
                    </div>
                </Banner>
                <LastNft>
                    <h1>{t('main::newNFT')}</h1>
                    <div className='nft-grid'>
                        {nftStore.NFT?.map((el,i)=>{
                            if(i<5){
                                return(
                                    <CatalogItem key={'same'+i} el={el} />
                                )
                            }
                        })}
                        </div>
                </LastNft>
                <VideoBanner>
                        <ReactPlayer
                            url={require('../../images/final_eng.mp4')}
                            loop={true}
                            // style={{cursor:'pointer', width:'100%'}}
                            playing={true}
                            // forceVideo={true}
                            width='100%' 
                            volume={0}
                            height='auto'
                        />
                </VideoBanner>
                <About>
                    <h1>{t('news::aboutProjectTitle')}О проекте</h1>
                    <p>{t('news::aboutProject')}DIGARTY открыт для покупателей со всего мира без каких-либо проверок. Просто зарегистрируйтесь, привяжите кошелёк и начинайте коллекционировать. В целях улучшения качества работ на площадке, художники должны пройти процедуру подтверждения аккаунта. Верификация художника также необходима для гарантии эксклюзивности контента.
                    В мире всё больше говорят про NFT (невзаимозаменяемый токен) и нам часто задают вопросы о том, как начать продавать или покупать токены. Для многих первые шаги могут быть не понятны. Подключение кошелька, загрузка работы, создание токена… Не пугайтесь, ответы на свои вопросы Вы найдете в разделе FAQ.</p>
                    
                    
                    <h1>{t('news::principlesTitle')}10 основных принципов DIGARTY</h1>
                    <div className='principles'>
                        <div>
                            <h3>{t('news::princip1title')}1. Безопасность</h3>
                            <p>{t('news::princip1text')}Все сделки происходят в сети блокчейн и 100% безопасны. DIGARTY гарантирует защиту авторов произведений</p>
                        </div>
                        <div>
                            <h3>{t('news::princip2title')}2. Только настоящее искусство</h3>
                            <p>{t('news::princip2text')}Все художники проходят процедуру подтверждения аккаунта</p>
                        </div>
                        <div>
                            <h3>{t('news::princip3title')}3. Уникальность</h3>
                            <p>{t('news::princip3text')}Работы, впервые выставленные на платформе, гарантированно уникальны и продаются напрямую от создателей</p>
                        </div>
                        <div>
                            <h3>{t('news::princip4title')}4. Вариативность</h3>
                            <p>{t('news::princip4text')}Для клиентов платформы доступна оплаты через индивидуальный криптокошелек, который может быть пополнен как с других криптокошельков, та и через платформы-обменники.</p>
                        </div>
                        <div>
                            <h3>{t('news::princip5title')}5. Анонимность покупателей</h3>
                            <p>{t('news::princip5text')}Покупатель в праве не указывать своё настоящее имя и пользоваться анонимными кошельками для оплаты</p>
                        </div>
                        <div>
                            <h3>{t('news::princip6title')}6. Уникальные возможности для инвестирования</h3>
                            <p>{t('news::princip6text')}Платформа предоставляет инвесторам мощные инструменты аналитики на основе ИИ и обзоров лучших искусствоведов платформы.</p>
                        </div>
                        <div>
                            <h3>{t('news::princip7title')}7. Коллаборации</h3>
                            <p>{t('news::princip7text')}DIGARTY поощряет и даёт возможности для создания самых необычных коллабораций между создателями контента: художниками, музыкантами, медийными персонами, брендами</p>
                        </div>
                        <div>
                            <h3>{t('news::princip8title')}8. Развитие сообщества</h3>
                            <p>{t('news::princip8text')}Мы продвигаем молодых перспективных художников на международной арене.</p>
                        </div>
                        <div>
                            <h3>{t('news::princip9title')}9. Win-win</h3>
                            <p>{t('news::princip9text')}На платформе реализована реферальная программа. Вы пожизненно получаете 1% от объема сделок приведённого пользователя, будь то автор, инвестор или брэнд</p>
                        </div>
                        <div>
                            <h3>{t('news::princip10title')}10. Инновационность</h3>
                            <p>{t('news::princip10text')}Площадка объединяет концепции традиционного маркетплэйса и виртуальной галереи. Для художников создаётся персонализированный зал в стилистике их работ с опциональным аудио сопровождением, позволяя воспринимать искусство в задуманном автором масштабе глубиннее и полнее.</p>
                        </div>
                    </div>
                </About>
            </Container>

    )
})


export default  MainPage